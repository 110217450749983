import Vue from 'vue'
import Vuex from 'vuex'
import router from './main-router'
import {firebaseConfig} from './firebase'
import firebase from 'firebase/app'
import 'firebase/analytics';
import 'firebase/performance';
import axios from 'axios'
import defaultStaticData from './static-data'

firebase.initializeApp(firebaseConfig)
firebase.analytics();
firebase.performance();

Vue.use(Vuex)
var server = "https://prod.stub.africa";
var public_server = "https://web.stub.africa";
if(window.location.hostname=="localhost") {
  server = "http://localhost:3000";
  public_server = "http://localhost:3000";
  console.log("local-server")
}
else if(window.location.hostname=="beta.stub.africa") {
  server = "https://beta-api.stub.africa";
  public_server = "https://beta-api.stub.africa";
  console.log("beta-server")
}
else if(window.location.hostname=="127.0.0.1") {
  server = "https://beta-api.stub.africa";
  public_server = "https://beta-api.stub.africa";
  console.log("beta-server")
}

export default new Vuex.Store({
  state: {
    user: null,
    business: {},
    activity: null,
    loading: true,
    ready: false,
    focusMode: false,
    plans: [],
    articles: [],
    server: server,
    public: window.location.origin,
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
    defaultStaticData: defaultStaticData,
    staticData: {},
    banks: [],
    testMode: null,
    modalActive: false,
  },
  getters: {
    user(state) {
      return state.user
    },
    active(state) {
      var month = 1 * 2629743000;
      var business = state.business || {};
      var billing = business.billing || {};
      return billing.status == "active";
    },
    trial(state) {
      var month = 1 * 2629743000;
      var business = state.business.details || {};
      var expires = business.expires;
      return (Date.now() < expires)
    },
    created(state) {
      var business = state.business.details || {};
      return business.created;
    },
    business(state) {
      return state.business
    },
    currency(state) {
      return ((state.business.details || {}).currency || "R")+" ";
    },
    ready(state) {
      return state.ready
    },
    loading(state) {
      return state.loading
    },
    testMode(state) {
      return state.testMode
    },
    logo(state) {
      var business = state.business
      if(business) {
        if(business.details) {
          if(business.details.logo) {
            return "background-color: #fff; background-image: url('"+business.details.logo+"'); background-repeat: no-repeat; background-size: 100% auto; background-position: center;";
          }
        }
      }
      return null;
    },
    plans(state) {
      return state.plans;
    },
    banks(state) {
      return state.banks;
    },
    articles(state) {
      return state.articles;
    },
    focusMode(state) {
      return state.focusMode;
    },
    activity(state) {
      return state.activity;
    },
    server(state) {
      return state.server;
    },
    public(state) {
      return state.public;
    },
    connected(state) {
      return state.socket.isConnected;
    },
    expenseCategories(state) {
      return state.staticData.expensecategories || state.defaultStaticData.expenseCategories
    },
    incomeCategories(state) {
      return state.staticData.incomecategories || state.defaultStaticData.incomeCategories
    },
    modalActive(state) {
      return state.modalActive;
    }
  },
  mutations: {
    login(state,user) {
      state.user = user
    },
    updateEmail(state,email) {
      state.user.email = email
    },
    loading(state,loading) {
      state.loading = loading
    },
    ready(state) {
      console.log("Ready")
      state.ready = true;
    },
    loadBusiness(state,business) {
      state.business = business
    },
    loadPlans(state,plans) {
      state.plans = plans
    },
    loadStaticData(state,staticData) {
      state.staticData = staticData;
    },
    loadBanks(state,banks) {
      state.banks = banks;
    },
    loadArticles(state,articles) {
      state.articles = articles
    },
    logout(state) {
      state.user = null;
      state.business = {};
      state.ready = false;
    },
    focusMode(state,focusMode) {
      state.focusMode = focusMode;
    },
    loadActivity(state,activity) {
      state.activity = activity;
    },
    toggleTestMode(state) {
      state.testMode = !state.testMode;
    },
    SOCKET_ONOPEN (state, event)  {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
      if(state.user) {
        state.user.getIdToken().then(function(token) {
          Vue.prototype.$socket.send(token)
        })
      }
      setInterval(function() { 
        if(state.socket.isConnected) {
          Vue.prototype.$socket.send("")
        }
      }, 10000);
    },
    SOCKET_ONCLOSE (state, event)  {
      state.socket.isConnected = false;
      //state.loading = true;
      //state.ready = false;
    },
    SOCKET_ONERROR (state, event)  {
      //state.loading = true;
      //state.ready = false;
      state.socket.isConnected = false;
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
      
      //console.log(message)
      if(message.heartbeat!=true) {
        state.business = message;
        if(message && message!={}) {
          console.log(message)
          setTimeout(function() { 
            state.loading = false;
            state.ready = true;
          }, 100);
        }
        if($crisp) {
          try {
            $crisp.push(["set", "user:email", [state.user.email]]);
            $crisp.push(["set", "user:company", [state.business.details.name]]);
            $crisp.push(["set", "user:nickname", [state.business.profile.firstname]]);
            $crisp.push(["set", "user:avatar", [state.business.details.logo]]);
          }
          catch(err) {};
        }
      }
      
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      if(state.socket.isConnected) {
        state.user.getIdToken().then(function(token) {
          Vue.prototype.$socket.send(token)
        })
      }
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
      state.socket.isConnected = false;
      //state.loading = true;
      //state.ready = false;
      
    },
  },
  actions: {
    event: function({state}, event) {
      firebase.analytics().logEvent(event);
    },
    socketUpdate:function({state}) {
      if(state.socket.isConnected) {
        state.user.getIdToken().then(function(token) {
          Vue.prototype.$socket.send(token)
        })
      }
    },
    sendMessage: function({state}, message) {
      if(state.socket.isConnected) {
        Vue.prototype.$socket.send(message)
      }
    },
    signup({commit, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        axios.post(server+'/api/business/create', data)
        .then(function (response) {
           if(response.data.error) {
            reject(response.data)
           }
           else {
            dispatch('event','user-signup');
             resolve(true);
            }
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    beta({commit, dispatch }, data) {
      return new Promise(function(resolve, reject) {
        axios.post(server+'/api/user/beta', data)
        .then(function (response) {
          dispatch('event','user-beta');
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    login({commit, dispatch }, data) {
      return new Promise(function(resolve, reject) {
      firebase.auth().signInWithEmailAndPassword(data.email, data.password)
        .then(function(user) {
          commit('loading',true)
          commit('login',user.user)
          dispatch('event','user-login');
          // user.getIdToken().then(function(token) { 
          //   Vue.prototype.$socket.send(token)
          // });
          resolve(true)
          router.push("/insights");
        })
        .catch(function(error) {
          commit('loading',false)
          dispatch('event','user-wrong-credentials');
          reject(error)
        });
      });
    },
    logout({commit, state, dispatch}) {
      firebase.auth().signOut().then(function() {
        commit('logout')
        dispatch('event','user-logout');
        router.push("/login");
      }).catch(function(error) {
        // An error happened.
      });
    },
    plainLogout({commit, state, dispatch}) {
      firebase.auth().signOut().then(function() {
        commit('logout')
        dispatch('event','user-logout');
      }).catch(function(error) {
        // An error happened.
      });
    },
    updateBusiness({commit,state, dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        var finaldata  = newdata;
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','business-updated');
          axios.post(server+'/api/business/update', finaldata)
          .then(function (response) {
            console.log(response)
            resolve(response.data)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          });
          
        })
      })
    },
    createInvoice({commit,state},newdata) {
      return new Promise(function(resolve, reject) {
        var finaldata  = newdata;
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          //more fine grained data is collected on the page
          axios.post(server+'/api/invoice/create', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          }); 
        })
      });
    },
    saveInvoice({commit,state}, newdata) {
      return new Promise(function(resolve, reject) {
        var finaldata  = newdata;
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          //more fine grained data is collected on the page
          axios.post(server+'/api/invoice/update', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    invoiceEvent({commit,state, dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        var finaldata  = newdata;
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          axios.post(server+'/api/invoice/event', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    autoSaveInvoice({commit,state, dispatch},newdata) {
      var finaldata  = newdata;
      state.user.getIdToken().then(function(token) {
        finaldata.token = token;
        axios.post(server+'/api/invoice/update', finaldata)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });
        
      })
    },
    sendInvoice({commit,state, dispatch},finaldata) {
      return new Promise(function(resolve, reject) {
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','invoice-sent');
          axios.post(server+'/api/invoice/send', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    shareInvoice({commit,state, dispatch},finaldata) {
      return new Promise(function(resolve, reject) {
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','invoice-shared');
          axios.post(server+'/api/invoice/share', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    markInvoiceNotificationAsRead({commit,state, dispatch},finaldata) {
      return new Promise(function(resolve, reject) {
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','invoice-notification-read');
          axios.post(server+'/api/invoice/mark-notification-as-read', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    analyzeInvoice({commit,state, dispatch},id) {
      return new Promise(function(resolve, reject) {
        var finaldata  = {id:id};
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','invoice-analyzed');
          axios.post(server+'/api/invoice/analyze', finaldata)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error)
          });
          
        })
      });
    },
    deleteInvoice({commit,state,dispatch},id) {
      return new Promise(function(resolve, reject) {
        var finaldata  = {id: id};
        state.user.getIdToken().then(function(token) {
          finaldata.token = token;
          dispatch('event','invoice-deleted');
          axios.post(server+'/api/invoice/delete', finaldata)
          .then(function (response) {
            console.log(response)
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          });
          
        })
      });
    },
    getPlans({commit,state}) {
      return new Promise(function(resolve, reject) {
        axios.get(server+'/api/data/plans')
        .then(function (response) {
          commit('loadPlans',response.data)
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    getStaticData({commit,state}) {
      return new Promise(function(resolve, reject) {
        axios.get(server+'/api/data/static')
        .then(function (response) {
          commit('loadStaticData',response.data)
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    getBanks({commit,state}) {
      return new Promise(function(resolve, reject) {
        axios.get(server+'/api/data/banks')
        .then(function (response) {
          commit('loadBanks',response.data.banks)
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    getArticles({commit,state}) {
      return new Promise(function(resolve, reject) {
        axios.get(server+'/api/data/articles')
        .then(function (response) {
          commit('loadArticles',response.data)
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    createExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','expense-created');
            axios.post(server+'/api/expense/create', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','expense-deleted');
            axios.post(server+'/api/expense/delete', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','expense-updated');
            axios.post(server+'/api/expense/update', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    createIncome({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','income-created');
            axios.post(server+'/api/income/create', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteIncome({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','income-deleted');
            axios.post(server+'/api/income/delete', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateIncome({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','income-updated');
            axios.post(server+'/api/income/update', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    createClient({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','client-created');
            axios.post(server+'/api/client/create', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteClient({commit,state,dispatch},id) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = {id:id};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','client-deleted');
            axios.post(server+'/api/client/delete', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateClient({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','client-updated');
            axios.post(server+'/api/client/update', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    createProduct({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','product-created');
            axios.post(server+'/api/product/create', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteProduct({commit,state,dispatch},id) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = {id:id};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','product-deleted');
            axios.post(server+'/api/product/delete', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateProduct({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','product-updated');
            axios.post(server+'/api/product/update', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    forgotPassword({commit,state, dispatch},data) {
      return new Promise(function(resolve, reject) {
        dispatch('event','password-forgotten');
        axios.post(server+'/api/auth/forgot-password', data)
        .then(function (response) {
          resolve(true)
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    resetPassword({commit,state, dispatch},data) {
      return new Promise(function(resolve, reject) {
        dispatch('event','password-reset');
        axios.post(server+'/api/auth/reset-password', data)
        .then(function (response) {
          resolve(true)
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    verifyEmail({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','email-verified');
            axios.post(server+'/api/auth/verify-email', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    changeEmail({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','email-changed');
            axios.post(server+'/api/auth/change-email', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateProfile({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            dispatch('event','profile-updated');
            axios.post(server+'/api/auth/update-profile', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    expenseSnapshot({commit,state}) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
            state.user.getIdToken().then(function(token) {
              var finaldata = {token: token};
              axios.post(server+'/api/expense/snapshot', finaldata)
              .then(function (response) {
                resolve(response.data);
              })
              .catch(function (error) {
                reject(error);
              });
            })
        }
        else reject("Not logged in");
      })
    },
    addPayment({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/add-payment', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deletePayment({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/delete-payment', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updatePayment({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/update-payment', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    addInvoiceItem({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/add-item', finaldata)
            .then(function (response) {
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateInvoiceItem({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/update-item', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteInvoiceItem({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/delete-item', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    addInvoiceExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/add-expense', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updateInvoiceExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/update-expense', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteInvoiceExpense({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/invoice/delete-expense', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    sentInvite({commit,state,dispatch},newdata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = newdata;
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/user/invite', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    deleteAccount({commit,state,dispatch}) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/delete', finaldata)
            .then(function (response) {
              firebase.auth().signOut().then(function() {
                resolve(response.data)
              })
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    subscribeToPlan({commit,state,dispatch},finaldata) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/subscription/start', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    cancelPlan({commit,state,dispatch}) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/subscription/cancel', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    updatePlan({commit,state,dispatch}) {
      return new Promise(function(resolve, reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/subscription/update', finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              reject(error);
            });
          })
        }
      })
    },
    generateDummyData({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.get(server+'/api/data/dummy')
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    linkBank({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/link-bank',finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    upgrade({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.get(server+'/api/migrate/all/'+state.user.uid)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    listAccounts({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/list-accounts',finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    seen({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/user/seen',finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    enableStubPayments({commit,state,dispatch},finaldata) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/enable-stub-payments',finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
    disableStubPayments({commit,state,dispatch}) {
      return new Promise(function(resolve, _reject) {
        if(state.user) {
          var finaldata  = {};
          state.user.getIdToken().then(function(token) {
            finaldata.token = token;
            axios.post(server+'/api/business/disable-stub-payments',finaldata)
            .then(function (response) {
              resolve(response.data)
            })
            .catch(function (error) {
              resolve(null);
            });
          })
        }
      })
    },
  }
})
