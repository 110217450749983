<template>
  <div v-if="user && b">
    <div class="profile" v-if="details">
      <div @change="dirtify()">
        <h3>Business Banking Details</h3>
        <p>You may choose to show your banking details on your invoices</p>
        <div>
          <label for="accountname">
            Account Name
          </label>
          <input v-model="details.accountname" id="accountname" type="text" placeholder="" :disabled="details.stubpayments">
        </div>
        <div>
          <label for="accountnumber">
            Account Number
          </label>
          <input v-model="details.accountnumber" id="accountnumber" type="text" placeholder=""  :disabled="details.stubpayments">
        </div>
        <div>
          <label for="accounttype">
            Account Type
          </label>
          <input v-model="details.accounttype" id="accounttype" type="text" placeholder="" :disabled="details.stubpayments">
        </div>
         
        <div class="v-select__holder">
          <label for="bankname">
            Bank
          </label>
          <!-- <input v-model="details.bank" id="bankname" type="text" placeholder=""> -->
          <v-select :options="banks"  label="name" v-model="details.bank" class="input" @input="updateBank" :class="{ disabled: details.stubpayments }"></v-select>
        </div>
        <div>
          <label for="branchcode">
            Branch code
          </label>
          <input v-model="details.branchcode" id="branchcode" type="text" placeholder="" disabled>
        </div>
        <div>
          <label for="paymentnote">
            Payment note
          </label>
          <!-- <textarea v-model="details.paymentnote" id="paymentnote" type="text" placeholder=""></textarea> -->
          <textarea v-model="details.paymentnote" id="paymentnote" type="text" placeholder="Please use your invoice number as the transaction reference."></textarea>
          <p class="input-description">This appears at the bottom of your invoices.</p>
        </div>
        <div>
          <label class="checkbox">Show bank details on invoice
            <input type="checkbox" name="" v-model="details.showbank">
            <span class="check-mark"></span>
          </label>
        </div>
        <div class="space-1"></div>
        
        <!-- <h3>Digital payments</h3>
        <p v-if="details.bank && details.accountnumber">When enabled, digital payments received via stub will automatically be paid out to the account above after two banking days.</p> 
        
        <modal v-bind:visible="enablepayments" @close-event="enablepayments = false" heading="Enable stub payments">
          <div class="modal__content">
              <p>Confirm you bank account</p>
              <input v-model="accountnumber" id="accountnumber" type="text" placeholder="">
              @ {{details.bank}}
              <br/>
              <br/>
              <label class="checkbox">Accept stub payments into the account shown above
                  <input type="checkbox" name="" v-model="confirm">
                  <span class="check-mark"></span>
              </label>
          </div>
          
          <div class="modal__buttons">
              <StubButton @click="enableStubPayments()" class="button button--primary button--priority" :disabled="!validaccount()" :is-loading="accepting">Accept payments via stub</StubButton>
              <button class="button--danger button--priority" @click="enablepayments = false;">Cancel</button>
          </div>
        </modal>
        <div v-if="details.bank && details.accountnumber && !details.stubpayments">
          <StubButton @click="enablepayments = true" class="button button--accent button--priority">Enable stub payments</StubButton>
        </div>  
        <div v-if="!details.bank || !details.accountnumber">
          <p>You must complete the <router-link to="/account/bank">bank section</router-link> of you business profile to enable stub payments</p>
        </div> 
        <div v-if="details.stubpayments">
          <label class="checkbox">Show digital payment button on invoice
            <input type="checkbox" name="" v-model="details.showpaymentlink">
            <span class="check-mark"></span>
          </label>
          <p><a class="danger" @click="disableStubPayments()">Delink this account {{details.accountnumber}}</a></p>
        </div> -->
        
        <div class="space-4"></div>
        <div class="space-4"></div>
        <div class="submit">
          <StubButton @click="saveProfile()" class="primary" v-bind:is-loading="loading">Save payment details</StubButton>
        </div>

        <!-- <div v-if="testMode || !details.stitch" class="space-4"></div>
        <h3 v-if="testMode || !details.stitch" class="section__heading">Link bank account to stub</h3>
        <div v-if="testMode || !details.stitch" class="mb-4">
          <p class="subtext">This will allow us to pull your bank transaction automatically into stub</p> 
          
          <StubButton @click="linkBank()" class="button button--accent" v-bind:is-loading="loadingLinkBank">Link {{details.accountnumber}} @ {{details.bank}}</StubButton>
        </div>

        <div v-if="testMode" class="space-4"></div>
        <h3 v-if="testMode" >Available accounts</h3>
        <div class="space-1"></div>
        <!-- {{accounts}} -->
        <!-- <div v-if="testMode" class="list">
          <div v-for="a in accounts" v-bind:key="a.id" class="list__row">
            <div class="cell payment">
                <div class="bank__name">{{a.bankId}} {{a.name}}</div>
                <div class="subtext"><em>{{a.accountNumber}}</em></div>
            </div>
            <div class="cell cell--chevron material-icons">
                chevron_right
            </div>
          </div>
        </div> -->
      </div>
    </div>
     <modal v-bind:visible="showModal" @close-event="(showModal=false)" heading="Discard changes?">
      <div class="modal__content">
        <p>You have unsaved changes to your banking details. Would you like to save these changes?</p>
      </div>
      <div class="modal__buttons">
        <StubButton @click="saveProfile()" class="primary" v-bind:is-loading="loading">Save details</StubButton>
        <StubButton @click="leave()" class="button button--secondary">Discard changes</StubButton>
        
      </div>
    </modal>
    <div class="space-4"></div>
  </div>
</template>
    
<script>
import VueAutonumeric from '../../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue';
import StubButton from '../../components/StubButton.vue';
import Modal from '../../components/Modal.vue';
import vSelect from 'vue-select';

export default {
  name: 'bank',
  components: {
    VueAutonumeric,
    StubButton,
    Modal,
    vSelect
  },
  data: function() {
    return {
      user: this.$store.getters.user,
      templogo: null,
      loading: false,
      next: null,
      showModal: false,
      cache: null,
      business: null,
      account: null,
      dirty: false,
      loadingLinkBank: false,
      loadingRefresh: false,
      bankAccounts: null,
      bankAccountsLoaded: false,
      bank: {},
      accountnumber: null,
      enablepayments: false,
      confirm: false,
      placeholder: "<No account number>",
      accepting: false,
    }
  },
  methods: {
    validaccount: function() {
      return this.confirm && (this.details.accountnumber == this.accountnumber);
    },
    enableStubPayments: function() {
      var thisy = this;
      this.accepting = true;
      thisy.$store.dispatch('enableStubPayments', {accountnumber: thisy.accountnumber}).then(function(data){
        console.log(data)
        thisy.accepting = false;
        thisy.enablepayments = false;
      })
    },
    disableStubPayments: function() {
      var thisy = this;  
      thisy.$store.dispatch('disableStubPayments').then(function(data){
        console.log(data)
      })
    },
    updateBank: function(b) {
      if(b) {
      this.details.bank = b.name;
      this.details.branchcode = b.code;
      }
    },
    linkBank: function () {
      var thisy = this;
      thisy.loadingLinkBank = true;
      this.$store.dispatch('linkBank').then(function(data){
           console.log(data)
           window.open(data.result, "_self");
           thisy.loadingLinkBank = false;
      })
    },
    saveProfile: function() {
        var thisy = this;
        this.loading = true;
        this.dirty = false;
        var bizniz = {}
        if(!thisy.details.stubpayments) {
          bizniz.details = thisy.details;
          bizniz.details.journeys = {onboarding: true};            
        }
        else {
          bizniz.details = {showbank: thisy.details.showbank,
                            paymentnote: thisy.details.showbank,
                            showpaymentlink: thisy.details.showpaymentlink }
        }
        thisy.$store.dispatch('updateBusiness', bizniz).then(function(){
            thisy.loading = false;  
             if(thisy.next) thisy.next();
        })
    },
    refresh: function() {
      var thisy = this;
      this.$store.dispatch('listAccounts').then(function(data){
          console.log(data)
          thisy.bankAccounts = data.result;
          thisy.bankAccountsLoaded = true;
      })
    },
    leave: function(){
      this.showModal = false;
      console.log(this.next)
      this.next()
      
    },
    dirtify: function() {
      this.dirty = true;
    }
  },
  computed: {
    testMode: function() {
      return this.$store.getters.testMode;
    },
    b: function() {
      return this.$store.getters.business;
    },
    banks: function() {
      return this.$store.getters.banks;
    },
    details: function() {
      return this.b.details || {};
    },
    accounts:function() {
      var thisy = this;
      if(!this.bankAccountsLoaded){
        this.bankAccountsLoaded = true;
        console.log("loading")
        if(this.details.stitch) {
          this.$store.dispatch('listAccounts').then(function(data){
              console.log(data)
              thisy.bankAccounts = data.result;
              thisy.bankAccountsLoaded = true;
              return thisy.bankAccounts
          })
        }
        else return thisy.bankAccounts
      }
      else return thisy.bankAccounts
    }
  },
  beforeRouteLeave: function(to, from, next) { 
      if(this.dirty){
        this.next = next;
        this.showModal = true;
      }
      else next()
  }
}
</script>


<style lang="scss">
@import '../../assets/scss/variables';

.bank__name {
  text-transform: capitalize;
}
.v-select__holder .v-select {
  width: 50%;
}
.v-select__holder .vs__search {
  width: 100%; 
}
.v-select.disabled {
    pointer-events:none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    border-color: #d1dbe5;   

    .vs__dropdown-toggle .vs__selected-options input[type=search].vs__search {
      background-color: #F5F6F8;
    }
}
@media screen and (max-width: $breakpoint-md) {
    .v-select__holder .v-select {
      width: 100%;
    }
}
</style>
