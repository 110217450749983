<template>
    <div class="list__row" v-if="!isDeleted">

      <div class="cell-wrapper" @click="edit()">
        <div class="cell cell--stretchy">
          <h2 class="subheading">{{saved.name}}</h2>
          <p class="subtext">{{saved.date | formatDate}}</p>
        </div>
        <div class="cell" v-if="saved.category">
          <span class="pill">{{saved.category}}</span>
        </div>
        <vue-autonumeric v-bind:value="savedtotal"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="div" contenteditable="false"
          class="cell"></vue-autonumeric>        
      </div>

      <modal v-bind:visible="isFocused" @close-event="closeModal()" heading="Edit expense">
        <div class="modal__content">
          <label>Name</label>
          <input v-model="expense.name" type="text" placeholder="">
          <!-- <label for="quantity">Quantity</label>
          <vue-autonumeric v-model="expense.quantity"
            :options="['numericPos']"></vue-autonumeric> -->          
          <label>Amount</label>
          <vue-autonumeric v-model="expense.amount" @input="handleInput"
            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
            :placeholder="currency+'0.00'" @focus.native="gainFocus(4)" @blur.native="loseFocus(4)"></vue-autonumeric>
          <label class="checkbox">Quantity
            <input type="checkbox" name="" v-model="expense.showquantity">
            <span class="check-mark"></span>
          </label>
          <label v-if="expense.showquantity">Quantity</label>
          <vue-autonumeric v-if="expense.showquantity" v-model="expense.quantity"
            :options="['numericPos']"></vue-autonumeric>
          <label v-if="expense.showquantity">Total</label>
          <vue-autonumeric v-if="expense.showquantity" v-bind:value="total" @input="handleInput"
            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
            :placeholder="currency+'0.00'" disabled></vue-autonumeric>
          <div v-if="expense.showquantity" class="space"></div>
          <label>Category</label>
          <select name="expensecategory" id="" v-model="expense.category">
            <option v-for="cat in expenseCategories" :key="cat.id" :value="cat.value">{{cat.label}}</option>
          </select>
          <label>Date</label>
          <vuejs-datepicker v-model="expense.date" @input="handleInput"></vuejs-datepicker>
        </div>
        <div class="modal__buttons modal__buttons--divider">
          <StubButton @click="updateExpense()" v-bind:is-loading="saving">Save</StubButton>
          <button class="button button--danger" @click="confirmDeleteIsFocused = true; isFocused = false;">Delete</button>
        </div>
      </modal>

      <modal v-bind:visible="confirmDeleteIsFocused" @close-event="confirmDeleteIsFocused = false" heading="Delete expense">
        <div class="modal__content">
          <h3>Are you sure?</h3>
          <p>This will permanently delete this expense and cannot be undone.</p>
        </div>
        <div class="modal__buttons">
          <button class="button button--disaster" @click="deleteEvent()">Delete</button>
          <button class="button--secondary" @click="isFocused = true; confirmDeleteIsFocused = false;">Cancel</button>
        </div>
      </modal>

    </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'

export default {
  name: 'Expense',
  components: {
    Modal,
    vuejsDatepicker,
    VueAutonumeric,
    StubButton
  },
  props: ['value','currency'],
  data: function() {
    return {
      expense: this.value,
      saved: {...this.value},
      hasFocus: [0,0,0,0,0],
      isFocused: false,
      isDeleted: false,
      saving: false,
      confirmDeleteIsFocused: false,
    }
  },
  methods: {
    makeFloat: function(num) {
        return Number(Math.round(num +'e'+ 2) +'e-'+ 2).toFixed(2);
    },
    closeModal: function() {
      this.isFocused = false;
    },
    formatDate: function(rawDate) {
      var date = new Date(rawDate);
      var monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sep", "Oct",
        "Nov", "Dec"
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return day + ' ' + monthNames[monthIndex] + ' ' + year;
    },
    edit: function() {
      this.isFocused = true;
    },
    updateExpense: function() {
      var thisy = this;
      thisy.saving = true;
      var expense = this.expense;
      if(expense.showquantity==false) {
        expense.quantity = 1;
      }
      this.$store.dispatch('updateExpense',expense).then(function(data){
          console.log(data)
          if(!data.error) {
            thisy.saved = thisy.value
          }
          thisy.isFocused = false;
          thisy.saving = false;
      });
    },
    handleInput: function(e) {
      this.$emit('input', this.expense);
    },
    deleteEvent: function() {
      console.log(this.expense)
      this.isDeleted = true;
      this.$emit('delete', this.expense);
      this.isFocused = false;
    },
    gainFocus: function(index) {
      // this.hasFocus[index] = 1;
      // if(this.hasFocus.reduce((a, b) => a + b, 0) > 0) {
      //   this.isFocused = true
      // }
      // else this.isFocused = false;
    },
    loseFocus: function(index) {
      // this.hasFocus[index] = 0;
      // if(this.hasFocus.reduce((a, b) => a + b, 0) > 0) {
      //   this.isFocused = true
      // }
      // else this.isFocused = false;
    }
  },
  computed: {
    expenseCategories: function() {
      return  this.$store.getters.expenseCategories;
    },
    total: function() {
      this.expense.total = this.expense.amount * (this.expense.showquantity ? (this.expense.quantity || 1) : 1);
      return this.expense.total;
    },
    savedtotal: function() {
      return this.expense.amount * (this.expense.showquantity ? (this.expense.quantity || 1) : 1);
    }
  }
}
</script>

<style lang="scss" scoped>
// @import './../assets/scss/variables';

</style>
