<template>
    <div class="list__row" v-if="!isDeleted" @click="edit()">
      
      <div class="cell-wrapper">
        <div class="cell cell--stretchy">
          <h2 class="subheading">{{income.name}}</h2>
          <p class="subtext">{{ income.date | formatDate }}</p>
        </div>
        <div class="cell">
          <span class="pill">{{income.category}}</span>
        </div>
        <vue-autonumeric v-model="income.amount"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="div" contenteditable="false"
          class="cell"></vue-autonumeric>        
      </div>

      <modal v-bind:visible="isFocused" @close-event="closeModal()" heading="Edit income">
          <!-- <div class="switch">
              <label class="switch-option">
                  <input type="radio" name="incometypeswitch" value="payment" v-model="income.incometype">
                  <span class="switch-mark">Invoice payment</span>
              </label>
              <label class="switch-option">
                  <input type="radio" name="incometypeswitch" value="income" v-model="income.incometype">
                  <span class="switch-mark">Other income</span>
              </label>
          </div> -->

          <div class="modal__content">
            <!-- <a class="action" @click="income.incometype='erm'"><span class="material-icons">chevron_left</span> Change income type</a> -->
            <label>Name</label>
            <input v-model="income.name" type="text" placeholder="">
            <label>Linked invoice</label>
            <v-select :options="invoices" v-model="income.quinvoice" label="title">
              <template #no-options>
                No unpaid invoices found
              </template>
            </v-select>
            <label>Amount</label>
            <vue-autonumeric v-model="income.amount" @input="handleInput"
              :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
              :placeholder="currency+'0.00'"  @focus.native="gainFocus(4)"  @blur.native="loseFocus(4)" :disabled="income.verified"></vue-autonumeric>
            <label>Date</label>
            <vuejs-datepicker v-model="income.date" @input="handleInput" :disabled="income.verified"></vuejs-datepicker>
            <label>Category</label>
            <select name="incomecategory" id="" v-model="income.category">
              <option v-for="cat in incomeCategories" :key="cat.id" :value="cat.value">{{cat.label}}</option>
            </select>
            <label>Payment</label>
            <label class="radio-button">Account
              <input type="radio" name="incomepayment" value="account" v-model="income.payment" :disabled="income.verified">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">Cash
              <input type="radio" name="incomepayment" value="cash" v-model="income.payment" :disabled="income.verified">
              <span class="radio-mark"></span>
            </label>
            <!-- <label>Notes</label>
            <textarea v-model="income.notes"></textarea> -->
          </div>
        <div class="modal__buttons modal__buttons--divider">
          <StubButton @click="updateIncome()" v-bind:is-loading="saving">Save</StubButton>
          <button class="button button--danger" @click="confirmDeleteIsFocused = true; isFocused = false;" :disabled="income.verified">Delete</button>
        </div>
      </modal>

      <modal v-bind:visible="confirmDeleteIsFocused" @close-event="confirmDeleteIsFocused = false" heading="Delete income">
        <div class="modal__content">
          <h3>Are you sure?</h3>
          <p>This will permanently delete this income and cannot be undone.</p>
        </div>
        <div class="modal__buttons">
          <button class="button button--disaster" @click="deleteEvent()">Delete</button>
          <button class="button--secondary" @click="confirmDeleteIsFocused = false">Cancel</button>
        </div>
      </modal>
    </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'
import vSelect from 'vue-select'

export default {
  name: 'Income',
  components: {
    Modal,
    vuejsDatepicker,
    VueAutonumeric,
    StubButton,
    vSelect
  },
  props: ['id','value','currency'],
  data: function() {
    return {
      income: this.value,
      hasFocus: [0,0,0,0,0],
      isFocused: false,
      isDeleted: false,
      saving: false,
      confirmDeleteIsFocused: false
    }
  },
  methods: {
    migrate: function(inv) {
      return inv;
    },
    getIndicator: function (ob) {
      if(ob.meta) {
        return ob.meta.indicator;
      }
      else return undefined;
    },
    makeFloat: function(num) {
        return Number(Math.round(num +'e'+ 2) +'e-'+ 2).toFixed(2);
    },
    closeModal: function() {
      this.isFocused = false;
    },
    formatDate: function(rawDate) {
      var date = new Date(rawDate);
      var monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sep", "Oct",
        "Nov", "Dec"
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return day + ' ' + monthNames[monthIndex] + ' ' + year;
    },
    edit: function() {
      this.isFocused = true;
    },
    updateIncome: function() {
      var temp = {...this.income};
      if(this.income.quinvoice && this.income.quinvoice.id) {
        temp.quinvoice = this.income.quinvoice.id;
      }
      var thisy = this;
      this.$store.dispatch('updateIncome',temp).then(function(data){
            console.log(data)
            thisy.isFocused = false;
      })
      console.log(temp)
    },
    handleInput: function(e) {
      this.$emit('input', this.income)
    },
    deleteEvent: function() {
      this.isDeleted = true;
      this.$emit('delete', this.income);
      this.isFocused = false;
    },
    gainFocus: function(index) {
      // this.hasFocus[index] = 1;
      // if(this.hasFocus.reduce((a, b) => a + b, 0) > 0) {
      //   this.isFocused = true
      // }
      // else this.isFocused = false;
    },
    loseFocus: function(index) {
      // this.hasFocus[index] = 0;
      // if(this.hasFocus.reduce((a, b) => a + b, 0) > 0) {
      //   this.isFocused = true
      // }
      // else this.isFocused = false;
    },
    next: function () {
      this.part = 1;
    },
  },
  computed: {
    incomeCategories: function() {
      return  this.$store.getters.incomeCategories;
    },
    clients: function() {
        var biz = this.$store.getters.business || {};
        return biz.clients || {};
    },
    invoices: function() {
      var inv = [];
      var biz = this.$store.getters.business || {};
      var invoices = biz.invoices || [];
      var thisy = this;
      invoices.forEach(function(i,index) {
          var temp = i;
          temp.title = ((temp.client||{}).name || "Invoice") + " — " + temp.invoicenumber;
          inv.push(temp)
      });
      return inv.sort(function(a,b){
         const dataA = new Date(a.date);
         const dataB = new Date(b.date);
         let comparison = 0;
          
          if (dataA < dataB) {
            comparison = -1;
          } else if (dataA > dataB) {
            comparison = 1;
          }
          return comparison;
       });
    },
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

// .income--highlighted .pill {
//   color: $success-active;
//   background: $success;
//   border: 1px solid $success;   
// }
// .income--highlighted .heading,
// .income--highlighted .subheading,
// .income--highlighted .subtext {
//   color: $success-active;
// }
</style>
