import Vue from 'vue'
import App from './App.vue'
import router from './main-router'
import store from './main-store'
import firebase from 'firebase/app';
import 'firebase/auth';
import LoadScript from 'vue-plugin-load-script';
import VueNativeSock from 'vue-native-websocket';
import moment from 'moment';
import TrendChart from "vue-trend-chart";

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(!to.meta.requiresAuth) { //route is public, don't check for authentication
      next();
      store.commit('loading',false)  
  } 
  else {  //route is protected, if authenticated, proceed. Else, login
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        // store.dispatch('getArticles');
        store.commit('login',user) 
        if(!store.getters.connected || !store.getters.ready ) {
          store.dispatch('getPlans');
          store.dispatch('getStaticData');
          store.dispatch('getBanks');
          store.dispatch('seen');
          Vue.prototype.$connect();
        }
        next();
      } 
    })           
  }

  if(to.meta.focusMode) {
    store.commit('focusMode',true);
  }
  else store.commit('focusMode',false);
})
router.afterEach((to, from) => {
  store.dispatch('event','navigation-'+to.name);
})

Vue.use(TrendChart);
Vue.use(LoadScript);

Vue.filter('formatStatus', (value) => {
  var s = (value+" ").replace(/-/gi, " ");
  return s.charAt(0).toUpperCase() + s.slice(1);
});
Vue.filter('formatDate', (value) => {
  return moment(value).format('DD MMM Y'); 
});
Vue.filter('formatDateTime', (value) => {
  return moment(value).format('DD MMM Y, HH:mm:ss'); 
});
Vue.filter('formatShortDate', (value) => {
  if (moment(value).isSame(new Date(), 'year')) {
    return moment(value).format('DD MMMM'); 
  }
  return moment(value).format('DD MMM Y'); 
});
Vue.filter('formatMonth', (value) => {
  var month = new Array();
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  return month[value.getMonth()];
});

var socket = "wss://prod.stub.africa/ws";
if(window.location.hostname=="localhost") {
  socket = "ws://localhost:3000/ws";
}
else if(window.location.hostname=="beta.stub.africa") {
  socket = "wss://beta-api.stub.africa/ws";
  console.log("beta-socket")
}
else if(window.location.hostname=="127.0.0.1") {
  socket = "wss://beta-api.stub.africa/ws";
  console.log("beta-socket")
}


Vue.use(VueNativeSock, socket, {
  reconnection: true, 
  reconnectionDelay: 3000,
  store: store,
  format: "json",
  connectManually: true
})


new Vue({
  router,
  store,
  mounted() {
      var thisy = this;
      this._keyListener = function(e) {
          if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
              console.log(e)
              store.commit('toggleTestMode') 
          }
      };

      document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
      document.removeEventListener('keydown', this._keyListener);
  },
  render: h => h(App)
}).$mount('#app')