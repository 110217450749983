<template>
  <div class="content">

    <header>
      <h1>Income</h1>
      <div>
        <!-- <button class="button button--secondary"><span class="material-icons">get_app</span></button> -->
        <button id="add-income" @click="addIncome()" class="button"><span class="material-icons">payments</span> Add income</button>
      </div>
    </header>

    <div class="list">
      <div class="list__filter" v-if="income.length > 0">
        <input v-model="search" id="search" type="search" placeholder="Search income">
        <div>
          <label class="label--inline">Show</label>
          <select class="month-filter label--inline" v-model="month">
            <option v-for="(month,index) in months" v-bind:value="month.date" :key="index">{{month.name}}</option>
          </select>
          <select class="year-filter" v-model="year">
            <option v-for="(year,index) in (yearrange || years)" v-bind:value="year" :key="index">{{year}}</option>
          </select>
        </div>
      </div>

      <modal v-bind:visible="adding" @close-event="closeModal()" heading="Add income">
        <div class="modal__content">

          <div class="switch">
              <label class="switch-option">
                  <input type="radio" id="add-income-type-payment" name="incometypeswitch" value="payment" v-model="incometype">
                  <span class="switch-mark">Invoice payment</span>
              </label>
              <label class="switch-option">
                  <input type="radio" id="add-income-type-income" name="incometypeswitch" value="income" v-model="incometype">
                  <span class="switch-mark">Other income</span>
              </label>
          </div>

          <fieldset v-if="incometype == 'payment'">
            <label>Invoice</label>
            <v-select :options="outstanding" v-model="tempincome.invoice" label="title">
              <template #no-options>
                No unpaid invoices found
              </template>
            </v-select>
            <label>Amount</label>
            <vue-autonumeric v-model="tempincome.amount"
              :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
              :placeholder="currency+'0.00'"></vue-autonumeric>
            <p v-if="tempincome.invoice" class="input-description">{{currency}} {{tempincome.invoice.$outstanding}} outstanding</p>
            <label>Date</label>
            <vuejs-datepicker v-model="tempincome.date"></vuejs-datepicker>
            <label>Payment</label>
            <label class="radio-button">Account
              <input type="radio" name="incomepayment" value="account" v-model="tempincome.payment">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">Cash
              <input type="radio" name="incomepayment" value="cash" v-model="tempincome.payment">
              <span class="radio-mark"></span>
            </label>
            <!-- <label>Notes</label>
            <textarea v-model="tempincome.notes"></textarea> -->
          </fieldset>

          <fieldset v-if="incometype == 'income'">
            <label>Name</label>
            <input id="add-income-description" v-model="tempincome.name" type="text" placeholder="Project, source etc">
            <label>Amount</label>
            <vue-autonumeric v-model="tempincome.amount"
              id="add-income-amount" 
              :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
              :placeholder="currency+'0.00'"></vue-autonumeric>
            <label>Date</label>
            <vuejs-datepicker v-model="tempincome.date"></vuejs-datepicker>
            <label>Category</label>
            <select name="incomecategory" id="" v-model="tempincome.category">
              <option v-for="cat in incomeCategories" :key="cat.id" :value="cat.value">{{cat.label}}</option>
            </select>
            <label>Payment</label>
            <label class="radio-button">Account
              <input type="radio" name="incomepayment" value="account" v-model="tempincome.payment">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">Cash
              <input type="radio" name="incomepayment" value="cash" v-model="tempincome.payment">
              <span class="radio-mark"></span>
            </label>
            <!-- <label>Notes</label>
            <textarea v-model="tempincome.notes"></textarea> -->
          </fieldset>

        </div>
        <div class="modal__buttons">
          <StubButton id="add-income-submit" class="button submit" @click="createIncome()" v-bind:is-loading="loading">Save</StubButton>
        </div>
      </modal> 

      <div v-if="sorted.length > 0" class="incomes">
        <Income 
          v-for="(income, index) in searched" 
          v-bind:id="income.id" 
          v-bind:class="income.type" 
          v-model="searched[index]" 
          v-on:delete="deleteIncome(income.id)" 
          v-bind:key="income.id" 
          v-bind:currency="currency"></Income>  
      </div>

      <!-- <div v-if="sorted.length > 0" class="list__pagination">
        <button class="button button--secondary"><span class="material-icons">navigate_before</span> May</button>
        <button class="button button--secondary">July <span class="material-icons">navigate_next</span></button>
      </div> -->
      <div v-else class="empty-state">
        <img src="/images/empty-state.svg" alt="Empty">
        <h5>No income added yet</h5>
        <p class="subtext">Track your income to get insights about your cashflow</p>
        <button class="button button--secondary" @click="addIncome()"><span class="material-icons">payments</span> Add income</button>
      </div>
    </div>
  </div>
</template>

<script>
import Income from '../components/Income.vue'
import moment from 'moment';
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue';
import Modal from '../components/Modal.vue'
import vuejsDatepicker from 'vuejs-datepicker';
import StubButton from '../components/StubButton.vue'
import vSelect from 'vue-select'

const refreshPeriodinSeconds  = 5;

export default {
  name: 'income',
  components: {
    Income,
    VueAutonumeric,
    Modal,
    vuejsDatepicker,
    StubButton,
    vSelect
  },
  data: function() {
    return {
      search: null,
      autosaver: null,
      loading: false,
      tempIncome: [],
      month: -1,//moment().month(),
      year: 'All',//new Date().getFullYear(),
      months: [
        { name: "All", date: -1},
        { name: "Jan", date: 0},
        { name: "Feb", date:1},
        { name: "Mar", date:2},
        { name: "Apr", date: 3},
        { name: "May", date: 4},
        { name: "Jun", date:5},
        { name: "Jul", date: 6},
        { name: "Aug", date: 7},
        { name: "Sep", date: 8},
        { name: "Oct", date: 9},
        { name: "Nov", date: 10},
        { name: "Dec", date: 11},        
      ],
      years: ['All',new Date().getFullYear()],
      adding: false,
      tempincome: {
        name: null,
        date: new Date(),
        category: null,
        amount: null,
        invoice: null,
        outstanding: 0,
        payment: 'account'
      },
      incometype: 'payment',
    }
  },
  beforeDestroy () {
    clearInterval(this.autosaver)
  },
  beforeRouteLeave (to, from, next) {
    next();
  },
  computed: {
    currency: function() {
      return this.$store.getters.currency;
    },
    income: function() {
      var income = this.$store.getters.business.income || [];
      return income;
    },
    searched: function() {
      var temp = this.sorted;
      var search = (this.search+"").toLowerCase();
      if(temp) {
        if(temp.length>0) {
          if(this.search===null || this.search =="") return temp;
          else return temp.filter(function(income) {
            return ((income.category+"").toLowerCase().includes(search) || (income.name+"").toLowerCase().includes(search+"") || (income.details+"").toLowerCase().includes(search+""));
          });
        }
        else return null;
      }
      else return null;
    },
    sorted: function() {
       return this.filtered.sort(function(a,b){
         const dateA = moment(a.date);
         const dateB = moment(b.date);
         let comparison = 0;
          
          if (dateA < dateB) {
            comparison = 1;
          } else if (dateA > dateB) {
            comparison = -1;
          }
          return comparison;
       });
    },
    filtered: function() {
      var thisy = this;
      var mon = this.month;
      var yea = this.year;
      var allincome = this.tempIncome.concat(this.income)
      return allincome.filter(function(element) {
        if(element == null) return false;
        if(mon == -1 && yea != 'All') return moment(element.date).year() == thisy.year;
        else if(mon == -1 && yea == 'All') return true;
        else if(mon > -1 && yea == 'All') return moment(element.date).month() == thisy.month;
        else return moment(element.date).month() == thisy.month && moment(element.date).year() == thisy.year;
      });
    },
    monthlySum: function () {
      return this.sorted.reduce((a, b) => a + (b["data"]["amount"] || 0), 0);
    },
    yearrange: function() {
      var allincome = this.tempIncome.concat(this.income).filter(function (el) {return el != null;});;
      if(allincome.length > 0) {
        var earliest = allincome.reduce(function(prev, curr) {
            return moment(prev.date).year() < moment(curr.date).year() ? prev : curr;
        });
        var firstyear = parseInt(moment(earliest.date).year(),10)
        var thisyear = parseInt(moment().year(), 10)
        var yearrange = [...Array(1+thisyear-firstyear).keys()].map(i => i + firstyear);
        yearrange.push('All');
        return yearrange;
      }
      else return null;
    },
    incomeCategories: function() {
      return this.$store.getters.incomeCategories;
    },
    clients: function() {
        var biz = this.$store.getters.business || {};
        return biz.clients || {};
    },
    invoices: function() {
      var inv = [];
      var biz = this.$store.getters.business || {};
      var invoices = biz.invoices || [];
      var thisy = this;
      invoices.forEach(function(i,index) {
          var temp = i;
          temp.title = ((temp.client||{}).name || "Invoice") + " — " + temp.invoicenumber;
          inv.push(temp)
      });
      return inv.sort(function(a,b){
         const dataA = new Date(a.date);
         const dataB = new Date(b.date);
         let comparison = 0;
          
          if (dataA < dataB) {
            comparison = -1;
          } else if (dataA > dataB) {
            comparison = 1;
          }
          return comparison;
       });
    },
    outstanding: function() {
       return this.invoices.filter(function(a){
         return (a.$outstanding>0)
       });
    }
  },
  methods: {
    closeModal: function() {
      this.adding = false;
      this.part = 0;
      this.loading = false;
      this.tempincome = {
        name: null,
        date: new Date(),
        category: null,
        amount:null,
        meta: {
          setup: null,
          work: null,
          cleanup: null,
          max: null,
        }
      }
    },
    addIncome: function () {
      var thisy = this;
      this.adding = true;
      this.part = 0;
    },
    next: function () {
      this.part = 1;
    },
    hasNull: function(target) {
        for (var member in target) {
            if (target[member] == null)
                return true;
        }
        return false;
    },
    createIncome: function () {
      var thisy = this;
      this.loading = true;
      var finaldata = {...this.tempincome};
      console.log(finaldata)
      // console.log(this.tempincome)
      if(this.tempincome.invoice && this.tempincome.invoice.id) {
        finaldata.invoice = this.tempincome.invoice.id
        finaldata.name = "Payment on Invoice "+this.tempincome.invoice.invoicenumber
        finaldata.category = "Invoice payment";
        this.$store.dispatch('addPayment',finaldata).then(function(data){
            thisy.loading = false;
            thisy.closeModal();
        });
      }
      else {
        this.$store.dispatch('createIncome',finaldata).then(function(data){
            thisy.loading = false;
            thisy.closeModal();
        });
      }
     
    },
    deleteIncome: function(income) {
      var thisy = this;
      this.loading = true;
      this.$store.dispatch('deleteIncome',{id:income});
    },
    getIncome: function() {
       return this.$store.getters.income;
    },
  },
}
</script>


<style lang="scss" scoped>
@import './../assets/scss/variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>
