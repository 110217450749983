<template>
  <div class="content">

     <header>
      <h1>Clients</h1>
      <div>
        <button @click="addClient()" class="button"><span class="material-icons">add_circle_outline</span> Add client</button>
      </div>
    </header>

    <div class="list">

        <div class="list__filter" v-if="clients.length > 0">
            <input v-model="search" id="search" type="search" placeholder="Search clients">
            <div>
                <label class="label--inline">Sort</label>
                <select class="clients-sort" v-model="sort">
                    <option value="recent">Recent</option>
                    <option value="a-z">A–Z</option>
                    <option value="z-a">Z–A</option>
                </select>
            </div>
        </div>

        <modal v-bind:visible="adding" @close-event="closeModal()" heading="Add client">
            <div class="modal__content">
                <label for="clientname">Client</label>
                <input type="text" autocomplete="organization" placeholder="Business or person" name="clientname" v-model="tempclient.name">
                <label for="clientemail">Email Address</label>
                <input type="email" name="clientemail" v-model="tempclient.email">
                <div class="space"></div>
                <label for="clientaddress">Address</label>
                <input type="text" name="clientaddress" placeholder="Street address" v-model="tempclient.address">
                <input type="text" name="clientaddress2" v-model="tempclient.address2">
                <input type="text" name="clientsuburb" placeholder="Suburb" v-model="tempclient.suburb">
                <input type="text" name="clientcity" placeholder="City" v-model="tempclient.city">
                <input class="narrow" type="text" name="clientpostcode" placeholder="Post Code" v-model="tempclient.postalcode">
                <!-- <select name="clientcountry" id="">
                    <option value="South Africa">South Africa</option>
                </select> -->
                <p class="input-description">Your invoice must include your client's address. <a target="_blank" href="https://support.stub.africa/invoices#to">Learn more</a>.</p>
            </div>
            <div class="modal__buttons modal__buttons--divider">
                <StubButton @click="createClient()" v-bind:is-loading="saving">Save</StubButton>
                <!-- <button class="button button--danger" @click="deleteEvent()">Delete</button> -->
            </div>
        </modal> 

        <div class="clients">
            <Client 
                v-for="(client,index) in filtered" 
                v-model="filtered[index]" 
                v-on:delete="deleteClient(client.id)" 
                v-bind:key="client.id" 
                v-bind:currency="currency"
                v-bind:invoicecount="tally[client.id]||0"></Client>  
        </div>

        <div v-if="clients.length == 0" class="empty-state">
            <img src="../assets/img/client.svg" alt="Empty">
            <h5>No clients added yet</h5>
            <p class="subtext">Manage your clients here to get insight into your market</p>
            <button class="button button--secondary" @click="addClient()"><span class="material-icons">add_circle_outline</span> Add client</button>
        </div>

    </div>
  </div>
</template>

<script>
import Client from '../components/Client.vue'
import Modal from '../components/Modal.vue'
import StubButton from '../components/StubButton.vue'

export default {
    name: "clients",
    components: {
        Client,
        Modal,
        StubButton
    },
    data: function() {
        return {
            currency: (this.$store.getters.business.currency || 'R')+' ',
            adding: false,
            saving: false,
            search: '',
            sort: 'recent',
            tempclient: {
                name: null,
                email: null,
                address: null,
                address2: null,
                suburb: null,
                city: null,
                postalcode: null,
            }
        }
    },
    methods: {
        addClient: function () {
            this.adding = true;
        },
        closeModal: function () {
            this.adding = false;
        },
        createClient: function () {
            var thisy = this;
            thisy.saving = true;
            this.$store.dispatch('createClient',this.tempclient).then(function(){
                thisy.saving = false;
                thisy.closeModal();
                thisy.tempclient = {}
            });
        },
        deleteClient: function(id) {
            this.$store.dispatch('deleteClient',id);
        },
    },
    computed: {
        clients: function() {
            var clients = this.$store.getters.business.clients || [];
            return clients;
        },
        sorted: function() {
            var thisy = this;
            return this.clients.sort(function(a,b){
                var dataA = a.id;
                var dataB = b.id;
                
                if(thisy.sort=="a-z") {
                    dataA = b.name;
                    dataB = a.name;
                }
                else if(thisy.sort=="z-a") {
                    dataA = a.name;
                    dataB = b.name;
                }
                let comparison = 0;
                
                if (dataA < dataB) {
                    comparison = 1;
                } else if (dataA > dataB) {
                    comparison = -1;
                }
                return comparison;
            });
        },
        filtered: function() {
            var thisy = this;
            return this.sorted.filter(function(element) {
                if(thisy.search.length==0) return true;
                else if(element == null) return false;
                else return (element.name+"").toLowerCase().includes(thisy.search.toLowerCase());
            });
        },
        invcli: function() {
            var inv = [];
            var invoices = this.$store.getters.business.invoices || [];
            var thisy = this;
            invoices.forEach(function(data,index) {
                var c = data.client || {};
                var tempid = c.id;
                if(tempid) inv.push(tempid)
            });
        
            return inv
        },
        tally: function() {
            var tally = {};
            for (let index = 0; index < this.invcli.length; index++) {
                var id = this.invcli[index];
                tally[id] = (tally[id] || 0) + 1;
            }
            return tally;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>