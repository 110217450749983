<template>
  <div class="content">

     <header>
      <h1>Products</h1>
      <div>
        <button @click="addProduct()" class="button"><span class="material-icons">add_circle_outline</span> Add product</button>
      </div>
    </header>

    <div class="list">

        <div class="list__filter" v-if="products.length > 0">
            <input v-model="search" id="search" type="search" placeholder="Search products">
            <div>
                <label class="label--inline">Sort</label>
                <select class="clients-sort" v-model="sort">
                    <option value="recent">Recent</option>
                    <option value="a-z">A–Z</option>
                    <option value="z-a">Z–A</option>
                </select>
            </div>
        </div>

        <modal v-bind:visible="adding" @close-event="closeModal()" heading="Add product">
            <div class="modal__content">
                <div class="tabs">
                    <div class="tab" :class="activeTab == 1 ? 'tab--active' : ''" @click="activeTab = 1">Product</div>
                    <div class="tab" :class="activeTab == 2 ? 'tab--active' : ''" @click="activeTab = 2">Cost</div>
                    <div class="tab" :class="activeTab == 3 ? 'tab--active' : ''" @click="activeTab = 3">Notes</div>
                </div>

                <div :class="activeTab == 1 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="description">Name</label>
                    <input type="text" name="description" v-model="tempproduct.description">
                    <label for="price">Price</label>
                    <vue-autonumeric v-model="tempproduct.price"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                        :placeholder="currency+'0.00'"></vue-autonumeric>
                    <label for="productunit">Units</label>
                    <label class="radio-button radio-button--inline">Quantity
                        <input type="radio" name="productunit" value="quantity" v-model="tempproduct.unit">
                        <span class="radio-mark"></span>
                    </label>
                    <label class="radio-button radio-button--inline">Hours
                        <input type="radio" name="productunit" value="hours" v-model="tempproduct.unit">
                        <span class="radio-mark"></span>
                    </label>
                    
                </div>

                <div :class="activeTab == 2 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="price">Cost</label>
                    <vue-autonumeric v-model="tempproduct.cost"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                        :placeholder="currency+'0.00'"></vue-autonumeric>
                    <p class="input-description">
                        Price:
                        <vue-autonumeric v-bind:value="tempproduct.price"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'" 
                            tag="span"
                            contenteditable="false"></vue-autonumeric>
                    </p>
                    <label for="profit">Profit</label>
                    <vue-autonumeric v-bind:value="profit"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                        :placeholder="currency+'0.00'" 
                        disabled></vue-autonumeric>
                    <p class="input-description">Markup: {{profitmargin}}</p>
                </div>

                <div :class="activeTab == 3 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="notes">Notes</label>
                    <textarea name="notes" v-model="tempproduct.notes"></textarea>
                </div>

            </div>
            <div class="modal__buttons modal__buttons--divider">
                <StubButton @click="createProduct()" v-bind:is-loading="saving">Save</StubButton>
                <!-- <button class="button button--danger" @click="deleteEvent()">Delete</button> -->
            </div>
        </modal>

        <div class="products">
            <Product 
                v-for="(product,index) in filtered" 
                v-model="filtered[index]" 
                v-bind:id="product.id"
                v-on:delete="deleteProduct(product.id)" 
                v-bind:key="product.id" 
                v-bind:currency="currency"></Product>  
        </div>

        <div v-if="products.length == 0" class="empty-state">
            <img src="../assets/img/products.svg" alt="Empty">
            <h5>No products added yet</h5>
            <p class="subtext">Add products or services to an invoice and you can reuse them on all of your invoices</p>
            <button class="button button--secondary" @click="addProduct()"><span class="material-icons">add_circle_outline</span> Add product</button>
        </div>

    </div>
  </div>
</template>

<script>
import Modal from '../components/Modal.vue'
import StubButton from '../components/StubButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import Product from '../components/Product.vue'

export default {
    name: "products",
    components: {
        Modal,
        StubButton,
        VueAutonumeric,
        Product
    },
    data: function() {
        return {
            sort: 'recent',
            search: '',
            adding: false,
            saving: false,
            tempproduct: {
                description: null,
                quantity: 1,
                price: 0,
                amount: 0,
                cost: 0,
                notes: null,
                unit: 'quantity'
            },
            activeTab: 1
        }
    },
    methods: {
        addProduct: function () {
            this.adding = true;
        },
        closeModal: function () {
            this.adding = false;
        },
        createProduct: function () {
            var thisy = this;
            thisy.saving = true;
            this.$store.dispatch('createProduct',this.tempproduct).then(function(){
                thisy.saving = false;
                thisy.closeModal();
                thisy.tempproduct =  {
                  description: null,
                  quantity: 1,
                  price: 0,
                  amount: 0,
                  cost: 0,
                  notes: null,
                  unit: 'quantity'
                };
            });
        },
        deleteProduct: function(id) {
            this.$store.dispatch('deleteProduct',id).then(function(data) {
            });
        }
    },
    computed: {
        currency: function() {
            return this.$store.getters.currency;
        },
        products: function() {
            var products = this.$store.getters.business.products || [];
            return products;
        },
        sorted: function() {
            var thisy = this;
            return this.products.sort(function(a,b){
                var dataA = a.id;
                var dataB = b.id;
                
                if(thisy.sort=="a-z") {
                    dataA = b.description;
                    dataB = a.description;
                }
                else if(thisy.sort=="z-a") {
                    dataA = a.description;
                    dataB = b.description;
                }

                let comparison = 0;
                
                if (dataA < dataB) {
                    comparison = 1;
                } else if (dataA > dataB) {
                    comparison = -1;
                }
                return comparison;
            });
        },
        filtered: function() {
            var thisy = this;
            return this.sorted.filter(function(element) {
                if(thisy.search.length==0) return true;
                else if(element == null) return false;
                else return (element.description+"").toLowerCase().includes(thisy.search.toLowerCase());
            });
        },
        profitmargin :function() {
            if(this.tempproduct.cost==null || this.tempproduct.price==null) {
                return '--';
            }
            if (this.tempproduct.cost == 0) {
                return '100%';
            } 
            if (this.tempproduct.price == 0) {
                return '-100%';
            }
            return Math.round((this.tempproduct.price - this.tempproduct.cost) / this.tempproduct.cost * 10000)/100 + '%';
        },
        profit: function() {
            return this.tempproduct.price - this.tempproduct.cost;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>