export default {
    // These are the fall back categoris if the server connection fails
    expenseCategories: [
        { value: "", label: "" },
        { value: "Business Development", label: "Business Development" },
        { value: "Consumables", label: "Consumables" },
        // { value: "Depreciation", label: "Depreciation" },
        { value: "Donations", label: "Donations" },
        { value: "Equipment", label: "Equipment" },
        { value: "Fuel", label: "Fuel" },
        { value: "Insurance", label: "Insurance" },
        { value: "Internet", label: "Internet" },
        { value: "Interest", label: "Interest" },
        { value: "Legal", label: "Legal" },
        { value: "Marketing", label: "Marketing" },
        { value: "Meals & Entertainment", label: "Meals & Entertainment" },
        { value: "Phone", label: "Phone" },
        { value: "Printing & Stationary", label: "Printing & Stationary" },
        { value: "Professional Development", label: "Professional Development" },
        { value: "Professional Fees", label: "Professional Fees" },
        { value: "Raw Materials", label: "Raw Materials" },
        { value: "Refunds", label: "Refunds" },
        { value: "Rent", label: "Rent" },
        { value: "Repairs & Maintenance", label: "Repairs & Maintenance" },
        { value: "Salaries & Wages", label: "Salaries & Wages" },
        { value: "Software", label: "Software" },
        { value: "Subscriptions & Services", label: "Subscriptions & Services" },
        { value: "Tax", label: "Tax" },
        { value: "Transport & Travel", label: "Transport & Travel" },        
        { value: "Utilities", label: "Utilities" },
        { value: "Other Expenses", label: "Other Expenses" },
    ],
    incomeCategories: [
        { value: "", label: "" },
        { value: "Sales", label: "Sales" },
        { value: "Interest", label: "Interest" },
        { value: "Investment", label: "Investment" },
        { value: "Refund", label: "Refund" },
        { value: "Other Income", label: "Other Income" },
    ]
}