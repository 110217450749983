<template>
  <div v-if="user && b">
    <div class="profile" v-if="details" @change="dirtify()">
      <div>
        <h3>Business Details</h3>
        <div class="business-logo">
          <label class="business-logo__cta">
            <label for="logo">Logo</label>
            <div id="logo-preview" class="profile-image" v-bind:style="getBackground">
              <input type="file" id="logo" name="logo" accept="image/*" @change="updateLogo">
            </div>
            <a class="button business-logo__instructions">Choose image</a>
          </label>
          <p class="input-description">Your logo should be 500 x 500px.</p>
        </div>
        <div>
          <label for="name">
            Business name
          </label>
          <input v-model="details.name" id="name" type="text" placeholder="">
        </div>
        <div>
          <label for="email">
            Email address
          </label>
          <input v-model="user.email" id="email" type="text" disabled="disabled">
        </div>
        <div>
          <label for="website">
            Website
          </label>
          <input v-model="details.website" id="website" type="text" placeholder="">
        </div>
        <div>
          <label for="phone">
            Phone number
          </label>
          <input v-model="details.phone" id="phone" type="text" placeholder="">
        </div>
        <div>
          <label for="address">
            Address line 1
          </label>
          <input v-model="details.address" id="address" type="text" placeholder="">
        </div>
        <div>
          <label for="address">
            Address line 2
          </label>
          <input v-model="details.address2" id="address2" type="text" placeholder="">
        </div>
        <div>
          <label for="suburb">
            Suburb
          </label>
          <input v-model="details.suburb" id="suburb" type="text" placeholder="">
        </div>
        <div>
          <label for="city">
            City
          </label>
          <input v-model="details.city" id="city" type="text" placeholder="">
        </div>
        <div>
          <label for="postalcode">
            Postal code
          </label>
          <input v-model="details.postalcode" id="postalcode" type="text" placeholder="">
        </div>

        <div class="space-4"></div>

        <h3>Tell us a bit more about your business</h3>
        <div>
          <label for="accountname">
            Industry
          </label>
          <input v-model="details.industry" type="text" placeholder="">
        </div>
        <div>
          <label for="businessemployees">
            Number of employees
          </label>
          <div>
            <label class="radio-button">1
              <input type="radio" v-model="details.employees" name="businessemployees" value="1">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">2 - 5
              <input type="radio" v-model="details.employees" name="businessemployees" value="2 - 5">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">6 - 10
              <input type="radio" v-model="details.employees" name="businessemployees" value="6 - 10">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">11 - 20
              <input type="radio" v-model="details.employees" name="businessemployees" value="11 - 20">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">21 - 50
              <input type="radio" v-model="details.employees" name="businessemployees" value="21 - 50">
              <span class="radio-mark"></span>
            </label>
            <label class="radio-button">50+
              <input type="radio" v-model="details.employees" name="businessemployees" value="50+">
              <span class="radio-mark"></span>
            </label>
          </div>
        </div>
        <div>
          <label for="">
            Annual turn over (optional)
          </label>
           <vue-autonumeric class="" v-model="details.turnover"
          :options="{digitGroupSeparator: ' ',  currencySymbol: currency, minimumValue: '0.00'}"
          :placeholder="currency+'0.00'"></vue-autonumeric>
          <p class="input-description">We will use this to keep track of your monthly progress.</p>
        </div>

        <div class="space-4"></div>

        <div class="space-4"></div>
        
        <h3>Other Financial Details</h3>
        <p>These financial details may appear on your invoices</p>
        <div>
          <label for="accountname">
            Currency symbol
          </label>
          <input v-model="details.currency" type="text" placeholder="e.g R, $, ¥, ₹">
        </div>
        <div>
          <label for="registrationnumber">
            Company registration number
          </label>
          <input v-model="details.registrationnumber" id="registrationnumber" type="text" placeholder="">
        </div>
        <div>
          <label for="vatnumber">
            VAT registration number
          </label>
          <input v-model="details.vatnumber" id="vatnumber" type="text" placeholder="">
        </div>

      </div>
    </div>
    
    <div class="space-4"></div>

    <div class="submit">
      <StubButton @click="saveProfile()" class="primary" v-bind:is-loading="loading">Save business profile</StubButton>
    </div>
    <modal v-bind:visible="showModal" @close-event="(showModal=false)" heading="Discard profile change?">
      <div class="modal__content">
        <p>You have unsaved changes to your business profile. Would you like to save these changes?</p>
      </div>
      <div class="modal__buttons">
        <StubButton @click="saveProfile()" class="primary" v-bind:is-loading="loading">Save profile</StubButton>
        <StubButton @click="leave()" class="button button--secondary">Discard changes</StubButton>
        
      </div>
    </modal>

    <modal class="modal--send-successful" v-bind:visible="prompter" @close-event="prompter=false" heading="Next up">
        <div class="modal__content">
            <div class="convert-quote">
              <img src="../../assets/img/invoices.svg" alt="">
            </div>
            <h2>Add your bank details</h2>
            <br/>
            <p>Add your bank details to your profile so your customers know how to pay you. These details will appear on every quote and invoice.</p>
        </div>
        <div class="modal__buttons">
            <button class="button button--secondary" @click="prompter=false">Maybe later</button>
            <router-link class="button" to="/account/bank">Add bank details</router-link>
        </div>
        <br/>
    </modal>
  </div>
</template>
    
<script>
import VueAutonumeric from '../../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue';
import StubButton from '../../components/StubButton.vue';
import Modal from '../../components/Modal.vue'

export default {
  name: 'business',
  components: {
    VueAutonumeric,
    StubButton,
    Modal,
  },
  data: function() {
    return {
      user: this.$store.getters.user,
      templogo: null,
      loading: false,
      next: null,
      showModal: false,
      cache: null,
      business: null,
      account: null,
      dirty: false,
      accountnumber: null,
      enablepayments: false,
      confirm: false,
      placeholder: "<No account number>",
      accepting: false,
      prompter: false,
    }
  },
  methods: {
    saveProfile: function() {
        var files =  document.getElementById('logo').files;
        var thisy = this;
        var prompt = !((this.details||{}).journeys||{}).onboarding;
        this.dirty = false;
        this.loading = true;
        var bizniz = {}
        bizniz.details = this.details;
        bizniz.details.journeys = {onboarding: true};   
        if(files.length>0) {
          var url = "https://api.cloudinary.com/v1_1/stub-africa/upload";
          var xhr = new XMLHttpRequest();
          var fd = new FormData();
          xhr.open('POST', url, true);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
          console.log("uploadling");
          xhr.onreadystatechange = function(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
              var response = JSON.parse(xhr.responseText);
              var url = response.secure_url;
              bizniz.details.logo = url;
              console.log(url)
              thisy.$store.dispatch('updateBusiness', bizniz).then(function(){
                setTimeout(function () {
                  thisy.loading = false;  
                  if(thisy.next) thisy.next();
                  else if(prompt) thisy.prompter = true;
                }, 350);
              })
            }          };
          //fd.append('public_id', this.state.user.uid);
          fd.append('upload_preset', "hvvsnvtx");
          fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
          fd.append('file',files[0]);
          xhr.send(fd);
        }
        else thisy.$store.dispatch('updateBusiness', bizniz).then(function(){
           setTimeout(function () {
            thisy.loading = false;  
            if(thisy.next) thisy.next();
            else if(prompt) thisy.prompter = true;
           }, 350);
        })
    },
    updateLogo: function(event) {
      console.log(event)
      var reader = new FileReader();
      var thisy = this;
      reader.onload = function(){
        var output = document.getElementById('logo');
        var image = reader.result;
        document.getElementById('logo-preview').style.backgroundColor = "transparent";
        document.getElementById('logo-preview').style.backgroundImage = "url('"+image+"')";
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    leave: function(){
      this.showModal = false;
      console.log(this.next)
      this.next()
      
    },
    dirtify: function() {
      this.dirty = true;
    }
  },
  computed: {
    testMode: function() {
      return this.$store.getters.testMode;
    },
    b: function() {
      return this.$store.getters.business;
    },
    details: function() {
      return this.b.details;
    },
    currency: function() {
      return (this.details.currency || "R")+" ";
    },
    getBackground: function() {
      var logo = "background-color:transparent;background-image: url('/nologo.png');"
      if(this.details) {
        if(this.details) {
          if(this.details.logo) {
            logo = "background-color:transparent;background-image: url('"+this.details.logo+"');"
          }
        }
      }
      return logo;
    },
  },
  beforeRouteLeave: function(to, from, next) { 
      var b = JSON.stringify(this.business);
      var c = JSON.stringify(this.cache);
      console.log(this.business)
      if(this.dirty){
        this.next = next;
        this.showModal = true;
      }
      else next()
  }
}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/variables';

.profile-image {
  width: 120px;
  height: 120px;
  background-color: $gray-200;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: $space-1;
}
#logo {
  opacity: 0;
}
</style>
