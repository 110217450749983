<template>
  <div class="focus">
    <router-link to="/"><img class="stub-logo" src="../assets/img/stub-logo.svg" alt="Stub"></router-link>
    <div class="focus__content">
      <h3>{{title}}</h3>
      <p>{{subtitle}}</p>
      <div class="form__actions" v-if="cta">
        <router-link v-bind:to="cta.target"><button type="button">&nbsp;&nbsp;{{cta.text}}&nbsp;&nbsp;</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Copyright from '../components/Copyright.vue'

export default {
  name: 'status',
  components: {
    Copyright
  },
  data: function () {
    return {
        title: this.$route.meta.title,
        subtitle: this.$route.meta.subtitle,
        cta: this.$route.meta.cta
    }
  },
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';
.stub-logo {
  display: block;
  margin: $space-2 auto;
}
@media screen and (min-width: $breakpoint-md) {
  .stub-logo {
    margin: $space-2 auto $space-3;
  }
}
</style>