<template>
    <div class="list__row" v-if="!isDeleted">
        <div class="cell-wrapper" @click="edit()">
            <div class="cell">
                <h2 class="subheading">{{client.name}}</h2>
            </div>   
            <div class="cell cell--stretchy">
                <p class="subtext">{{client.email}}</p>
            </div>
            <div class="cell">
                <p v-if="invoicecount==1" class="subtext"><em>{{invoicecount}} invoice</em></p>
                <p v-else class="subtext"><em>{{invoicecount}} invoices</em></p>
                <!-- <p class="subtext">{{client.email}}</p> -->
            </div>       
        </div>
        
        <modal v-bind:visible="editIsFocused" @close-event="closeEditModal()" heading="Edit client">
            <div class="modal__content">
                <label for="clientname">Client</label>
                <input type="text" autocomplete="name" placeholder="Business or person" name="clientname" v-model="client.name">
                <label for="clientemail">Email Address</label>
                <input type="email" name="clientemail" v-model="client.email">
                <div class="space"></div>
                <label for="clientaddress">Address</label>
                <input type="text" name="clientaddress" placeholder="Street address" v-model="client.address">
                <input type="text" name="clientaddress2" v-model="client.address2">
                <input type="text" name="clientsuburb" placeholder="Suburb" v-model="client.suburb">
                <input type="text" name="clientcity" placeholder="City" v-model="client.city">
                <input class="narrow" type="text" name="clientpostcode" placeholder="Post Code" v-model="client.postalcode">
                <!-- <select name="clientcountry" id="">
                    <option value="South Africa">South Africa</option>
                </select> -->
                <p class="input-description">Your invoice must include your client's address. <a target="_blank" href="https://support.stub.africa/invoices#to">Learn more</a>.</p>
            </div>
            <div class="modal__buttons modal__buttons--divider">
                <StubButton @click="updateClient()" v-bind:is-loading="saving">Save</StubButton>
                <button class="button button--danger" @click="confirmDelete()">Delete</button>
            </div>
        </modal>

        <modal v-bind:visible="confirmDeleteIsFocused" @close-event="confirmDeleteIsFocused = false" heading="Delete client">
            <div class="modal__content">
                <h3>Are you sure that you want to delete {{client.name}}?</h3>
                <p>This will permanently delete this client record and cannot be undone.</p>
            </div>
            <div class="modal__buttons">
                <StubButton class="button button--disaster" @click="deleteEvent()" v-bind:is-loading="saving">Delete</StubButton>
                <button class="button--secondary" @click="confirmDeleteIsFocused = false; editIsFocused = true;">Cancel</button>
            </div>
        </modal>

    </div>
</template>

<script>
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'

export default {
    name: 'client',
    components: {
        Modal,
        StubButton
    },
    props: ['id','value','currency','invoicecount'],
    data: function() {
        return {
            client: this.value,
            hasFocus: [0,0,0,0,0],
            editIsFocused: false,
            isDeleted: false,
            saving: false,
            confirmDeleteIsFocused: false,
        }
    },
    methods: {
        closeEditModal: function() {
            this.editIsFocused = false;
        },
        edit: function() {
            this.editIsFocused = true;
        },
        updateClient: function() {
            var thisy = this;
            thisy.saving = true;
            var client = this.client;
            this.$store.dispatch('updateClient',client).then(function(data){
                console.log(data)
                thisy.isFocused = false;
                thisy.saving = false;
                thisy.editIsFocused = false;
            });
        },
        confirmDelete: function() {
            this.editIsFocused = false
            this.confirmDeleteIsFocused = true
        },
        deleteEvent: function() {
            var thisy = this;
            thisy.saving = true;
            this.$emit('delete', this.value);
            setTimeout(function() {
               thisy.confirmDeleteIsFocused = false;
            }, 350)
        }
    }
}
</script>