<template>
  <div v-if="b.profile">
    <div class="profile">
      <div class="section details">
        <h3 class="section__heading">Profile</h3>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="firsname">
            First name
          </label>
          <input v-model="profile.firstname" id="firstname" type="text" placeholder="Sara">
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="lastname">
            Last name
          </label>
          <input v-model="profile.lastname" id="lastname" type="text" placeholder="Blakely">
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="role">
            Role
          </label>
          <input v-model="profile.role" class="" autocomplete="organization-title" id="role" type="text" placeholder="">
          <p class="input-description">What do you do in the business?</p>
          <StubButton class="primary" @click="updateProfile()" v-bind:is-loading="loadingProfile">Update details</StubButton>
        </div>
        <div class="space-4"></div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Email address
          </label>
          <input v-model="user.email" autocomplete="off" id="email" type="text" placeholder="email@domain.com">
          <modal v-bind:visible="modalVisible" @close-event="closeModal()">
            <div class="mb-4">
              <h3>Verify you new email to change it</h3>
              <p class="verification__message">We've sent a verification code to {{email}}</p>
              <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                Verification code
              </label>
              <input v-model="code" id="verification" type="number" placeholder="123456">
            </div>
            <StubButton class="primary" @click="changeEmail()" v-bind:is-loading="loadingEmail">Verify email</StubButton>
          </modal>
          <button class="primary" @click="verifyEmail()">Change email</button>
        </div>
        <div class="space-4"></div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Password
          </label>
          <StubButton @click="resetPassword()" class="primary" v-bind:is-loading="loadingPassword">Change password</StubButton>
        </div>
        <div v-if="testMode" class="space-4"></div>
        <h3 v-if="testMode" class="section__heading">Generate dummy data</h3>
        <div v-if="testMode" class="mb-4">
          <p class="subtext">This will generate 5 fake clients, income and expenses</p> 
          
          <StubButton @click="generate()" class="button button--accent" v-bind:is-loading="loadingDummy">Generate</StubButton>
        </div>
        <div class="space-4"></div>
        <h3 class="section__heading">Delete your stub account</h3>
        <div class="mb-4">
          <p class="subtext">Your login credentials and all your business data will be deleted. </p> 
          <p class="subtext">Invoices that you have already sent will still be viewable.</p>
          
          <StubButton @click="deletePopup = true" class="button button--danger button--priority">Delete my account</StubButton>
        </div>
        <modal v-bind:visible="deletePopup" @close-event="deletePopup = false" heading="Permanently delete account?">
          <div class="modal__content">
              <p>Once you delete a your account, there is no going back. Please be certain.</p>
              <label class="checkbox">Delete the stub profile for {{details.name}}
                  <input type="checkbox" name="" v-model="del">
                  <span class="check-mark"></span>
              </label>
          </div>
          <div class="modal__buttons">
              <StubButton @click="deleteAccount()" class="button button--disaster button--priority" :disabled="!del" :is-loading="deleting">Delete account</StubButton>
              <button class="button--secondary button--priority" @click="deletePopup = false;biz=false;">Cancel</button>
          </div>
        </modal>
        <modal class="modal--send-successful" v-bind:visible="deleted" @close-event="bye()" heading="Account deleted">
          <div class="modal__content">
              <img src="../../assets/img/hi-five.svg" alt="">
              <h2>Your account has been deleted</h2>
              <p>All the best with {{details.name|| 'your business'}}.</p>
          </div>
          <div class="modal__buttons">
              <button class="button--secondary" @click="bye()">Bye</button>
          </div>
      </modal>
      </div>
    </div>
  </div>
</template>
    
<script>
import Modal from '../../components/Modal.vue'
import StubButton from '../../components/StubButton.vue'

export default {
  name: 'settings',
  components: {
    Modal,
    StubButton,
  },
  data: function() {
    return {
      email: null,
      code: null,
      modalVisible: false,
      loadingProfile: false,
      loadingEmail: false,
      loadingPassword: false,
      loadingDummy: false,
      profile: null,
      deletePopup: false,
      del: false,
      deleted: false,
      deleting: false
    }
  },
  computed: {
    b: function() {
      if(this.profile==null && this.$store.getters.business && this.$store.getters.business.profile) {
        this.profile = Object.assign({}, this.$store.getters.business.profile);
      }
     return this.$store.getters.business;
    },
    details: function() {
      var biz = this.b || {};
      return biz.details || {};
    },
    user: function() {
      if(this.email==null && this.$store.getters.user && this.$store.getters.user.email) {
        this.email = this.$store.getters.user.email;
      }
      return this.$store.getters.user;
    },
    testMode: function() {
      return this.$store.getters.testMode;
    },
  },
  methods: {
    resetPassword: function() {
        var thisy = this;
        thisy.loadingPassword = true;
        this.$store.dispatch('forgotPassword',{email: this.email}).then(function(){
            thisy.$store.dispatch('plainLogout');
            thisy.$router.push('/forgot-password-sent');
            thisy.loadingPassword = false;
        });
    },
    verifyEmail: function() {
        var thisy = this;
        if(this.$store.getters.user.email != this.email) {
          thisy.showModal()
          this.$store.dispatch('verifyEmail',{email: this.email}).then(function(data){
              
          });
        }
        else console.log("No change");
    },
    changeEmail: function() {
        var thisy = this;
        thisy.loadingEmail = true;
        this.$store.dispatch('changeEmail',{email: this.email,code:this.code}).then(function(data){
            thisy.modalVisible = false;
            thisy.code = null;
            thisy.$store.commit('updateEmail',thisy.email);
            thisy.loadingEmail = false;
        });
    },
    updateProfile: function() {
        var thisy = this;
        thisy.loadingProfile = true;
        this.$store.dispatch('updateProfile',this.profile).then(function(data){
            thisy.loadingProfile = false;
        });
    },
    closeModal: function() {
      this.modalVisible = false;
    },
    showModal: function() {
      this.modalVisible = true;
    },
    deleteAccount: function() {
      if(this.del) {
        var thisy = this;
        this.deleting = true;
        this.$store.dispatch('deleteAccount').then(function(data){
            thisy.deleted = true;
            thisy.deleting = false;
        });
      }
    },
    bye: function() {
      this.$router.push('/');
      this.$store.commit('logout');
    },
    generate: function() {
        var thisy = this;
        thisy.loadingDummy = true;
        this.$store.dispatch('generateDummyData').then(function(data){
            console.log(data);
            if(data) {
              data.expenses.forEach(element => {
              thisy.$store.dispatch('createExpense',element)
              });
              data.income.forEach(element => {
                thisy.$store.dispatch('createIncome',element)
              });
              data.clients.forEach(element => {
                thisy.$store.dispatch('createClient',element)
              });
              data.products.forEach(element => {
              thisy.$store.dispatch('createProduct',element)
              });
            }
            thisy.loadingDummy = false;
        });
    },
  },
  created: function() {
    this.profile = this.$store.getters.business.profile;
  },
}
</script>


<style lang="scss" scoped>
.input-description {
  margin-bottom: 5px;
}
</style>
