<template>
    <div class="list__row" v-if="!isDeleted">
        <div class="cell-wrapper" @click="edit()">
            <div class="cell cell--stretchy">
                <h2 class="subheading">{{product.description}}</h2>
            </div>
            <vue-autonumeric v-model="product.price"
                :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                tag="div" contenteditable="false"
                class="cell"></vue-autonumeric>    
        </div>
        
        <modal v-bind:visible="editIsFocused" @close-event="closeEditModal()" heading="Edit product">
            <div class="modal__content">
                <div class="tabs">
                    <div class="tab" :class="activeTab == 1 ? 'tab--active' : ''" @click="activeTab = 1">Product</div>
                    <div class="tab" :class="activeTab == 2 ? 'tab--active' : ''" @click="activeTab = 2">Cost</div>
                    <div class="tab" :class="activeTab == 3 ? 'tab--active' : ''" @click="activeTab = 3">Notes</div>
                </div>

                <div :class="activeTab == 1 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="description">Description</label>
                    <input type="text" name="description" v-model="product.description">
                    <label for="price">Price</label>
                    <vue-autonumeric v-model="product.price"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                        :placeholder="currency+'0.00'"></vue-autonumeric>
                    <label for="productunit">Units</label>
                    <label class="radio-button radio-button--inline">Quantity
                        <input type="radio" name="productunit" value="quantity" v-model="product.unit">
                        <span class="radio-mark"></span>
                    </label>
                    <label class="radio-button radio-button--inline">Hours
                        <input type="radio" name="productunit" value="hours" v-model="product.unit">
                        <span class="radio-mark"></span>
                    </label>
                </div>

                <div :class="activeTab == 2 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="price">Cost</label>
                    <vue-autonumeric v-model="product.cost"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                        :placeholder="currency+'0.00'"></vue-autonumeric>
                    <p class="input-description">
                        Price:
                        <vue-autonumeric v-bind:value="product.price"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'" 
                            tag="span"
                            contenteditable="false"></vue-autonumeric>
                    </p>
                    <label for="profit">Profit</label>
                    <vue-autonumeric v-bind:value="profit"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                        :placeholder="currency+'0.00'" 
                        disabled></vue-autonumeric>
                    <p class="input-description">Markup: {{profitmargin}}</p>
                </div>

                <div :class="activeTab == 3 ? 'tab-content tab-content--active' : 'tab-content'">
                    <label for="notes">Notes</label>
                    <textarea name="notes" v-model="product.notes"></textarea>
                </div>
            </div>
            <div class="modal__buttons modal__buttons--divider">
                <StubButton @click="updateProduct()" v-bind:is-loading="saving">Save</StubButton>
                <button class="button button--danger" @click="confirmDelete()">Delete</button>
            </div>
        </modal>

        <modal v-bind:visible="confirmDeleteIsFocused" @close-event="confirmDeleteIsFocused = false" heading="Delete product">
            <div class="modal__content">
                <h3>Are you sure that you want to delete {{product.description}}?</h3>
                <p>This will permanently delete this product record and cannot be undone.</p>
            </div>
           <div class="modal__buttons">
                <StubButton class="button button--disaster" @click="deleteEvent()" v-bind:is-loading="saving">Delete</StubButton>
                <button class="button--secondary" @click="confirmDeleteIsFocused = false; editIsFocused = true;">Cancel</button>
            </div>
        </modal>

    </div>
</template>

<script>
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
    name: 'product',
    components: {
        Modal,
        StubButton,
        VueAutonumeric,
    },
    props: ['value','currency'],
    data: function() {
        return {
            product: this.value,
            hasFocus: [0,0,0,0,0],
            editIsFocused: false,
            isDeleted: false,
            saving: false,
            confirmDeleteIsFocused: false,
            activeTab: 1
        }
    },
    methods: {
        closeEditModal: function() {
            this.editIsFocused = false;
        },
        edit: function() {
            this.editIsFocused = true;
        },
        updateProduct: function() {
            var thisy = this;
            thisy.saving = true;
            var product = this.product;
            console.log(product)
            this.$store.dispatch('updateProduct',product).then(function(data){
                thisy.isFocused = false;
                thisy.saving = false;
                thisy.editIsFocused = false;
            });
        },
        confirmDelete: function() {
            this.editIsFocused = false
            this.confirmDeleteIsFocused = true
        },
        deleteEvent: function() {
            var thisy = this;
            thisy.saving = true;
            this.$emit('delete', this.value);
            setTimeout(function() {
               thisy.confirmDeleteIsFocused = false;
            }, 350)
        }
    },
    computed: {
        profit: function() {
            return this.product.price - this.product.cost;
        },
        profitmargin: function() {
             if(this.product.cost==null || this.product.price==null) {
                return '--';
            }
            if (this.product.cost == 0) {
                return '100%';
            } 
            if (this.product.price == 0) {
                return '-100%';
            }
            return Math.round((this.product.price - this.product.cost) / this.product.cost * 10000)/100 + '%';            
        }
    }
}
</script>