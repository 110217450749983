<template>
  <div class="forgot-password focus">
    <router-link to="/"><img class="stub-logo" src="../assets/img/stub-logo.svg" alt="stub logo"></router-link>
    <form class="focus__content">
      <h2>Reset password</h2>
      <p>Enter the email address you used to register for stub and we'll send you a link to reset your password. </p>
      <div>
        <label for="email">Email</label>
        <input v-model="email" autocomplete="email" class="" id="email" type="email" placeholder="name@business.com">
      </div>
      <div class="form__actions">
        <StubButton @click="forgot()" type="button" :is-loading="loading">
          Send reset email
        </StubButton>
        <router-link class="action" to="/login">Log in</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import Copyright from '../components/Copyright.vue'
import StubButton from '../components/StubButton.vue'

export default {
  name: 'forgot-password',
  components: {
    Copyright,
    StubButton
  },
  data: function () {
    return {
      loading: false,
      email: null,
    }
  },
  methods: {
    forgot: function(event) {
      if(this.email) {
        var thisy  = this;
        this.loading = true;
        this.$store.dispatch('forgotPassword',{email: this.email}).then(function(){
          thisy.loading = false;
          thisy.$router.push('/forgot-password-sent');
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

.stub-logo {
  display: block;
  margin: $space-2 auto;
}
@media screen and (min-width: $breakpoint-md) {
  .stub-logo {
    margin: $space-2 auto $space-3;
  }
}
</style>