<template>
  <div id="app" class="app" v-bind:class="{ 'app--loggedin': ready,'app--loggedout': !ready, 'focus-mode': focusMode, 'expired': !active && !trial && $route.name != 'settings-plans' && $route.meta.requiresAuth, 'modal-active': modalActive }">
    
    <!-- Loader -->
    <Loader v-if="loading()"></Loader>
    <!-- end Loader -->

    <!-- Logged-in Header -->
    <header class="nav" v-if="user() && business()">
      <div class="nav-bar">
        <router-link @click.native="hideMenu()" class="nav-logo" to="/insights"><img class="stub-logo" src="./assets/img/stub-logo.svg" alt="Stub"></router-link>
        <div class="nav-row" @click="toggleMenu()">
          <div class="nav-toggle">
            <i class="nav-toggle__menu material-icons">menu</i>
            <i class="nav-toggle__close material-icons">close</i>
          </div>
          <span class="nav-label nav-label--menu">Menu</span>
          <span class="nav-label nav-label--close">Close</span>
        </div>
      </div>
      <div class="nav-menu">
        <div class="nav-menu__section nav-menu__section--logo">
          <div class="nav-logo" @click="$router.push('/insights');hideMenu();">
            <img class="stub-logo" src="./assets/img/stub-logo.svg" alt="Stub">
          </div>
        </div>
        
        <div class="nav-menu__section">
          <router-link id="nav-insights" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/insights"><img src="./assets/icons/insights.svg" class="icon">Insights</router-link>
          <router-link id="nav-invoices" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/quotes"><img src="./assets/icons/quotes.svg" class="icon">Quotes</router-link>
          <router-link id="nav-invoices" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/invoices"><img src="./assets/icons/invoices.svg" class="icon">Invoices <span v-if="invoiceNotifications>0" class="notification-badge">{{invoiceNotifications}}</span></router-link>
          <router-link id="nav-income" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/income"><img src="./assets/icons/income.svg" class="icon">Income</router-link>
          <router-link id="nav-expenses" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/expenses"><img src="./assets/icons/expenses.svg" class="icon">Expenses</router-link>
        </div>

        <div class="nav-menu__section">
          <router-link id="nav-products" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/products"><img src="./assets/icons/products.svg" class="icon">Products</router-link>
          <router-link id="nav-clients" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/clients"><img src="./assets/icons/clients.svg" class="icon">Clients</router-link>
          <router-link id="nav-settings" @click.native="hideMenu()" class="nav-item nav-item--icon" to="/account/business">
            <div class="profile" v-bind:style="getLogo()"></div>
            <div>Settings</div>
          </router-link>
        </div>

        <div class="nav-menu__section">
          <a @click="friend={};invitePopup=true;" class="nav-item nav-item--icon subtext"><img src="./assets/icons/prezzie.svg" class="icon">Invite a friend</a>
          <a class="nav-item nav-item--icon subtext" href="https://support.stub.africa" target="_blank"><img src="./assets/icons/support.svg" class="icon">Help &amp; Support</a>
        </div>
        <div class="nav-menu__section">
          <a class="nav-item subtext" @click="logout()">Log out</a>
        </div>
      </div>
      <div v-if="env!='production'" class="environment subtext gray-700">{{env}} v1.{{version}}</div>
    </header>
    <!-- end Logged-in Header -->
    <!-- Static Header -->
    <header class="nav" v-else>
      <div class="nav-bar">
        <router-link @click.native="hideMenu()" class="nav-logo" to="/"><img class="stub-logo"  src="./assets/img/stub-logo.svg" alt="Stub"></router-link>
        <div class="nav-toggle" @click="toggleMenu()">
          <span class="nav-toggle__menu">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1E262E" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
          </span>
          <span class="nav-toggle__close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1E262E" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
          </span>
        </div>
      </div>
      <div class="nav-menu">
        <router-link @click.native="hideMenu()" class="nav-logo" to="/"><img class="stub-logo" src="./assets/img/stub-logo.svg" alt="Stub"></router-link>
        <router-link @click.native="hideMenu()" class="nav-item" to="/">Features</router-link>
        <router-link @click.native="hideMenu()" class="nav-item" to="/about">About</router-link>
        <router-link @click.native="hideMenu()" class="nav-item nav-item--stretchy" to="/pricing">Pricing</router-link>
        <a class="nav-item nav-item--nb" href="/signup">Sign&nbsp;up</a>
        <a class="button" href="/login">Log&nbsp;in</a>
      </div>
    </header>
    <!-- end Static Header -->
    <modal v-bind:visible="invitePopup" @close-event="invitePopup = false" heading="Invite a friend to stub">
      <div class="modal__content">
          <p class="text-muted">Know someone who'd benefit from stub?</p>
          <label for="clientemail">Name</label>
          <input type="email" name="clientname" v-model="friend.name">
          <label for="clientemail">Email address</label>
          <input type="email" name="clientemail" v-model="friend.email">
      </div>
      <div class="modal__buttons">
          <button class="button--priority" @click="sendInvite()"><span class="material-icons">send</span> Send invite</button>
          <button class="button--secondary button--priority" @click="invitePopup = false;friend={};">Cancel</button>
      </div>
    </modal>
    <modal class="modal--send-successful" v-bind:visible="thanksPopup" @close-event="thanksPopup = false" heading="Invite sent">
        <div class="modal__content">
            <img src="./assets/img/sent.svg" alt="">
            <h2>Invite Sent</h2>
            <p>You the bomb! Thanks for sharing!</p>
        </div>
        <div class="modal__buttons">
            <button class="button--secondary button--priority" @click="thanksPopup = false">Done</button>
        </div>
    </modal>

    <div class="overlay trial-expired" v-if="!dismissed && user() && ready && !active && !trial && $route.name != 'settings-plans' && !legacy"> 
      <div class="modal">
        <div class="modal__content text-center">
          <div class="modal__close" @click.stop="dismissed=true"><i class="material-icons">close</i></div>
          <img src="./assets/img/inventory.svg" alt="">
          <h3>Your free trial has expired</h3>
          <p>We hope you’ve enjoyed your 30 days with us as much as we have! To carry on using stub you’ll need to sign up.</p>
          <router-link class="button--priority" tag="button" to="/account/plans">Choose a plan</router-link>
        </div>
      </div>
    </div>

    <div class="overlay trial-expired" v-if="legacy"> 
      <div class="modal">
        <div class="modal__content text-center">
          <img src="./assets/img/inventory.svg" alt="">
          <h3>Hey there</h3>
          <p>We've upgraded stub! We hope you like it.</p> 
          <p>All of your data has been preserved</p>
          <StubButton @click="upgrade()" class="primary button--priority" v-bind:is-loading="loadingUpgrade">Got it</StubButton>
          <div class="space-2"></div>
        </div>
      </div>
    </div>

    <!-- Router View -->
    <main>
      <div v-if="user() && business() && !focusMode" class="announcements">
        <div v-if="!active" class="announcement" @click="plans()">
          <div class="announcement__icon">⏰</div>
          <div class="announcement__content">
            Your free trial ends in {{countdown}} days
          </div>
          <div class="announcement__action">Choose a plan</div>
        </div>
      </div>
      <router-view/>
    </main>
    <!-- end Router View -->

    <!-- Footer -->
    <footer class="stub-footer">
      <Copyright />
    </footer>
    <!-- end Footer -->
  </div>
</template>

<script>
import Loader from './components/Loader.vue'
import StubButton from './components/StubButton.vue'
import Copyright from './components/Copyright.vue'
import Modal from '@/components/Modal.vue'

export default {
  name: 'App',
  components: {
    Loader,
    StubButton,
    Copyright,
    Modal,
  },
  computed: {
    ready: function() {
      return this.$store.getters.ready;// && this.$store.getters.connected;
    },
    focusMode: function() {
      return this.$store.getters.focusMode;
    },
    active: function() {
      return this.$store.getters.active;
    },
    trial: function() {
      return this.$store.getters.trial;
    },
    b: function () {
      return this.$store.getters.business
    },
    legacy: function () {
      var obj = this.b.profile;
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    billing: function() {
      var billing = this.b.billing || {};
      return billing || {};
    },
    details: function() {
      return this.b.details || {};
    },
    countdown: function() {
      var end = this.details.expires;
      var countdown = Math.floor((end - Date.now())/(1000 * 60 * 60 * 24))
      return Math.max(countdown,0);
    },
    remaining: function() {
      var invites = Object.values(this.details.invites||{}) || [];
      return invites.reduce(function(accumulator, currentValue) { 
        if(currentValue.used) return accumulator+0;
        else return accumulator+1;
      },0)
    },
    invoiceNotifications: function() {
      var invoices = this.b.invoices || [];
      var notifs =  invoices.reduce(function(accumulator, currentValue) { 
        if(currentValue.notification) return accumulator+1;
        else return accumulator+0;
      },0)
      console.log(notifs)
      return notifs;
    },
    s: function () {
      return (this.remaining != 1 ? 's' : '');
    },
    modalActive: function() {
      return this.$store.getters.modalActive;
    }
  },
  data: function() {
    return {
      navIsVisible: false,
      invitePopup: false,
      thanksPopup: false,
      friend: {},
      month: 1*2629743000,
      env: process.env.ENVIRONMENT,
      version: process.env.GITHUB_RUN_NUMBER || process.env.VERSION || "0",
      dismissed: false,
      loadingUpgrade: false,
    }
  },
  methods: {
    user: function() {
      return this.$store.getters.user;
    },
    business: function() {
      return this.$store.getters.business;
    },
    loading: function() {
      return this.$store.getters.loading;
    },
    getLogo: function() {
      return this.$store.getters.logo
    },
    plans: function () {
      this.$router.push("/account/plans");
    },
    logout: function(event) {
      this.$store.dispatch('logout');
    },
    toggleMenu: function() {
      document.getElementById('app').classList.toggle('show-nav')
    },
    hideMenu: function() {
      document.getElementById('app').classList.remove('show-nav')
    },
    upgrade: function() {
      var thisy = this;
      thisy.loadingUpgrade = true;
      this.$store.dispatch('upgrade').then(function(data) {
        thisy.loadingUpgrade = false;
        thisy.$router.push("/insights");
      })
    },
    sendInvite: function () {
      var thisy = this;
      var invites = this.details.invites || [];
      var invite = true;
      var i = null;
      // for (let index = 0; index < invites.length; index++) {
      //   const element = invites[index];
      //   if(!element.used) {
      //     invite = element.code; 
      //     i = index;
      //     break;
      //   }
      // }
      if(invite) {
        this.friend.code = invite;
        // this.friend.index = i;
        this.friend.referrer = this.b.profile.firstname+" "+this.b.profile.lastname;
        this.sending = true;
        this.$store.dispatch('sentInvite',this.friend).then(function(data) {
          thisy.sending = false;
          thisy.invitePopup = false;
          thisy.thanksPopup = true;
        });
      }
      else {
        this.sending = false;
      }
    }
  }
}
</script>
<style lang="scss">
@import './assets/scss/sds.scss';

body {
  background: $gray-100;
}
.app--loggedout  {
  background: $light;
}

.app.focus-mode {
  background: $gray-100;
  min-height: 100vh;

  header.nav,
  .nav-spacer {
    display: none;
  }

 & ~ .crisp-client {
    visibility: hidden;
  }
}

.app--loggedout {
  .stub-footer {
    background: $gray-100;
  }
}

.environment {
  position: absolute;
  left: $space-4;
  bottom: $space-3;
  font-size: $subtext-sm;
  pointer-events: none;
}

.modal__close {
  position: absolute;
  right: $space-3;
  top: $space-3;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-md - 1px) {
  
  .environment {
    opacity: 0;
  }

  .show-nav .environment {
    opacity: 1;
  }
}

</style>