<template>
  <div class="login focus">

    <router-link to="/"><img class="stub-logo" src="../assets/img/stub-logo.svg" alt="stub logo"></router-link>

    <form class="focus__content" v-on:submit.prevent="login()">
      <h2>Log in</h2>
      <p>Welcome back. Please enter your email address and password to access your account.</p>

      <p v-if="error" class="alert--error">You have entered incorrect details. Please try again.</p>
      <div>
        <label for="email">
          Email
        </label>
        <input v-model="email" autocomplete="email" id="email" type="email">
      </div>
      <div>
        <label for="password">
          Password
        </label>
        <input v-model="password" autocomplete="password" id="password" type="password">
        <p><router-link to="/forgot-password">Forgot password?</router-link></p>
      </div>
      <div class="form__actions">
        <StubButton v-bind:is-loading="loading">
          Log in
        </StubButton>
        <router-link class="action" to="/signup">Sign up</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import StubButton from '../components/StubButton.vue'
import Copyright from '../components/Copyright.vue'

export default {
  name: 'login',
  components: {
    StubButton,
    Copyright
  },
  data: function () {
    return {
      email: null,
      password: null,
      error: false,
      loading: false,
    }
  },
  methods: {
    login: function(event) {
      if(this.email && this.password) {
        var thisy = this;
        thisy.error = false;
        thisy.loading = true;
        this.$store.dispatch('login',{email: this.email, password: this.password}).then(function(data){
          thisy.loading  = false;
          console.log(data);
        }).catch(function(data){
          thisy.error = true;
          thisy.loading  = false;
          console.log(data);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

.stub-logo {
  display: block;
  margin: $space-2 auto;
}
@media screen and (min-width: $breakpoint-md) {
  .stub-logo {
    margin: $space-2 auto $space-3;
  }
}
</style>