<template>
  <div class="content">

     <header>
      <h1>Expenses</h1>
      <div>
        <!-- <button class="button button--secondary"><span class="material-icons">get_app</span></button> -->
        <button id="add-expense" @click="addExpense()" class="button"><span class="material-icons">add_circle_outline</span> Add expense</button>
      </div>
    </header>

    <div class="list">

    <div class="list__filter" v-if="expenses.length > 0">
      <input v-model="search" id="search" type="search" placeholder="Search expenses">
      <div>
        <label class="label--inline">Show</label>
        <select class="month-filter label--inline" v-model="month">
          <option v-for="(month,index) in months" v-bind:value="month.date" :key="index">{{month.name}}</option>
        </select>
        <select class="year-filter" v-model="year">
          <option v-for="(year,index) in (yearrange || years)" v-bind:value="year" :key="index">{{year}}</option>
        </select>
      </div>
    </div>

    <modal v-bind:visible="adding" @close-event="closeModal()" heading="Add expense">
      <div class="modal__content">
        <label class="">Name</label>
        <input id="add-expense-description" v-model="tempexpense.name" type="text" placeholder="Supplier, goods, services etc">
        <label class="">Amount</label>
        <vue-autonumeric class="" v-model="tempexpense.amount"
           id="add-expense-amount"
          :options="{digitGroupSeparator: ' ',  currencySymbol: currency, minimumValue: '0.00'}"
          :placeholder="currency+'0.00'"></vue-autonumeric>
        <label class="checkbox">Quantity
          <input type="checkbox" name="" v-model="tempexpense.showquantity">
          <span class="check-mark"></span>
        </label>
        <label v-if="tempexpense.showquantity">Quantity</label>
        <vue-autonumeric v-if="tempexpense.showquantity" v-model="tempexpense.quantity"
          :options="['numericPos']"></vue-autonumeric>
        <label v-if="tempexpense.showquantity">Total</label>
        <vue-autonumeric v-if="tempexpense.showquantity" v-bind:value="temptotal" 
          :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
          :placeholder="currency+'0.00'" 
          disabled></vue-autonumeric>
        <div v-if="tempexpense.showquantity" class="space"></div>
        <label class="">Category</label>
        <!-- <input v-model="tempexpense.category" type="text" placeholder=""> -->
        <select  id="add-expense-category" name="expensecategory"  v-model="tempexpense.category">
          <option v-for="cat in expenseCategories" :key="cat.id" :value="cat.value">{{cat.label}}</option>
        </select>
        <label class="">Date</label>
        <vuejs-datepicker v-model="tempexpense.date"></vuejs-datepicker>
      </div>
      <div class="modal__buttons">
        <StubButton id="add-expense-submit" class="button" @click="createExpense()" v-bind:is-loading="loading">Save</StubButton>
      </div>
    </modal> 

    <div v-if="sorted.length > 0" class="expenses">
      <Expense 
        v-for="(expense,index) in searched" 
        v-bind:id="expense.id" 
        v-model="searched[index]" 
        v-on:delete="deleteExpense(expense.id)" 
        v-bind:key="expense.id" 
        v-bind:currency="currency"></Expense>  
    </div>
    <div v-else class="empty-state">
      <img src="/images/empty-state.svg" alt="Empty">
      <h5>No expenses added yet</h5>
      <p class="subtext">Track your expenses to get insights about your cashflow</p>
      <button class="button button--secondary" @click="addExpense()"><span class="material-icons">add_circle_outline</span> Add expense</button>
    </div>

    </div>
  </div>
</template>

<script>
import Expense from '../components/Expense.vue'
import moment from 'moment';
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue';
import Modal from '../components/Modal.vue'
import vuejsDatepicker from 'vuejs-datepicker';
import StubButton from '../components/StubButton.vue'

const refreshPeriodinSeconds  = 5;

export default {
  name: 'expenses',
  components: {
    Expense,
    VueAutonumeric,
    Modal,
    vuejsDatepicker,
    StubButton
  },
  data: function() {
    return {
      search: null,
      autosaver: null,
      loading: false,
      month: -1,//moment().month(),
      year: 'All',//new Date().getFullYear(),
      months: [
        { name: "Jan", date: 0},
        { name: "Feb", date:1},
        { name: "Mar", date:2},
        { name: "Apr", date: 3},
        { name: "May", date: 4},
        { name: "Jun", date:5},
        { name: "Jul", date: 6},
        { name: "Aug", date: 7},
        { name: "Sep", date: 8},
        { name: "Oct", date: 9},
        { name: "Nov", date: 10},
        { name: "Dec", date: 11},
        { name: "All", date: -1},        
      ],
      years: ['All',new Date().getFullYear()],
      adding: false,
      tempexpense: {
        name: null,
        date: new Date(),
        category: null,
        amount:null,
        quantity: 1,
        showquantity: false
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.autosaver)
  },
  beforeRouteLeave (to, from, next) {
    //this.saveExpenses();
    next();
  },
  computed: {
    currency: function() {
      return this.$store.getters.currency;
    },
    temptotal: function() {
      this.tempexpense.total = this.tempexpense.amount * (this.tempexpense.quantity || 1);
      return this.tempexpense.total;
    },
    expenses: function() {
      var expenses = this.$store.getters.business.expenses || [];
      return expenses;
    },
    searched: function() {
      var temp = this.sorted;
      var search = (this.search+"").toLowerCase();
      if(temp) {
        if(temp.length>0) {
          if(this.search===null || this.search =="") return temp;
          else return temp.filter(function(expense) {
            return ((expense.category+"").toLowerCase().includes(search) || (expense.name+"").toLowerCase().includes(search+"") || (expense.details+"").toLowerCase().includes(search+""));
          });
        }
        else return [];
      }
      else return [];
    },
    sorted: function() {
       return this.filtered.sort(function(a,b){
         const dateA = moment(a.date);
         const dateB = moment(b.date);
         let comparison = 0;
          
          if (dateA < dateB) {
            comparison = 1;
          } else if (dateA > dateB) {
            comparison = -1;
          }
          return comparison;
       });
    },
    filtered: function() {
      var thisy = this;
      var mon = this.month;
      var yea = this.year;
      var allexpenses = this.expenses;
      return allexpenses.filter(function(element) {
        if(element == null) return false;
        if(mon == -1 && yea != 'All') return moment(element.date).year() == thisy.year;
        else if(mon == -1 && yea == 'All') return true;
        else if(mon > -1 && yea == 'All') return moment(element.date).month() == thisy.month;
        else return moment(element.date).month() == thisy.month && moment(element.date).year() == thisy.year;
      });
    },
    monthlySum: function () {
      console.log(this.sorted[0])
      return this.sorted.reduce((a, b) => a + (b["data"]["amount"] || 0), 0);
    },
    yearrange: function() {
      var allexpenses = this.expenses.filter(function (el) {return el != null;});;
      if(allexpenses.length > 0) {
        var earliest = allexpenses.reduce(function(prev, curr) {
            return moment(prev.date).year() < moment(curr.date).year() ? prev : curr;
        });
        var firstyear = parseInt(moment(earliest.date).year(),10)
        var thisyear = parseInt(moment().year(), 10)
        var yearrange = [...Array(1+thisyear-firstyear).keys()].map(i => i + firstyear);
        yearrange.push('All');
        return yearrange;
      }
      else return null;
    },
    expenseCategories: function() {
      return this.$store.getters.expenseCategories;
    }
  },
  methods: {
    closeModal: function() {
      this.adding = false;
      this.tempexpense = {
        name: null,
        date: new Date(),
        category: null,
        amount:null,
        quantity: 1,
        total: 0
      }
    },
    addExpense: function () {
      var thisy = this;
      this.adding = true;
    },
    createExpense: function () {
      var thisy = this;
      thisy.loading = true;
      if(this.tempexpense.showquantity==false) {
        this.tempexpense.quantity = 1;
      }
      this.$store.dispatch('createExpense',this.tempexpense).then(function(){
        thisy.loading = false;
        thisy.closeModal();
      });
    },
    deleteExpense: function(event) {
      console.log(event)
       this.$store.dispatch('deleteExpense',{id:event}).then(function(data) {
        console.log(data);
      });
    },
    getExpenses: function() {
       return this.$store.getters.expenses;
    },
    updateExpense:function(expense) {
      this.$store.dispatch('updateExpense',expense).then(function(data) {
        //console.log(data);
      });
    },
  },
}
</script>


<style lang="scss" scoped>
@import './../assets/scss/variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>
