<template>
  <div v-if="user" class="plans">
    <div class="plans-header">
      <h2>Choose the right stub plan for you</h2>
      <p>You can cancel, upgrade or downgrade your subscription whenever you like.</p>
    </div>
    <div class="plans-list">
      <div class="plan"> 
        <p class="plan__subtitle">The Sneaky</p>
        <h2  class="plan__title title">Side Hustle</h2>
        <ul class="plan__features"> 
          <li>Basic insights</li>
          <li>Invoicing</li>
          <li>Expenses</li>
          <li>Income</li>
          <li>Client management</li>
          <li>Product management</li>
          <li>Quoting <span class="pill pill--new">coming soon</span></li>
          <li>Receipts <span class="pill pill--new">coming soon</span></li>
        </ul>
        <p class="plan__price subheading"><strong>R 159.00</strong> p/m</p>
        <div class="plan__cta">
            <button v-if="!active" @click="activate()" class="button--hero">Activate plan</button>
            <button v-if="active"  @click="cancelPopup = true" class="button--danger"><strong>Cancel plan</strong></button>
        </div>
      </div>
      <div class="plan"> 
        <p class="plan__subtitle">The Growing</p>
        <h2  class="plan__title title">Startup</h2>
        <ul class="plan__features"> 
          <li class="gray-700">All <em>Side Hustle</em> features</li>
          <li>In-depth insights</li>
          <li>Client Relationship Management</li>
          <li>Import bank statements</li>
          <li>Invoice payments</li>
          <li class="invisible"></li>
          <li class="invisible"></li>
          <li class="invisible"></li>
        </ul>
        <p class="plan__price subheading"><strong>R 279.00</strong> p/m</p>
        <div class="plan__cta">
            <div class="pill">Coming soon</div>
        </div>
      </div>
      <div class="plan"> 
        <p class="plan__subtitle">The Established</p>
        <h2  class="plan__title title">Entrepreneur</h2>
        <ul class="plan__features"> 
          <li class="gray-700">All <em>Startup</em> features</li>
          <li>Inventory management</li>
          <li>SARS tax reconciliation</li>
          <li class="invisible"></li>
          <li class="invisible"></li>
          <li class="invisible"></li>
          <li class="invisible"></li>
          <li class="invisible"></li>
        </ul>
        <p class="plan__price subheading"><strong>R 479.00</strong> p/m</p>
        <div class="plan__cta">
             <div class="pill">Coming soon</div>
        </div>
      </div>
    </div>
    <modal v-bind:visible="cancelPopup" @close-event="cancelPopup = false" heading="Cancel your stub subscription?">
      <div class="modal__content">
          <p>This will not delete your stub account.</p>
          <label class="checkbox">Cancel my subscription
              <input type="checkbox" name="" v-model="can">
              <span class="check-mark"></span>
          </label>
      </div>
      <div class="modal__buttons">
          <StubButton @click="cancel()" class="button button--disaster button--priority" :disabled="!can" :is-loading="cancelling">Cancel subscription</StubButton>
          <button class="button--secondary button--priority" @click="deletePopup = false;">Back</button>
      </div>
    </modal>
    <div v-if="active">
      <h2 v-if="billing.nextpayment">Next payment due:</h2>
      <h4 v-if="billing.nextpayment">{{makeDate(billing.nextpayment)}}</h4>
      
      <h2>Payment method</h2>
      <button @click="modify()" class="button--secondary">Update payment method</button>

      <h2>Payment history</h2>
      <div class="list">
        <div class="list__row list__row--disabled" v-for="payment in subscription" v-bind:key="payment.id">
          <div class="cell">
            <p class="payment__card">{{payment.brand}} **** {{payment.last4}} </p>
            <p class="microtext">Expires: {{payment.expirymonth}}/{{payment.expiryyear}} </p>
          </div>
          <div class="cell cell--stretchy"></div>
          <div class="cell cell--payment-amount">
            <vue-autonumeric v-model="payment.amount"
            :options="{digitGroupSeparator: ' ', currencySymbol: payment.currency, minimumValue: '0.00' }"
            tag="div" contenteditable="false"
            class="cell currency"></vue-autonumeric>
            <p class="subtext">{{payment.date | formatDate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import StubButton from '../../components/StubButton.vue'
import Modal from '@/components/Modal.vue'
import VueAutonumeric from '../../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
  name: 'plans',
  components: {
    StubButton,
    Modal,
    VueAutonumeric
  },
  data: function() {
    return {
      rendering: false,
      cancelPopup: false,
      cancelling: false,
      can: false,
    }
  },
  computed: {
    business: function () {
      return this.$store.getters.business
    },
    details: function () {
      return this.business.details
    },
    user: function() {
      return this.$store.getters.user;
    },
    plans: function() {
      return this.$store.getters.plans;
    },  
    trial: function() {
      return this.$store.getters.trial;
    }, 
    invites: function() {
      var billing = this.business.details || {};
      return billing.invites || {};
    },
    billing: function() {
      var billing = this.business.billing || {};
      return billing || {};
    },
    subscription: function() {
      var subscription = this.business.subscription || [];
      return subscription;
    },
    active: function() {
      return this.billing.status == "active" ||  this.billing.status == "trialing"
    },
    
  },
  methods: {
    status: function() {
      return this.business.billing.status;
    },
    precision: function(num) {
      return (num || 0).toFixed(2);
    },
    activate: function(plan) {
      var thisy = this;
      this.$store.dispatch('subscribeToPlan',{callback: window.location.href}).then(function(data) {
          console.log(data)
          window.open(data.checkout, "_self") // for paystack
          // Paddle.Checkout.open({
          //   override: data.checkout
          // });
      });

    },
    makeDate:function(datestring) {
      var d = new Date(datestring);
      var months =  ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      return d.getDate()+ " " +months[d.getMonth()]+ " " + d.getFullYear(); 
    },
    cancel: function() {
      var thisy = this;
      this.cancelling = true;
      this.$store.dispatch('cancelPlan').then(function(data) {
        console.log(data)
        thisy.cancelling = false;
        thisy.cancelPopup = false;
      });
    },
    modify: function() {
      var thisy = this;
      this.$store.dispatch('event','plan-update');
      this.$store.dispatch('updatePlan').then(function(data) {
          console.log(data)
          window.open(data.checkout, "_self");// for paystack
          // Paddle.Checkout.open({
          //   override: data.update
          // });
      });
    },
    copy: function(code) {
        const el = document.createElement('textarea');
        el.value = code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        document.getElementById(code).classList.add("copied");
        setTimeout(function() {
          document.getElementById(code).classList.remove("copied");
        }, 450)
    }
  },
  beforeCreate: function() {
    var thisy = this;
    this.$loadScript("https://cdn.paddle.com/paddle/paddle.js")
    .then(() => {
        Paddle.Setup({ 
          vendor: 52586,
          eventCallback: function(eventData) {
            console.log(eventData)
            if(eventData.event.includes("Close")) {
              thisy.$router.go();
            }
          }
        });
    })
    .catch(() => {
      // Failed to fetch script
    });
  },
}
</script>


<style lang="scss" scoped>
@import '../../assets/scss/variables';

.plans-header {
  margin: 0 0 $space-5;
}
.cell--payment-amount .currency {
  padding-right: $space-2;
}

@media screen and (min-width: $breakpoint-lg) and (max-width: 1100px) {
  .plans-list {
    .plan {
      padding: $space-3 $space-2;
    }
  }
}

.payment__card {
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
}
</style>
