<template>
  <div class="signup focus">

    <router-link to="/"><img class="stub-logo" src="../assets/img/stub-logo.svg" alt="stub logo"></router-link>

    <form class="focus__content" v-on:submit.prevent="signup()">
      <h2>Sign up</h2>
      <p>Create a stub account for your business</p>
      <div class="form__header">
        
      </div>
      <div>
        <label for="business">
          Business name
        </label>
        <input v-model="business" autocomplete="organization" id="business" type="text" placeholder="">
      </div>

      <div>
        <label for="firstname">
          Name
        </label>
        <input v-model="firstname" autocomplete="given-name" id="firstname" type="text" placeholder="First name">
        <input v-model="lastname" autocomplete="family-name" id="lastname" type="text" placeholder="Surname">
      </div>

      <div>
        <label for="email">
          Business email address
        </label>
        <input v-model="email" autocomplete="email" id="email" type="email" placeholder="email@business.com">
      </div>
      
      <div>
        <label for="newpassword">
          Password
        </label>
        <input v-model="password" autocomplete="off" id="newpassword" type="password">
      </div>
      <input v-model="address" autocomplete="off" id="address" type="text">

      <p v-if="error" class="errors alert--error">We could not create your account.</p>
      <p v-if="empty" class="errors alert--error">Please complete the signup form</p>
      <p v-if="emailerror" class="errors alert--error">This email address could not be registered</p>
      <p v-if="inviteerror" class="errors alert--error">This invite code has been used or is invalid</p>
      <p v-if="shorterror" class="errors alert--error">Your password must be at least 6 characters</p>

      <div class="form__actions">
        <StubButton type="button" @click="signup()" :is-loading="loading">
          Sign up
        </StubButton>
        <router-link class="action" to="/login">Log in</router-link>
      </div>
    </form>

  </div>
</template>

<script>
import StubButton from '../components/StubButton.vue'
import Copyright from '../components/Copyright.vue'

export default {
  name: 'signup',
  components: {
    StubButton,
    Copyright
  },
  data: function () {
    return {
      business:null,
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      error: false,
      inviteerror: false,
      emailerror: false,
      loading: false,
      shorterror: false,
      empty: false,
      invite: null,
      address: null,
    }
  },
  mounted: function() {
    document.getElementById("address").tabIndex = -1;
  },
  computed: {
    short: function () {
      return ((this.password+"").length < 6);
    }
  },
  methods: {
    signup: async function(event) {
      this.shorterror = false;
      this.inviteerror = false;
      this.emailerror = false;
      this.error = false;
      this.empty = false;
      this.invite ="welcome"
      if(this.business && this.firstname && this.email && !this.loading && !this.short && this.invite) {
        var thisy = this;
        thisy.error = false;
        thisy.loading = true;
        var invite = this.invite.toLowerCase();
        var user = {honeypot: this.address,captcha: "",invite: invite, business: this.business, firstname: this.firstname, lastname: this.lastname, email: this.email, password: this.password}
        this.$store.dispatch('signup', user).then(function(data) {
            thisy.$store.dispatch('login',{email: thisy.email, password: thisy.password});
        }).catch(function(data){
          if(!data.hasOwnProperty('invited')) thisy.emailerror = true;
          else if(data.invited==false) thisy.inviteerror = true;
          else thisy.error = true;
          thisy.loading = false;
          console.log(data)
        });
      }
      else if(this.short) {
        this.shorterror = true;
      }
      else {
        this.empty = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

@media screen and (min-width: $breakpoint-sm) {
  #firstname {
    width: calc(50% - #{$space-1});
    margin-right: $space-1;
    display: inline-block;
  }
  #lastname {
    width: 50%;
    display: inline-block;
  }
}

.stub-logo {
  display: block;
  margin: $space-2 auto;
}
@media screen and (min-width: $breakpoint-md) {
  .stub-logo {
    margin: $space-2 auto $space-3;
  }
}
.errors {
  margin-top: $space-3;
  margin-bottom: -$space-2;
}
#address {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
} 
</style>