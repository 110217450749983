<template>
    <div>
        <div class="invoice-section invoice-expense editable" @click="editExpense()">
            <p class="col-70">{{value.description}}</p>
            <vue-autonumeric v-model="value.amount"
                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                tag="p" contenteditable="false"
                class="col-30 currency"></vue-autonumeric>
        </div>
        <!-- Modal -->
        <modal v-bind:visible="isFocused" @close-event="closeModal()" heading="Expense">
            <div class="modal__content">
                <label for="description">Description</label>
                <input type="text" name="description" v-model="value.description">
                <label for="amount">Amount</label>
                <vue-autonumeric v-model="value.amount"
                    :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                    :placeholder="currency+'0.00'"></vue-autonumeric>
                <p class="subtext">VAT is not added to expenses. <a href="">Learn more</a>.</p>
            </div>
            <div class="modal__buttons">
                <StubButton @click="updateExpense()" v-bind:isLoading="saving">Save</StubButton>
                <button class="button button--danger" @click="deleteExpense()">Delete</button>
            </div>
        </modal>
        <!-- End Modal -->
    </div>
</template>

<script>
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
    name: 'InvoiceExpense',
    components: {
        Modal,
        StubButton,
        VueAutonumeric
    },
    props: ['value','currency','invoiceIsEditable'],
    data: function() {
        return {
            isFocused: false,
            isDeleted: false,
            saving: false
        }
    },
    methods: {
        editExpense() {
            this.isFocused = this.invoiceIsEditable ? true : false;
        },
        updateExpense() {
            // TODO: Implement update event
            this.$emit('update', this.value);
            var thisy = this;
            setTimeout(function() {
                thisy.isFocused = false;
            },500)
        },
        deleteExpense() {
            // TODO: Implement delete even
            this.$emit('delete', this.value);
            this.isFocused = false;
        },
        closeModal: function() {
            this.isFocused = false;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>