<template>
  <div class="content" v-if="insights.thismonth">

    <header>
      <h1>Insights</h1>
    </header>

    <div class="insights" v-if="!journeys.onboarding">
      <div class="insight insight--mega insight--empty-state">
        <img src="/images/empty-state.svg" alt="Empty">
        <h2>Welcome to stub</h2>
        <p>As you use stub you'll see insights appearing here</p>
        <p class="subtext">Get started by completing your business profile</p>
        <router-link tag="button" to="/account/business"><span class="material-icons">work</span> Complete business profile</router-link>
        <!-- <p class="subtext">Let's send your first invoice and get you paid!</p>
        <router-link class="button button--secondary" to="/invoices"><span class="material-icons">send</span> Send Invoice</router-link> -->
      </div>
    </div>

    <h2>Hi {{profile.firstname || profile.name}}, <span class="gray-700">here’s what’s going on with {{details.name}}</span></h2>

    <div class="insights">
      <div class="insight">
        <h2 class="subheading insight__heading">Cash this month</h2>
        <p class="subtext gray-700 insight__date"><span>{{startofmonth|formatShortDate}}</span> – <span>{{endofmonth|formatShortDate}}</span></p>
        <vue-autonumeric class="insight__content title" v-bind:value="insights.thismonth.net"
          v-bind:class="(insights.thismonth.net >= 0 ? 'success': 'danger')"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
        <p v-if="insights.delta.net > 0" class="insight__change subtext">
          <span class="material-icons success">north_east</span> {{insights.delta.net}}%
        </p>
        <p v-else-if="insights.delta.net < 0" class="insight__change subtext">
          <span class="material-icons danger">south_east</span> {{insights.delta.net}}%
        </p>
        <p v-else-if="insights.delta.net = 0" class="insight__change subtext">
          <span class="material-icons">east</span> {{insights.delta.net}}%
        </p>
        <p v-else style="opacity:0">-</p>
      </div>

      <div class="insight insight--number">
        <h2 class="subheading insight__heading">Invoices sent</h2>
        <p class="subtext gray-700 insight__date"><span>{{startofmonth|formatShortDate}}</span> – <span>{{endofmonth|formatShortDate}}</span></p>
        <h3 class="insight__content title">{{insights.thismonth.sent}} invoice<span v-if="insights.thismonth.sent!=1">s</span></h3>
        <p v-if="insights.delta.invoices > 0" class="insight__change subtext">
          <span class="material-icons success">north_east</span> {{insights.delta.invoices}}%
        </p>
        <p v-else-if="insights.delta.invoices < 0" class="insight__change subtext">
          <span class="material-icons danger">south_east</span> {{insights.delta.invoices}}%
        </p>
         <p v-else-if="insights.delta.ninvoiceset = 0" class="insight__change subtext">
          <span class="material-icons">east</span> {{insights.delta.invoices}}%
        </p>
        <p v-else style="opacity:0">-</p>
      </div>

      <div class="insight insight--number">
        <h2 class="subheading insight__heading">Quotes sent</h2>
        <p class="subtext gray-700 insight__date"><span>{{startofmonth|formatShortDate}}</span> – <span>{{endofmonth|formatShortDate}}</span></p>
        <h3 class="insight__content title">{{insights.thismonth.quoted}} quote<span v-if="insights.thismonth.quoted!=1">s</span></h3>
        <!-- <p v-if="insights.delta.invoices > 0" class="insight__change subtext">
          <span class="material-icons success">north_east</span> {{insights.delta.invoices}}%
        </p>
        <p v-else-if="insights.delta.invoices < 0" class="insight__change subtext">
          <span class="material-icons danger">south_east</span> {{insights.delta.invoices}}%
        </p>
         <p v-else-if="insights.delta.ninvoiceset = 0" class="insight__change subtext">
          <span class="material-icons">east</span> {{insights.delta.invoices}}%
        </p>
        <p v-else style="opacity:0">-</p> -->
      </div>

      <!-- <div v-if="invoicesOverdue" class="insight insight--cta">
        <img src="../assets/img/invoices.svg" alt="Invoices"><br>
        <p class="subtext"><router-link to="/invoices">{{invoicesOverdue}} invoices</router-link> are overdue</p>
        <router-link tag="button" class="button--secondary" to="/invoices">Follow up <span class="material-icons">arrow_forward</span></router-link>
      </div>
      <div v-else-if="invoicesDue" class="insight insight--cta">
        <img src="../assets/img/invoices.svg" alt="Invoices"><br>
        <p class="subtext"><router-link to="/invoices">{{invoicesDue}} invoices</router-link> due this month</p>
        <router-link tag="button" class="button--secondary" to="/income">Add payment <span class="material-icons">arrow_forward</span></router-link>
      </div> -->
      <!-- <div class="insight insight--cta">
        <img src="../assets/img/invoices.svg" alt="quotes"><br>
        <p class="subtext">{{insights.thismonth.quoted}} quote<span v-if="insights.thismonth.quoted!=1">s</span> sent this month</p>
        <router-link tag="button" class="button--secondary" to="/quotes">New quote <span class="material-icons">arrow_forward</span></router-link>
      </div> -->

    </div>


    <div class="insights">

      <div class="insight">
        <h2 class="subheading insight__heading">Income</h2>
        <p class="subtext gray-700 insight__date"><span>{{startofmonth|formatShortDate}}</span> – <span>{{endofmonth|formatShortDate}}</span></p>
        <vue-autonumeric class="insight__content title" v-bind:value="insights.thismonth.income"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
        <p v-if="insights.delta.income > 0" class="insight__change subtext">
          <span class="material-icons success">north_east</span> {{insights.delta.income}}%
        </p>
        <p v-else-if="insights.delta.income < 0" class="insight__change subtext">
          <span class="material-icons danger">south_east</span> {{insights.delta.income}}%
        </p>
         <p v-else-if="insights.delta.income = 0" class="insight__change subtext">
          <span class="material-icons">east</span> {{insights.delta.income}}%
        </p>
        <p v-else style="opacity:0">-</p>
        <p class="insight__action"><router-link to="/income" class="action">Add payment <span class="material-icons">arrow_forward</span></router-link></p>
      </div>

      <div class="insight">
        <h2 class="subheading insight__heading">Payments due</h2>
        <p class="subtext gray-700 insight__date">this month</p>
        <vue-autonumeric class="insight__content title" v-bind:value="insights.thismonth.due"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
        <p style="opacity:0">-</p>
        <p class="insight__action"><router-link to="/invoices" class="action">Follow up <span class="material-icons">arrow_forward</span></router-link></p>
      </div>

      <div class="insight">
        <h2 class="subheading insight__heading">Expenses</h2>
        <p class="subtext gray-700 insight__date"><span>{{startofmonth|formatShortDate}}</span> – <span>{{endofmonth|formatShortDate}}</span></p>
        <vue-autonumeric class="insight__content title" v-bind:value="insights.thismonth.expenses"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
        <p v-if="insights.delta.expenses > 0" class="insight__change subtext">
          <span class="material-icons danger">north_east</span> {{insights.delta.expenses}}%
        </p>
        <p v-else-if="insights.delta.expenses < 0" class="insight__change subtext">
          <span class="material-icons success">south_east</span> {{insights.delta.expenses}}%
        </p>
         <p v-else-if="insights.delta.expenses = 0" class="insight__change subtext">
          <span class="material-icons">east</span> {{insights.delta.expenses}}%
        </p>
        <p v-else style="opacity:0">-</p>
        <p class="insight__action"><router-link to="/expenses" class="action">Add expense <span class="material-icons">arrow_forward</span></router-link></p>
      </div>

    </div>


    <!-- <h2>Financial year {{thisYear}}</h2> -->
    <h2>Financial position</h2>

    <div class="insights">

      <div class="insight">
        <h2 class="subheading insight__heading">Net position</h2>
        <p class="subtext gray-700 insight__date">Since {{startofyear|formatDate}}</p>
        <vue-autonumeric class="insight__content subtitle" 
          v-bind:class="(insights.net > 0? 'success': 'danger')" 
          v-bind:value="insights.net"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
      </div>

      <div class="insight">
        <h2 class="subheading insight__heading">Total income</h2>
        <p class="subtext gray-700 insight__date">Since {{startofyear|formatDate}}</p>
        <vue-autonumeric class="insight__content subtitle" v-bind:value="insights.totalincome"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
      </div>

      <div class="insight">
        <h2 class="subheading insight__heading">Total expenses</h2>
        <p class="subtext gray-700 insight__date">Since {{startofyear|formatDate}}</p>
        <vue-autonumeric class="insight__content subtitle" v-bind:value="insights.totalexpenses"
          :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
          tag="h3" contenteditable="false"></vue-autonumeric>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
  name: 'insights',
  components: {
    VueAutonumeric,
  },
  data: function () {
    return {
      incomeData: [14000,0,13201,14291],
      incomeMin: 0,
      incomeMax: 20000,
      incomeFrom: '9 May',
      incomeTo: '7 June 2020',
      expensesData: [1201,9003,7810,2901],
      expensesMin: 0,
      expensesMax: 10000,
      expensesFrom: '9 May',
      expensesTo: '7 June 2020',
      invoicesData: [17000,11020,0,0],
      invoicesMin: 0,
      invoicesMax: 20000,
      invoicesFrom: '9 May',
      invoicesTo: '7 June 2020',
    }
  },
  computed: {
    b: function() {
      console.log(this.$store.getters.business)
      return this.$store.getters.business || {};
    },
    currency: function() {
      return this.$store.getters.currency;
    },
    details: function() {
      return this.b.details || {};
    },
    insights: function() {
      return this.b.insights || {};
    },
    profile: function() {
      return this.b.profile || {};
    },
    journeys: function() {
      return this.details.journeys || {};
    },
    activity: function() {
      return this.$store.getters.activity || [];
    },
    articles: function() {
      return this.$store.getters.articles || [];
    },
    startofmonth: function() {
      var startofmonth = new Date();
      startofmonth.setDate(1);
      return startofmonth;
    },
    nextseven: function() {
      var nextseven = new Date();
      nextseven.setDate(nextseven.getDate()+7);
      nextseven.setHours(23);
      return nextseven;
    },
    endofmonth: function() {
      var today = new Date();
      return new Date(today.getFullYear(), today.getMonth() + 1, 0);
    },
    thismonth: function() {
      return new Date();
    },
    startofyear: function() {
      // or date of the first income in stub
      var created = this.$store.getters.created ;
      return new Date(created);
    },
    thisYear: function() {
      return new Date().getFullYear();
    },
    invoicesPrevious: function() {
      var data = [];
      var temp = Object.values(this.$store.getters.business.invoices || {});
      var now = ((new Date()).getMonth()-1)+"-"+(new Date()).getYear();
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        if(element.events) {
          var event = Object.values(element.events).sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)  
          for (let index = 0; index < event.length; index++) {
            var ev = event[index]
            if(ev.event == "sent") {
              data.push(new Date(ev.timestamp))
              break;
            }
          }
        }
      }
      return data.filter(function(ev) {
        return now == ev.getMonth()+"-"+ev.getYear()
      }).length;
    },
    invoicesSent: function() {
      var data = [];
      var temp = Object.values(this.$store.getters.business.invoices || {});
      var now = (new Date()).getMonth()+"-"+(new Date()).getYear();
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        if(element.events) {
          var event = Object.values(element.events).sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)
          for (let index = 0; index < event.length; index++) {
            var ev = event[index]
            if(ev.event == "sent") {
              data.push(new Date(ev.timestamp))
              break;
            }
          }
        }
      }
      return data.filter(function(ev) {
        return now == ev.getMonth()+"-"+ev.getYear()
      }).length;
    },
    invoicesSentChange: function() {
      var lastMonth = this.invoicesPrevious; // last months invoices sent
      var thisMonth = this.invoicesSent;
      
      if (thisMonth == 0 && lastMonth == 0) {
        return 0;
      }
      if (thisMonth == 0) {
        return -100;
      }
      if (lastMonth == 0) {
        return 100;
      }

      return Math.round((thisMonth - lastMonth) / lastMonth * 10000) / 100;
    },
    invoicesOverdue: function() {
      return null
    },
    invoicesDue: function() {
      return null
    },
  },
  methods: {
    makeMax: function(newMax) {
      if(newMax > this.max) {
        this.max = newMax;
      }
    },
    getImage: function(imagedata) {
      var image = imagedata || [{}]
      return image[0].url;
    }
  },
  created: function() {
  }
}
</script>


<style lang="scss" scoped>
@import '../assets/scss/variables';
header {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  // flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
    // width: 100%;
  }
}
.insight--empty-state {
  text-align: center;
  padding-top: $space-5;
  padding-bottom: $space-6;
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>
