<template>
  <div class="content">
      <header>
        <h1>Account</h1>
      </header>

      <div class="tabs">
        <router-link to="/account/business" class="tab" tag="div">Business</router-link>
        <router-link to="/account/bank" class="tab" tag="div">Payments</router-link>
        <router-link to="/account/plans" class="tab" tag="div">Plans</router-link>
        <router-link to="/account/settings" class="tab" tag="div">Profile</router-link>
      </div>

      <div class="account__main">
          <router-view></router-view>
      </div>
    
  </div>
</template>

<script>
export default {
  name: 'account',
  components: {
    
  },
  data: function() {
    return {
      search: null,
    }
  },
  computed: {
   
  },
  methods: {
   
  },
  created: function() {
   
  }
}
</script>


<style lang="scss" scoped>
@import './../assets/scss/variables';
.account {
  min-height: 101vh;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}

</style>
