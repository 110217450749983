<template>
  <div class="content">

    <header>
      <h1 v-if="$route.meta.quote">Quotes</h1>
      <h1 v-else>Invoices</h1>
      <div class="header-ctas">
        <StubButton v-if="$route.meta.quote" id="new-invoice" class="button" @click="createQuote()" v-bind:is-loading="quoting"><i class="material-icons">add_circle_outline</i> Quote</StubButton>
        <StubButton v-else id="new-invoice" class="button" @click="createInvoice()" v-bind:is-loading="loading"><i class="material-icons">add_circle_outline</i> Invoice</StubButton>
        <!-- <MenuButton button-class="button--secondary" button-icon="expand_more">
          <ul class="menu-options">
            <li class="menu-options--option" @click="createInvoice()">New&nbsp;invoice</li>
          </ul>
        </MenuButton> -->
      </div>

    </header>

    <div class="list">

      <div class="list__filter" v-if="invoices.length > 0">
        <input v-if="$route.meta.quote" v-model="search" class="input input-search" id="search" type="search" placeholder="Search quotes">
        <input v-else v-model="search" class="input input-search" id="search" type="search" placeholder="Search invoices">
          <div>
            <label class="label--inline">Show</label>
            <select class="status-filter" v-model="currentview">
              <option v-for="(view,index) in views" v-bind:value="view" :key="index">{{view}}</option>
            </select>
          </div>
      </div>
      
      <div class="invoices">
        <div v-bind:id="invoice.id"  @click="view(invoice)" class="list__row" v-for="invoice in filtered" :key="invoice.id">
          <div class="cell cell--stretchy">
            <h2 v-if="invoice.client" class="subheading">{{invoice.client.name}} <span v-if="invoice.notification && !invoice.quote" class="notification notification--inline"></span></h2>
            <h2 v-else class="subheading"><em>{{quinvoicetypeupper}}</em></h2>
            <p class="subtext">{{invoice.invoicenumber}}<span v-if="invoice.invoicenumber && invoice.duedate"> — {{invoice.duedate | formatDate}}</span></p>
          </div>
          <div class="cell">         
            <div v-if="status(invoice).includes('draft')" class="pill pill--medium">Draft</div>
            <!-- <div v-if="status(invoice).includes('sent')" class="pill pill--light">Sent</div> -->
            <div v-if="status(invoice).includes('unpaid')" class="pill pill--light">Unpaid</div>
            <div v-if="status(invoice).includes('delivered')" class="pill pill--info">Delivered</div>
            <div v-if="status(invoice).includes('failed')" class="pill pill--danger">Failed</div>
            <div v-if="status(invoice).includes('overdue')" class="pill pill--danger">Overdue</div>
            <div v-if="status(invoice).includes('viewed')" class="pill pill--info">Viewed</div>
            <div v-if="status(invoice).includes('partially-paid')" class="pill pill--info">Partially Paid</div>
            <div v-if="status(invoice).includes('written-off')" class="pill pill--warning">Written off</div>
            <div v-if="status(invoice).includes('paid')" class="pill pill--success">Paid</div>
            <div v-if="status(invoice).includes('over-paid')" class="pill pill--success">Overpaid</div>
          </div>
          <vue-autonumeric v-model="invoice.$total"
            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
            tag="div" contenteditable="false"
            class="cell currency"></vue-autonumeric>
        </div>
      </div>

      <div v-if="invoices.length == 0" class="empty-state">
        <img src="/images/empty-state.svg" alt="Empty">
        <h5>No {{quinvoicetype}}s </h5>
        <p class="subtext">Create {{quinvoicetype}}s to start getting paid</p>
        <button v-if="$route.meta.quote" class="button button--secondary" @click="createQuote()" v-bind:is-loading="quoting"><span class="material-icons">create</span> New quote</button>
        <button v-else class="button button--secondary" @click="createInvoice()" v-bind:is-loading="loading"><span class="material-icons">create</span> New invoice</button>
      </div>

    </div>
    
  </div>
</template>

<script>
import moment from 'moment'
import StubButton from '@/components/StubButton.vue'
import MenuButton from '@/components/MenuButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
  name: 'invoices',
  components: {
    StubButton,
    MenuButton,
    VueAutonumeric
  },
  data: function() {
    return {
      search: '',
      views: ["All", "Paid", "Partially paid", "Overpaid", "Unpaid", "Overdue", "Draft"],
      currentview: "All",
      loading: false,
      quoting: false,
      paymentnote: 'Please use your invoice number as the transaction reference.',            
    }
  },
  computed: {
    currency: function() {
      return this.$store.getters.currency;
    },
    quinvoicetype: function() {
      return (this.$route.meta.quote ? 'quote' : 'invoice')
    },
    quinvoicetypeupper: function() {
      return (this.$route.meta.quote ? 'Quote' : 'Invoice')
    },
    invoices: function() {
      var invoices = [];
      if(this.$route.meta.quote) invoices = this.$store.getters.business.quotes || [];
      else invoices = this.$store.getters.business.invoices || [];
      return invoices.sort(function(a,b){
         const dataA = new Date(a.sentdate || a.accepteddate || a.quotesentdate || a.date);
         const dataB = new Date(b.sentdate || b.accepteddate || b.quotesentdate || b.date);
         let comparison = 0;
          
          if (dataA < dataB) {
            comparison = 1;
          } else if (dataA > dataB) {
            comparison = -1;
          }
          return comparison;
       });
    },
    grouped: function () {
      var thisy = this;
      return this.invoices.filter(function(invoice) {
         if(thisy.currentview=="All") return true;
         else {
           var search = thisy.currentview.toLowerCase().replace(" ","-");
           console.log(search)
           return (thisy.status(invoice).includes(search));
         }
       });
    },
    filtered: function() {
            var thisy = this;
            return this.grouped.filter(function(element) {
                if(thisy.search.length==0) return true;
                else if(element == null) return false;
                else {
                  var name = (element.client||{}).name || thisy.quinvoicetype.charAt(0).toUpperCase() + thisy.quinvoicetype.slice(1);
                  return name.toLowerCase().includes(thisy.search.toLowerCase());
                }
            });
        },
    clients: function() {
        return this.$store.getters.business.clients || {};
    },
  },
  methods: {
    referenceGenerator: function() {
      var inv = (this.$store.getters.business.invoices.length+this.$store.getters.business.quotes.length+1)+"";
      if(inv.length == 1) return "00"+inv;
      if(inv.length == 2) return "0"+inv;
      return inv;
    },
    status: function(invoice) {
      var statuses = [];
      var now = new Date();
      var due = new Date(invoice.duedate);
      if(due.getTime() < now.getTime() && !invoice.writtenoff && invoice.$outstanding>0 && !invoice.quote) statuses.push("overdue");
      if(invoice.sent || invoice.quotesent) statuses.push("sent");
      if(invoice.writtenoff) statuses.push("written-off");
      if(invoice.$outstanding < 0 && invoice.$paymentstotal > 0) statuses.push("over-paid");
      else if(invoice.$outstanding == 0 && invoice.$total > 0) statuses.push("paid");
      if(invoice.$paymentstotal > 0 && invoice.$outstanding > 0) statuses.push("partially-paid");      
      if(invoice.sent && invoice.$paymentstotal == 0 && !invoice.quote) statuses.push("unpaid");
      if(statuses.length==0 && invoice.quote) statuses.push("draft");
      return statuses;
    },
    view: function(invoice) {
      this.$router.push("/invoice/"+invoice.id);
    },
    createInvoice: function() {
      var due = new Date();
      due.setDate(due.getDate() + 7)
      var paymentnote  = ((this.$store.getters.business||{}).account || {}).paymentnote;
      var blank = {
        //client: {name: "", email: null, phone: null},
        invoicenumber: this.referenceGenerator(),
        date: new Date(),
        duedate: due,
        showdeposit: true,
        showdiscount: false,
        showshipping: false,
        showvat: false,
        paymentnote: paymentnote || this.paymentnote
      }
      var thisy = this;
      thisy.loading = true;
      this.$store.dispatch('event','invoice-created');
      this.$store.dispatch('createInvoice',blank).then(function(data){
        thisy.$store.dispatch('socketUpdate');
        thisy.$store.dispatch('invoiceEvent',{id:data.id, event:"created"});
        setTimeout(function() {
          thisy.view(data);
          thisy.loading = false;
        },500)
      });
    },
    createQuote: function() {
      var due = new Date();
      due.setDate(due.getDate() + 7)
      var paymentnote  = ((this.$store.getters.business||{}).account || {}).paymentnote;
      var blank = {
        //client: {name: "", email: null, phone: null},
        invoicenumber: this.referenceGenerator(),
        date: new Date(),
        duedate: due,
        showdeposit: true,
        showdiscount: false,
        showshipping: false,
        showvat: false,
        quote: true,
        paymentnote: paymentnote || this.paymentnote
      }
      var thisy = this;
      thisy.quoting = true;
      this.$store.dispatch('event','invoice-created');
      this.$store.dispatch('createInvoice',blank).then(function(data){
        thisy.$store.dispatch('socketUpdate');
        thisy.$store.dispatch('invoiceEvent',{id:data.id, event:"created"});
        setTimeout(function() {
          thisy.view(data);
          thisy.quoting = false;
        },500)
      });
    }
  },
}
</script>


<style lang="scss" scoped>
@import './../assets/scss/variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: $space-3 0 $space-2;

  h1 {
    margin: 0;
  }

  .header-ctas {
    display: flex;
    flex-direction: row;
    .menubutton {
      margin-left: $space-1;
    }
  }
}
@media screen and (min-width: $breakpoint-lg) {
  header {
    margin: $space-5 0 $space-3;
  }
}
</style>
