<template>
    <div class="menubutton" >
        <button :class="buttonClass" @click="toggleMenu()"><i class="material-icons" v-if="buttonIcon">{{buttonIcon}}</i> {{buttonLabel}}</button>
        <div class="menu" v-if="visible">
             <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    buttonClass: {
        type: String,
        default: 'primary'
    },
    buttonLabel: String,
    buttonIcon: String
  },
   data: function() {
    return {
      visible: false
    }
  },
  methods: {
    toggleMenu() {
        this.visible = !this.visible;
    }
  }
}
</script>

<style scoped lang="scss">
/* See _menubutton.scss */
</style>
