<template>

    <div class="invoice-editor grid" v-if="i && user">

        <header class="grid__header grid__header--fixed invoice-editor-header">

            <div id="action-back" class="invoice-editor-close" @click="back()">
                <span class="material-icons">arrow_back</span>
                <span class="back-text">Back to {{invoiceType}}s</span>
            </div>

            <div class="invoice-editor-title">
                <h1 class="subtitle">{{invoicename}} — {{invoiceType}} {{i.invoicenumber}}</h1>
                <div v-if="statuses.list.includes('draft')" class="pill pill--medium">Draft</div>
                <div v-if="statuses.list.includes('sent')" class="pill pill--light">Sent</div>
                <div v-if="statuses.list.includes('unpaid')" class="pill pill--light">Unpaid</div>
                <div v-if="statuses.list.includes('delivered')" class="pill pill--info">Delivered</div>
                <div v-if="statuses.list.includes('failed')" class="pill pill--danger">Failed</div>
                <div v-if="statuses.list.includes('overdue')" class="pill pill--danger">Overdue</div>
                <div v-if="statuses.list.includes('viewed')" class="pill pill--info">Viewed</div>
                <div v-if="statuses.list.includes('partially-paid')" class="pill pill--info">Partially Paid</div>
                <div v-if="statuses.list.includes('written-off')" class="pill pill--warning">Written off</div>
                <div v-if="statuses.list.includes('paid')" class="pill pill--success">Paid</div>
                <div v-if="statuses.list.includes('over-paid')" class="pill pill--success">Overpaid</div>
            </div>

            <div class="invoice-editor-actions">
                <!-- <button id="action-more" class="button button--secondary invoice-editor-actions__more" @click="moreIsFocused = true"><i class="material-icons">more_horiz</i></button> -->
                <MenuButton class="invoice-editor-actions__more" button-class="button--secondary" button-icon="more_horiz">
                    <ul class="menu-options">
                        <li class="menu-options--option" @click="previewlink()">Preview</li>
                        <li class="menu-options--option" @click="focusSend()">{{sendtext}}</li>
                        <li class="menu-options--option" @click="focusPay()">Add&nbsp;payment</li>
                        <li class="menu-options--option" @click="confirmRevertIsFocused = true" v-if="!quote">Revert&nbsp;to&nbsp;quote</li>
                        <li class="menu-options--option" @click="confirmCreateInvoiceFocused = true" v-if="quote">Convert&nbsp;to&nbsp;invoice</li>
                        <li class="menu-options--option" @click="writeoff()">Write&nbsp;off</li>
                        <li class="menu-options--option danger" @click="confirmDeleteIsFocused = true;">Delete</li>
                    </ul>
                </MenuButton>
                <!-- <button v-if="isEditable" class="button--secondary invoice-editor-actions__preview" @click="previewlink()"><span class="material-icons">remove_red_eye</span> Preview</button> -->
                <button class="invoice-editor-actions__send" @click="focusSend()"><span class="material-icons">send</span> {{sendtext}}</button>
                <!-- <button v-if="!isEditable" class="invoice-editor-actions__pay" @click="focusPay()"><span class="material-icons">payments</span> Add payment</button> -->
            </div>            
        </header>

        <!-- <modal v-bind:visible="moreIsFocused" @close-event="moreIsFocused = false" v-bind:heading="invoicename">
            <div id="action-preview" class="modal__action action" @click="previewlink()" >Preview</div>
            <div id="action-send" class="modal__action action" @click="moreIsFocused = false; focusSend()" >Send</div>
            <div id="action-pay" class="modal__action action" @click="moreIsFocused = false; focusPay()">Add payment</div>
            <div id="action-writeoff" class="modal__action action" @click="writeoff()">Write off</div>
            <div id="action-duplicate" class="modal__action action" @click="duplicateInvoice()">Duplicate</div>
            <div id="action-delete" class="modal__action action action--danger" @click="moreIsFocused = false; confirmDeleteIsFocused = true;">Delete</div>
        </modal> -->

        <modal v-bind:visible="sendIsFocused" @close-event="sendIsFocused = false" heading="Send">
            <div class="modal__content">

                    <label for="quinvoicePermalink">{{invoiceType}} URL</label>
                    <input type="text" disabled name="quinvoicePermalink" :value="viewlink">
                    <div class="space-2"></div>
                    <StubButton @click="sharelink()" :is-loading="sharing" :disabled="!hasEmail"><span class="material-icons">send</span> WhatsApp</StubButton>

            </div>
            <div class="modal__content">
                    <label for="clientemail">Email address</label>
                    <input type="email" name="clientemail" v-model="client.email" required>
                    <label for="invoicecontent">Message</label>
                    <textarea name="invoicecontent" v-model="invoiceMessage"></textarea>
                    <p class="input-description">This will appear in the email, above the link to this {{invoiceTypeL}}.</p>
                    <div class="space-2"></div>
                    <StubButton class="button" @click="sendInvoice()" :is-loading="sending" :disabled="!hasEmail"><span class="material-icons">send</span> Email</StubButton>

                
            </div>
            <!-- <div class="modal__buttons"> -->
                <!-- <StubButton @click="sharelink()" :is-loading="sharing" :disabled="!hasEmail"><span class="material-icons">send</span> WhatsApp</StubButton> -->
                <!-- <StubButton class="button--secondary" @click="sendInvoice()" :is-loading="sending" :disabled="!hasEmail"><span class="material-icons">send</span> Email</StubButton> -->
                <!-- <button class="button--secondary" @click="previewlink()"><span class="material-icons">remove_red_eye</span> Preview</button> -->
            <!-- </div> -->
        </modal>
        <modal class="modal--send-successful" v-bind:visible="sendSuccessfulIsFocused" @close-event="sendSuccessfulIsFocused = false" :heading="invoiceType+' sent'">
            <div class="modal__content">
                <img src="./../assets/img/sent.svg" alt="">
                <h2>Sent</h2>
                <p>{{invoiceType}} {{i.invoicenumber}} sent to {{c.name}}</p>
            </div>
            <div class="modal__buttons">
                <router-link v-if="quote" tag="button" to='/quotes'>Done</router-link>
                <router-link v-else tag="button" to='/invoices'>Done</router-link>
            </div>
        </modal>

        <modal v-bind:visible="payIsFocused" @close-event="payIsFocused=false" heading="Add Payment">
            <div class="modal__content">
                <h4 v-if="showPayments">Payments</h4>
                <div v-if="showPayments" class="list">
                    <InvoicePayment class="list__row" 
                        v-for="(payment,index) in payments" :key="payment.id" v-bind:currency="currency" v-model="payments[index]" 
                        v-on:update="updatePayment"
                        v-on:delete="deletePayment"/>
                </div>
                <fieldset>
                    <h4 v-if="showPayments">Add payment</h4>
                    <label for="payAmount">Amount</label>
                    <vue-autonumeric v-model="payment.amount"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                        :placeholder="currency+'0.00'"></vue-autonumeric>
                    <p v-if="showPayments" class="input-description"><vue-autonumeric v-bind:value="i.$outstanding"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                        :placeholder="currency+'0.00'"
                        tag="span" contenteditable="false"></vue-autonumeric> outstanding</p>
                    
                    <label for="payDate">Payment Date</label>
                    <vuejs-datepicker name="payDate" v-model="payment.date"></vuejs-datepicker>
                    <label>Payment</label>
                    <label class="radio-button">Account
                        <input type="radio" name="incomepayment" value="account" v-model="payment.payment">
                        <span class="radio-mark"></span>
                    </label>
                    <label class="radio-button">Cash
                        <input type="radio" name="incomepayment" value="cash" v-model="payment.payment">
                        <span class="radio-mark"></span>
                    </label>
                </fieldset>
            </div>
            <div class="modal__buttons">
                <button id="add-payment-submit" @click="addPayment()">Add payment</button>
            </div>
        </modal>

        <modal v-bind:visible="confirmRevertIsFocused" @close-event="confirmRevertIsFocused = false" heading="Revert to quote">
            <div class="modal__content" v-if="payments.length">
                <p>You must delete the payments associated with this invoice before you can revert it to a quote.</p>
                <button class="button" @click="confirmRevertIsFocused=false">Ok</button>
            </div>
            <div class="modal__content" v-else>
                <p>You are about to convert this invoice into a quote with the same information on it.</p>
                <StubButton class="button" @click="toQuote()" :is-loading="reverting">Revert to quote</StubButton>
            </div>
        </modal>

        <modal v-bind:visible="confirmCreateInvoiceFocused" @close-event="confirmCreateInvoiceFocused = false" heading="Convert invoice">
            <div class="modal__content">
                <p>You are about to convert this quote to an invoice.</p>
                <StubButton class="button" @click="toInvoice()" :is-loading="converting">Convert to invoice</StubButton>
            </div>
        </modal>

        <modal v-bind:visible="confirmDeleteIsFocused" @close-event="confirmDeleteIsFocused = false" heading="Delete invoice">
            <div class="modal__content">
                <!-- <img src="./../assets/img/sent.svg" alt=""> -->
                <h3>Are you sure you want to delete {{invoiceType}} {{i.invoicenumber}}?</h3>
                <p>This action cannot be undone</p>
            </div>
            <div class="modal__buttons">
                <button class="button--disaster" id="action-confirm-delete" @click="deleteInvoice()">Delete</button>
                <button class="button--secondary" @click="confirmDeleteIsFocused = false">Cancel</button>
            </div>
        </modal>

        <div class="grid__left grid__left--lg-xl">
            <div class="grid-cells--fixed">
                <div class="grid-cell">
                    <h4>Sections</h4>
                    <p class="subtext">Choose the sections that your want to appear on this {{invoiceTypeL}}.</p>
                    <!--label class="checkbox">Expenses
                        <input type="checkbox" name="expenses" v-model="showExpenses" :disabled="!isEditable">
                        <span class="check-mark"></span>
                    </label-->
                    <label class="checkbox">Discount
                        <input type="checkbox" name="discount" v-model="showDiscount" :disabled="!isEditable" @change="toggleDiscount()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">Shipping
                        <input type="checkbox" name="shipping" v-model="showShipping" :disabled="!isEditable" @change="toggleShipping()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">VAT
                        <input type="checkbox" name="vat" v-model="showVat" :disabled="!isEditable" @change="toggleVat()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">Deposit
                        <input type="checkbox" name="deposit" v-model="showDeposit" :disabled="!isEditable" @change="toggleDeposit()">
                        <span class="check-mark"></span>
                    </label>
                    <!-- <label class="checkbox">Payments
                        <input type="checkbox" name="expenses" v-model="showPayments" :disabled="!isEditable">
                        <span class="check-mark"></span>
                    </label> -->
                </div>
            </div>
        </div>

        <div class="grid__main invoice-editor-invoice">

            <!-- <div class="switch">
                <label class="switch-option">
                    <input type="radio" name="invoicequoteswitch" value="Quote" v-model="invoiceType">
                    <span class="switch-mark">Quote</span>
                </label>
                <label class="switch-option">
                    <input type="radio" name="invoicequoteswitch" value="Invoice" v-model="invoiceType">
                    <span class="switch-mark">Invoice</span>
                </label>
            </div> -->

            <div class="edit-invoice" v-if="!isEditable && invoiceSent">
                <h4>{{invoiceType}} sent {{invoiceSent}}</h4>
                <a class="action" @click="confirmEditIsFocused = true"><i class="material-icons">edit</i> Edit</a>
            </div>
            <div class="edit-invoice" v-if="!isEditable && quoteSent">
                <h4>{{invoiceType}} sent {{quoteSent}}</h4>
                <a class="action" @click="confirmEditIsFocused = true"><i class="material-icons">edit</i> Edit</a>
            </div>
            <div class="edit-invoice" v-if="!isEditable">
                <h4>{{invoiceType}} locked</h4>
                <a class="action" @click="confirmEditIsFocused = true"><i class="material-icons">edit</i> Edit</a>
            </div>
            <!-- <div class="edit-invoice" v-else-if="!isEditable">
                <h4 v-if="statuses.list.includes('draft')" >Invoice locked</h4>
                <h4 v-if="statuses.list.includes('sent')">This invoice has been sent</h4>
                <a class="action" @click="confirmEditIsFocused = true"><i class="material-icons">edit</i> Edit</a>
            </div>
            <div class="edit-invoice" v-if="false">
                <h4>&nbsp;</h4>
                <button class="edit-invoice__button" @click="saveInvoice()"><i class="material-icons">save</i>Save changes</button>
            </div> -->
            <modal v-bind:visible="confirmEditIsFocused" @close-event="confirmEditIsFocused = false" heading="Are you sure?">
                <div class="modal__content">
                    <p v-if="quote">You are about to edit a quote that has already been sent.</p>
                    <p v-else>You are about to edit an invoice that has already been sent.</p>
                    <!-- <p><a @click="duplicateInvoice()">Duplicate this invoice</a> if you want to use it as a template.</p>
                    <p class="subtext">You can create as many invoices as you like.</p> -->
                </div>
                <div class="modal__buttons">
                    <button class="button--secondary" @click="confirmEdit()">Edit {{invoiceType}}</button>
                    <button class="button--secondary" @click="confirmEditIsFocused = false">Cancel</button>
                </div>
            </modal>

            <div :class="isEditable ? 'invoice invoice--editable' : 'invoice invoice--not-editable'">

                <!-- ********** Invoice Logo ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__row">
                        <div class="invoice-logo editable" @click="logoIsFocused = isEditable ? true : false" v-bind:style="getLogo()"></div>
                    </div>
                </div>
                <modal v-bind:visible="logoIsFocused" @close-event="logoIsFocused = false" heading="Logo">
                    <div class="modal__content">
                        <div class="invoice-logo" v-bind:style="getLogo()"></div>
                        <p>Updating your logo will change it on all of your invoices. You can update your logo in <router-link tag="a" to="/account/business">settings</router-link>.</p>
                    </div>
                    <div class="modal__buttons">
                        <button class="button button--secondary" @click="logoIsFocused = false">OK</button>
                    </div>
                </modal>

                <div class="invoice-section invoice-section--header">
                    
                <!-- ********** Invoice Date ********** -->

                    <div class="invoice-section__row">
                        <p class="i-label editable" @click="focusDate()">{{i.date | formatDate}}</p>
                    </div>

                    <modal v-bind:visible="invoiceDateIsFocused" @close-event="invoiceDateIsFocused = false" :heading="invoiceType + ' date'">
                        <div class="modal__content">
                            <label for="invoicedate">{{invoiceType}} Date</label>
                            <vuejs-datepicker name="invoicedate" v-model="invoiceDate"></vuejs-datepicker>
                            <p class="input-description">This is the date when you will issue your client with the {{invoiceTypeL}}.</p>
                            <label for="duedate">Due Date</label>
                            <vuejs-datepicker name="duedate" v-model="dueDate"></vuejs-datepicker>
                            <!-- <div class="pill pill--action">2 weeks</div><div class="pill pill--active">30 days</div><div class="pill pill--action">2 months</div><div class="pill pill--action">3 months</div> -->
                            <p class="input-description">This is the date when payment is due by.</p>
                        </div>
                        <div class="modal__buttons">
                            <StubButton class="button" @click="updateInvoiceDate()" :is-loading="loadingDates">Save</StubButton>
                        </div>
                    </modal>

                <!-- ********** Invoice Title & Description ********** -->
                
                    <div class="invoice-section__row">
                        <h1 class="i-title editable" @click="focusContent()">{{invoiceType}} <span class="invoice-title__invoice-number">{{i.invoicenumber}}</span></h1>
                    </div>

                    <div class="invoice-section__row">
                        <p v-bind:class="{'highlighted': (i.description == null || i.description.length == 0)}" class="i-text editable" @click="focusContent()">{{invoiceContent || i.description || 'Add description'}}</p>
                    </div>
                </div>

                <modal v-bind:visible="invoiceContentIsFocused" @close-event="invoiceContentIsFocused = false" heading="Description">
                    <div class="modal__content">
                        <label for="invoicenumber">{{invoiceType}} Number</label>
                        <input type="text" name="invoicenumber" v-model="invoiceNumber">
                        <label for="invoicecontent">Description</label>
                        <textarea name="invoicecontent" id="" v-model="invoiceContent"></textarea>
                        <p class="input-description">Describe the work being done or services provided.</p>
                    </div>
                    <div class="modal__buttons">
                        <StubButton class="button" @click="updateInvoiceContent()" :is-loading="loadingContent">Save</StubButton>
                    </div>
                </modal>

                <hr>

                <!-- ********** Invoice From ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__col editable" @click="fromIsFocused = isEditable ? true : false">
                        <h5 class="i-label">From</h5>
                        <p class="i-text">
                            {{d.name}}<br v-if="d.name">
                            {{user.email}}
                        </p>
                        <p class="i-text">
                            {{d.address}}<br v-if="d.address">
                            {{d.address2}}<br v-if="d.address2">
                            {{d.suburb}}<br v-if="d.suburb">
                            {{d.city}}<br v-if="d.city">
                            {{d.postalcode}}
                        </p>
                    </div>
                    <modal v-bind:visible="fromIsFocused" @close-event="fromIsFocused = false" heading="Business information">
                        <div class="modal__content">
                            <label for="businessname">Business Name</label>
                            <input type="text" name="businessname" v-bind:value="d.name" disabled>
                            <p>Updating your business information will  change it on all of your invoices. You can update your business information in  <router-link tag="a" to="/account/business">settings</router-link>.</p>
                        </div>
                        <div class="modal__buttons">
                            <button class="button button--secondary" @click="fromIsFocused = false">OK</button>
                        </div>
                    </modal>

                <!-- ********** Invoice To ********** -->

                    <div class="invoice-section__col editable" @click="focusTo()">
                        <h5 class="i-label">To</h5>
                        <p v-if="!i.client" class="i-text highlighted">Add customer details</p>
                        <p class="i-text" v-if="i.client">
                            {{c.name}}<br>
                            {{c.email}}
                        </p>
                        <p class="i-text" v-if="i.client">
                            {{c.address}}<br v-if="c.address">
                            {{c.address2}}<br v-if="c.address2">
                            {{c.suburb}}<br v-if="c.suburb">
                            {{c.city}}<br v-if="c.city">
                            {{c.postalcode}}
                        </p>
                    </div>
                    <modal v-bind:visible="toIsFocused" @close-event="toIsFocused = false; selectedClient = false;" heading="Client">
                        <div class="modal__content select-client" v-if="!selectedClient">
                            <input type="search" placeholder="Search clients...">
                            <div class="list">
                                <div class="list__row" @click="createClient()">
                                    <div class="cell" ><a class="action client-add-new"><span class="material-icons">add_circle_outline</span> Add client</a></div>
                                </div>
                                <div class="list__row" v-for="client in clients" :key="client.id" @click="selectClient(client)">
                                    <div class="cell">{{client.name}}</div>
                                    <div class="cell cell--chevron material-icons">
                                        chevron_right
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal__content edit-client" v-if="selectedClient">
                            <a class="action" @click="selectedClient=false;"><span class="material-icons">chevron_left</span> Change client</a>
                            <fieldset>
                                <label for="clientname">Client</label>
                                <input type="text" autocomplete="name" placeholder="Business or person" name="clientname" v-model="client.name">
                                <!-- <label for="clientbusiness">Business Name (optional)</label>
                                <input type="text" autocomplete="organization" name="clientbusiness" v-model="client.business"> -->
                                <label for="clientemail">Email Address</label>
                                <input type="email" name="clientemail" v-model="client.email">
                                <div class="space"></div>
                                <label for="clientaddress">Address</label>
                                <input type="text" name="clientaddress" placeholder="Street address" v-model="client.address">
                                <input type="text" name="clientaddress2" v-model="client.address2">
                                <input type="text" name="clientsuburb" placeholder="Suburb" v-model="client.suburb">
                                <input type="text" name="clientcity" placeholder="City" v-model="client.city">
                                <input class="narrow" type="text" name="clientpostcode" placeholder="Post Code" v-model="client.postalcode">
                                <!-- <select name="clientcountry" id="">
                                    <option value="South Africa">South Africa</option>
                                </select> -->
                                <p class="input-description">Your invoice must include your client's address. <a target="_blank" href="https://support.stub.africa/invoices#to">Learn more</a>.</p>
                            </fieldset>
                        </div>
                        <div class="modal__buttons modal__buttons--divider" v-if="selectedClient">
                            <StubButton @click="updateInvoiceTo()" v-bind:is-loading="savingclient">Save</StubButton>
                        </div>
                    </modal>
                </div>

                <hr>

                <!-- ********** Line Items ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__row">
                        <h5 class="i-label">Description</h5>
                        <h5 class="i-label text-right">Amount</h5>
                    </div>
                </div>
                <InvoiceLineItem v-for="(invoiceLineItem,index) in items" v-model="items[index]" :key="invoiceLineItem.id" v-bind:currency="currency" v-bind:invoice-is-editable="isEditable" 
                    :type="invoiceTypeL"
                    :itemid="invoiceLineItem.id"
                    v-on:update="updateInvoiceItem"
                    v-on:delete="deleteInvoiceItem(invoiceLineItem)"></InvoiceLineItem>

                <InvoiceLineItem 
                    v-model="newNewItem" v-bind:currency="currency" v-bind:invoice-is-editable="isEditable" 
                    :type="invoiceTypeL"
                    :show="showNewItem" :newitem="true"
                    v-on:update="saveNewItem"
                    v-on:close="closeNewItem"></InvoiceLineItem>

                <div class="invoice-section" v-if="isEditable">
                    <div class="invoice-section__row add-button-row">
                        <button id="add-item" class="button button--secondary" @click="addLineItem()"><i class="material-icons">add_circle</i> Add item</button>
                    </div>
                </div>

                <div class="invoice-section" v-if="showDiscount || showShipping || showVat || showExpenses">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-heading">Subtotal</h5>
                         <vue-autonumeric v-bind:value="i.$subtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-heading"></vue-autonumeric>
                    </div>
                </div>

                <hr v-if="showDiscount || showShipping || showVat || showExpenses">

                <!-- ********** Expenses ********** -->

                <!-- <div v-if="showExpenses" class="invoice-section">
                    <h5 class="invoice-section__col i-label">Expense</h5>
                    <h5 class="invoice-section__col i-label">Amount</h5>
                </div>

                <div v-if="showExpenses">
                    <InvoiceExpense v-for="invoiceExpense in expenses" v-model="invoiceExpense.data" :key="invoiceExpense.id" v-bind:currency="currency" v-bind:invoice-is-editable="isEditable"
                        v-on:update="updateInvoiceExpense()"
                        v-on:delete="deleteInvoiceExpense(invoiceExpense)"></InvoiceExpense>
                </div>

                <div class="invoice-section" v-if="showExpenses && isEditable">
                    <div class="invoice-section__row">
                        <button class="button button--secondary" @click="addExpense()"><i class="material-icons">add_circle</i> Add expense</button>
                    </div>
                </div>

                <div v-if="showExpenses" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-label">Subtotal</h5>
                        <vue-autonumeric v-model="expensesSubtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>

                <hr v-if="showExpenses"> -->

                <!-- ********** Discount ********** -->

                <div v-if="showDiscount" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable" @click="focusDiscount()">
                        <h5 class="i-text">Discount<span v-if="discountType == 'percent'"> (<vue-autonumeric v-bind:value="(i.$discounttotal/i.$subtotal)" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0'}]" tag="span" contenteditable="false"></vue-autonumeric>)</span></h5>
                        <vue-autonumeric v-model="i.$discounttotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency,}"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>
                <modal v-bind:visible="discountIsFocused" @close-event="discountIsFocused = false" heading="Discount">
                    <div class="modal__content">
                        <label>Amount</label>
                        <vue-autonumeric v-model="discount.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            :placeholder="currency+'0.00'"></vue-autonumeric>
                        <div class="text-muted subtext">Current item total 
                            <vue-autonumeric v-bind:value="i.$subtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency}"
                            :placeholder="currency+'0.00'"
                            tag="span" contenteditable="false"></vue-autonumeric></div>
                        <label>Percent</label>
                        <vue-autonumeric v-bind:value="(discount.amount/i.$subtotal)"
                            :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0'}]"
                            disabled></vue-autonumeric>
                    </div>
                    <div class="modal__buttons modal__buttons--divider">
                        <button @click="updateDiscount()">Save</button>
                    </div>
                </modal>

                <!-- ********** Shipping ********** -->

                <div v-if="showShipping" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable" @click="focusShipping()">
                        <h5 class="i-text">Shipping</h5>
                        <vue-autonumeric v-bind:value="i.$shippingtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>
                <modal v-bind:visible="shippingIsFocused" @close-event="shippingIsFocused = false" heading="Shipping">
                    <div class="modal__content">
                        <label>Amount</label>
                        <vue-autonumeric v-model="shippingAmount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'"></vue-autonumeric>
                    </div>
                    <div class="modal__buttons modal__buttons--divider">
                        <button @click="updateShipping()">Save</button>
                    </div>
                </modal>

                <!-- ********** VAT ********** -->

                <div v-if="showVat" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable" @click="focusVat()">
                        <h5 class="i-text">VAT (<vue-autonumeric v-bind:value="v.percent||0" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0', maximumValue: '100' }]" tag="span" contenteditable="false"></vue-autonumeric>)</h5> 
                        <vue-autonumeric v-bind:value="this.i.$vattotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>
                <modal v-bind:visible="vatIsFocused" @close-event="vatIsFocused = false" heading="VAT">
                    <div class="modal__content">
                        <label>Percent</label>
                        <vue-autonumeric v-model="vat.percent"
                            :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0', maximumValue: '100' }]">
                            </vue-autonumeric>
                        <label>Amount</label>
                        <vue-autonumeric v-bind:value="i.$vatabletotal*vat.percent"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'"
                            disabled></vue-autonumeric>
                        <div class="div space"></div>
                        <label class="checkbox">Inclusive VAT
                            <input type="checkbox" name="" v-model="vat.inclusive">
                            <span class="check-mark"></span>
                        </label>
                        <p class="subtext">Choose this option if your invoice items already include VAT, but you would still like to show the total VAT here.</p>
                    </div>
                    <div class="modal__buttons modal__buttons--divider">
                        <button @click="updateVat()">Save</button>
                    </div>
                </modal>

                <!-- ********** Total before payments ********** -->

                <div v-if="showPayments" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right invoice-subtotal">
                        <hr>
                        <h5 class="i-heading">Total</h5>
                        <vue-autonumeric v-bind:value="i.$total"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-heading"></vue-autonumeric>
                        <hr>
                    </div>
                </div>

                <!-- ********** Payments ********** -->

                <div v-if="showPayments" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-heading">Payments</h5>
                    </div>                    
                </div>

                <div v-for="payment in payments" :key="payment.id" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable" @click="isEditable ? payIsFocused = true : true;">
                        <p class="i-text">{{payment.date | formatDate}}</p>
                        <vue-autonumeric v-bind:value="payment.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>

                <!-- <hr v-if="showPayments"> -->

                <!-- ********** Invoice Total ********** -->
                
                <div class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right invoice-total">
                        <hr>
                        <h5 class="i-title">Total due</h5>
                        <vue-autonumeric v-bind:value="i.$outstanding"
                            id="total-due"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-title"></vue-autonumeric>
                        <hr>
                    </div>
                </div>


                <!-- ********** Initial Deposit ********** -->

                <div v-if="showDeposit" class="invoice-section">
                    <div class="invoice-section__col editable invoice-section__col--right invoice-subtotal" @click="focusDeposit()">
                        <h5 class="i-text">Deposit<span> (<vue-autonumeric v-bind:value="(dep.amount/i.$total)||0" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0'}]" tag="span" contenteditable="false"></vue-autonumeric>)</span></h5> 
                        <vue-autonumeric v-bind:value="dep.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>
                <modal v-bind:visible="depositIsFocused" @close-event="depositIsFocused = false" heading="Deposit">
                    <div class="modal__content">
                        <label>Amount</label>
                        <vue-autonumeric v-model="deposit.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00', maximumValue: i.$subtotal }"
                            :placeholder="currency+'0.00'"></vue-autonumeric>
                        <label>Percent</label>
                        <vue-autonumeric v-bind:value="(deposit.amount/i.$subtotal)"
                            :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0', maximumValue: '100' }]"
                            disabled></vue-autonumeric>
                    </div>
                    <div class="modal__buttons modal__buttons--divider">
                        <button @click="updateDeposit()">Save</button>
                    </div>
                </modal>
                
                <!-- ********** Footer ********** -->

                <div class="invoice-section invoice-section--footer">
                
                <!-- ********** Payment details ********** -->

                    <div class="invoice-section__col editable" @click="focusPaymentDetails()">
                        <h5 class="i-label">Payment Details</h5>
                        <p v-if="d.showbank">{{i.paymentnote}}</p>
                        <p v-if="d.showbank">{{d.accountname}}<br v-if="d.accountname">{{d.accountnumber}}<br v-if="d.accountnumber">{{d.branchcode}}<br v-if="d.branchcode">{{d.accounttype}}<br v-if="d.accounttype">{{d.bank}}</p>
                        <!-- <p v-if="d.showpaymentlink">Pay this invoice online using Snapscan or your card.</p>
                        <a v-if="d.showpaymentlink" class="button button--accent">Pay online</a> -->
                    </div>
                    <modal v-bind:visible="paymentDetailsIsFocused" @close-event="paymentDetailsIsFocused = false" heading="Payment Details">
                        <div class="modal__content">
                            <label>Payment Note</label>
                            <textarea v-model="paymentDetails"></textarea>
                            <!-- <label v-if="a.showpaymentlink">Payment link ({{a.paymentlinktype}})</label>
                            <a target="_blank" v-if="a.showpaymentlink" :href="a.paymentlink">{{a.paymentlink}}</a> -->
                            <div class="space-2"></div>
                            <p class="input-description">Updating your bank account information will change it on all of your invoices. You can update this information in <router-link tag="a" to="/account/bank">settings</router-link>.</p>
                        </div>
                        <div class="modal__buttons">
                            <button class="button" @click="updateInvoicePayment()">Save</button>
                        </div>
                    </modal>

                <!-- ********** Due Date ********** -->

                    <div class="invoice-section__col invoice-section__col--has-rows">

                        <div class="row editable" @click="focusDate()" v-if="!quote">
                            <h5 class="i-label">Due Date</h5>
                            <p class="i-text">{{i.duedate | formatDate}}</p>
                        </div>
                        <!-- See modal for invoice date -->
                

                <!-- ********** Invoice Number ********** -->

                        <div class="row editable" @click="focusContent()">
                            <h5 class="i-label">{{invoiceType}} Number</h5>
                            <p class="i-text">{{i.invoicenumber}}</p>
                        </div>

                <!-- ********** Company Registration Number ********** -->

                        <div v-if="d.registrationnumber" class="row editable" @click="registrationNumberIsFocused = isEditable ? true : false">
                            <h5 class="i-label">Company registration</h5>
                            <p class="i-text">{{d.registrationnumber}}</p>
                        </div>
                        <modal v-bind:visible="registrationNumberIsFocused" @close-event="registrationNumberIsFocused = false" heading="Company registration number">
                            <div class="modal__content">
                                <label for="vatregistrationnumber">Company registration number</label>
                                <input type="text" name="vatregistrationnumber" v-bind:value="d.registrationnumber" disabled>
                                <div class="space"></div>
                                <p>Updating your company registration number will change it on all of your invoices. You can update your company registration number in <router-link tag="a" to="/account/business">settings</router-link>.</p>
                            </div>
                            <div class="modal__buttons">
                                <button class="button button--secondary" @click="registrationNumberIsFocused = false">OK</button>
                            </div>
                        </modal>

                <!-- ********** VAT Registration Number ********** -->

                        <div v-if="showVat" class="row editable" @click="vatNumberIsFocused = isEditable ? true : false">
                            <h5 class="i-label">VAT Registration</h5>
                            <p class="i-text">{{d.vatnumber}}</p>
                        </div>
                        <modal v-bind:visible="vatNumberIsFocused" @close-event="vatNumberIsFocused = false" heading="VAT registration number">
                            <div class="modal__content">
                                <label for="vatregistrationnumber">VAT registration number</label>
                                <input type="text" name="vatregistrationnumber" v-bind:value="d.vatnumber" disabled>
                                <div class="space"></div>
                                <p>Updating your VAT registration number will change it on all of your invoices. You can update your VAT registration number in <router-link tag="a" to="/account/business">settings</router-link>.</p>
                            </div>
                            <div class="modal__buttons">
                                <button class="button button--secondary" @click="vatNumberIsFocused = false">OK</button>
                            </div>
                        </modal>
                    </div>
                
                </div>
            </div>
            
        </div>

        <div class="grid__right">

            <div class="grid-cells--fixed">

                <div class="grid-cell grid-cell--sm-lg">
                    <h4>Sections</h4>
                    <p class="subtext">Choose the sections that your want to appear on this {{invoiceTypeL}}.</p>
                    <!--label class="checkbox">Expenses
                        <input type="checkbox" name="expenses" v-model="showExpenses" :disabled="!isEditable">
                        <span class="check-mark"></span>
                    </label-->
                    <label class="checkbox">Discount
                        <input type="checkbox" name="discount" v-model="showDiscount" :disabled="!isEditable" @change="toggleDiscount()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">Shipping
                        <input type="checkbox" name="shipping" v-model="showShipping" :disabled="!isEditable" @change="toggleShipping()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">VAT
                        <input type="checkbox" name="vat" v-model="showVat" :disabled="!isEditable" @change="toggleVat()">
                        <span class="check-mark"></span>
                    </label>
                    <label class="checkbox">Deposit
                        <input type="checkbox" name="deposit" v-model="showDeposit" :disabled="!isEditable" @change="toggleDeposit()">
                        <span class="check-mark"></span>
                    </label>
                    <!-- <label class="checkbox">Payments
                        <input type="checkbox" name="expenses" v-model="showPayments" :disabled="!isEditable">
                        <span class="check-mark"></span>
                    </label> -->
                </div>

                <!-- <div v-if="false" class="grid-cell">
                    <h4>Analysis</h4>
                    <div v-if="!analysis.result">
                        <p class="subtext">Gain insight into your invoice by analyzing it.</p>
                        <button class="button button--secondary" @click="focusMeta()">Start analysis</button>
                    </div>
                    <div v-else>
                        <h3 class="analysis-result" v-bind:class="analysis.rating">{{analysis.result}}</h3>
                        <p v-if="analysis.result" class="subtext">in your pocket after tax</p>
                        <p class="subtext" v-if="analysis.overhead > 0 && analysis.rating == 'success'">
                            <vue-autonumeric v-bind:value="analysis.overhead" :options="['percentageUS2decPos', { decimalPlaces: '0', minimumValue: '0', maximumValue: '100' }]" 
                            tag="span" contenteditable="false"></vue-autonumeric> of your time is spent admin. 
                        </p>
                        <p class="subtext warning" v-if="analysis.rating == 'caution'">
                            <em>This rate is significantly below the average salary.</em>
                        </p>
                        <p class="subtext danger" v-if="analysis.rating == 'danger'">
                            <em>This rate is below minimum wage.</em>
                        </p>
                        <button class="button button--secondary" @click="focusMeta()">Edit analysis</button>
                    </div>
                </div> -->

                <div class="grid-cell" v-if="!quote">
                    <h4></h4>
                    <button class="button--secondary" @click="confirmRevertIsFocused = true">Revert to quote</button>
                </div>

                <div class="grid-cell" v-if="!quote">
                    <h4>Payments</h4>
                    <div class="list">
                        <InvoicePayment
                            v-for="(payment,index) in payments" :key="payment.id" v-bind:currency="currency" v-model="payments[index]" 
                            v-on:update="updatePayment"
                            v-on:delete="deletePayment"/>
                    </div>
                    <p v-if="showPayments" class="subtext"><vue-autonumeric v-bind:value="i.$outstanding"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, }"
                        :placeholder="currency+'0.00'"
                        tag="span" contenteditable="false"></vue-autonumeric> outstanding</p>
                    <button id="add-payment" class="button--secondary" @click="focusPay()"><span class="material-icons">payments</span> Add payment</button>
                    <!-- <p class="subtext" v-if="!payments.length">If you send this invoice to your client and they pay it, the payments will automatically reflect here.</p> -->
                </div>

                <div class="grid-cell" v-if="quote">
                    <h4></h4>
                    <button class="button--secondary" @click="confirmCreateInvoiceFocused = true">Convert to invoice</button>
                    <p class="subtext">This will convert this quote into an invoice.</p>
                    <p class="subtext">If you send this quote to your client and they accept it, it will automatically be converted into an invoice.</p>
                </div>
                
                <div class="grid-cell">
                    <!-- <h4>History</h4>
                    <div class="history">
                    
                        <div class="item" v-bind:class="h.event" v-for="h in hist.slice(0,4)" v-bind:key="h.id">
                            <h5 class="text gray-800">{{h.event | formatStatus}}</h5>
                            <p class="subtext">{{h.description}}
                                <vue-autonumeric v-if="h.amount" v-bind:value="h.amount"
                                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                                :placeholder="currency+'0.00'"
                                tag="span" contenteditable="false"
                                class="currency"></vue-autonumeric>
                            </p>
                            <p class="microtext gray-700"><em>{{h.timestamp | formatDate}}</em></p>
                        </div>
                        
                    </div>     -->

                    <div class="action" @click="invoiceHistoryIsFocused = true">
                        <span class="material-icons">history</span> Show history
                    </div>
                </div>

            </div>

            <modal v-bind:visible="metaIsFocused" @close-event="metaIsFocused = false" heading="Profit">
                <div class="modal__content profit-modal__content">
                    <div v-if="analysis.result" class="callout">
                        <h6>Analysis</h6>
                        <h3 v-bind:class="analysis.rating">{{analysis.result}}</h3>
                        <p class="subtext" v-if="analysis.overhead > 0 && analysis.rating == 'success'">
                            <vue-autonumeric v-bind:value="analysis.overhead" :options="['percentageUS2decPos', { decimalPlaces: '0', minimumValue: '0', maximumValue: '100' }]" 
                            tag="span" contenteditable="false"></vue-autonumeric> of your time is spent admin. 
                        </p>
                        <p class="subtext warning" v-if="analysis.rating == 'caution'">
                            <em>This rate is significantly below the average salary.</em>
                        </p>
                        <p class="subtext danger" v-if="analysis.rating == 'danger'">
                            <em>This rate is below minimum wage.</em>
                        </p>
                    </div>
                    <h3>Time Spent</h3>
                    <p>How much time did you spend on the work being invoiced?</p>
                    <label for="setUpTime">Admin / Set up time</label>
                    <input type="number" name="setUpTime" v-model="meta.setup">
                    <select name="setUpTimeMultiple" id="">
                        <!-- <option value="minutes">minutes</option> -->
                        <option value="hours" selected>hours</option>
                        <!-- <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option> -->
                    </select>
                    <p class="input-description">Show time spent on sales and marketing to get this work</p>
                    <label for="workTime">Work time</label>
                    <input type="number" name="workTime" v-model="meta.duration">
                    <select name="workTimeMultiple" id="">
                        <!-- <option value="minutes">minutes</option> -->
                        <option value="hours" selected>hours</option>
                        <!-- <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option> -->
                    </select>
                    <p class="input-description">Time spent doing this work or producing these goods</p>
                    <label for="wrapUpTime">Clean up / Wrap up time</label>
                    <input type="number" name="wrapUpTime" v-model="meta.wrapup">
                    <select name="wrapUpTimeMultiple" id="">
                        <!-- <option value="minutes">minutes</option> -->
                        <option value="hours" selected>hours</option>
                        <!-- <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option> -->
                    </select>
                    <p class="input-description">Time spent concluding this work</p>
                </div>

                <div class="modal__buttons modal__buttons--divider">
                    <StubButton @click="updateMeta()" :is-loading="analyzing">Save and analyze</StubButton>
                </div>
            </modal>

            <modal v-bind:visible="invoiceHistoryIsFocused" @close-event="invoiceHistoryIsFocused = false" heading="History" modalClass="modal--wider">
                <div class="modal__content history">
                    <div :class="historyitem.event + ' item'" v-for="historyitem in hist" :key="historyitem.id">
                        <div v-if="historyitem.event == 'created'" class="item__event pill pill--light">Created</div>
                        <div v-if="historyitem.event == 'edited'" class="item__event pill pill--light">Edited</div>
                        <div v-if="historyitem.event == 'sent'" class="item__event pill pill--info">Sent</div>
                        <div v-if="historyitem.event == 'delivered'" class="item__event pill pill--info">Delivered</div>
                        <div v-if="historyitem.event == 'failed'" class="item__event pill pill--danger">Failed</div>
                        <div v-if="historyitem.event == 'viewed'" class="item__event pill pill--info">Viewed</div>
                        <div v-if="historyitem.event == 'payment-added'" class="item__event pill pill--success">Payment added</div>
                        <div v-if="historyitem.event == 'written-off'" class="item__event pill pill--warning">Written off</div>
                        <div v-if="historyitem.event == 'paid'" class="item__event pill pill--success">Paid</div>
                        <p class="text stretchy"> {{historyitem.description}}</p>
                        <p class="subtext gray-700">{{historyitem.date | formatDateTime}}</p>
                    </div>
                </div>
            </modal>

        </div>
    </div>

</template>

<script>
import StubButton from '@/components/StubButton.vue'
import MenuButton from '@/components/MenuButton.vue'
import Copyright from '@/components/Copyright.vue'
import InvoiceLineItem from '@/components/InvoiceLineItem.vue'
import InvoiceExpense from '@/components/InvoiceExpense.vue'
import InvoicePayment from '@/components/InvoicePayment.vue'
import Modal from '@/components/Modal.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import VuejsDatepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
    name: 'invoice',
    components: {
        StubButton,
        MenuButton,
        Copyright,
        InvoiceLineItem,
        InvoiceExpense,
        InvoicePayment,
        Modal,
        VueAutonumeric,
        VuejsDatepicker
    },
    data: function() {
        return {
            savingclient: false,
            client: {},
            selectedClient: false,
            meta: {},
            dueDate: new Date(),
            invoiceDate: new Date(),
            invoiceNumber: null,
            invoiceIsEditable: null,
            lineItems:  [],
            paymentItems:  [],
            showNewItem: false,
            newNewItem: {},
            expenseItems:  {},
            discountAmount: null,
            discountPercent:null,
            discountType: 'percent',

            confirmDeleteIsFocused: false,
            confirmRevertIsFocused: false,
            confirmCreateInvoiceFocused: false,

            confirmEditIsFocused: false,
            dueDateIsFocused: false,
            discountIsFocused: false,
            discount: {},
            newDiscount: false,
            
            fromIsFocused: false,

            depositIsFocused: false,
            deposit:  {},
            newDeposit: false,
            
            inclusiveVat: false,
            invoiceContentIsFocused: false,
            invoiceContent: null,
            loadingContent: false,

            invoiceMessage: null,

            invoiceDateIsFocused: false,
            invoiceHistoryIsFocused: false,
            invoiceTitleIncludeInvoiceNumber: false,
            invoiceNumberIsFocused: false,
            logoIsFocused: false,
            moreIsFocused: false,
            
            payIsFocused: false,
            payment: {
                name: null,
                date: new Date(),
                category: "Invoice payment",
                amount: 0,
                invoice: null,
                outstanding: 0,
                payment: 'account'
            },

            invoiceSent: null,
            quoteSent: null,      

            paymentDetails: "",
            paymentDetailsIsFocused: false,
            metaIsFocused: false,
            analyzing: false,
            sendIsFocused: false,
            shippingIsFocused: false,

            sendSuccessfulIsFocused: false,
            
            showDeposit: null,
            showDiscount: null,
            showExpenses: null,
            showShipping: null,
            showVat: null,
            newVat: false,
            shippingAmount: 0,
            toIsFocused: false,
            
            vatIsFocused: false,
            vat: {},
            vatNumberIsFocused: false,
            backdoor: 0,
            ignoredPayments: [],
            sending: false,
            sharing: false,
            registrationNumberIsFocused: false,
            converting: false,
            reverting: false,
            loadingDates: false,
        }
    },
    methods: {
        getLogo: function(event) {
            return this.$store.getters.logo || "background-color: #fff; background-image: url('/nologo.png'); background-repeat: no-repeat; background-size: 100% auto; background-position: center;";  
        },
        focusTo: function() {
            if(this.isEditable) {
                if(this.c.id) this.selectedClient = true;
                this.client = {...this.c};
                this.toIsFocused = true;
            }
        },
        focusSend: function() {
            this.client = {...this.c};
            this.invoiceContent = this.i.description;
            this.invoiceMessage = this.i.message;
            this.sendIsFocused = true;
        },
        // focusInvoiceNumber: function() {
        //     if(this.isEditable) {
        //         this.invoiceNumber = this.i.invoicenumber;
        //         this.invoiceNumberIsFocused = true;
        //     }
        // },
        focusDeposit: function() {
            if(this.isEditable) {
                this.deposit = {...this.dep};
                this.depositIsFocused = true;
            }
        },
        focusDiscount: function() {
            if(this.isEditable) {
                this.discount = {...this.dis};
                this.discountIsFocused = true;
            }
        },
        focusDate: function() {
            if(this.isEditable) {
                this.dueDate = (this.i.duedate ? this.i.duedate : new Date());
                this.invoiceDate = (this.i.date ? this.i.date : new Date());
                this.invoiceDateIsFocused = true;
            }
        },
        focusPay: function() {
            this.payment.amount = this.i.$outstanding;
            this.payIsFocused = true;
        },
        focusVat: function() {
            if(this.isEditable) {
                this.vat = {...this.v};
                this.vatIsFocused = true;
            }
        },
        focusMeta: function() {
            this.meta = {...this.m};
            this.metaIsFocused = true;
        },
        focusContent: function() {
            if(this.isEditable) {
                this.invoiceContent = this.i.description;
                this.invoiceNumber = this.i.invoicenumber;
                this.invoiceContentIsFocused = true;
            }
        },
        focusPaymentDetails: function() {
            if(this.isEditable) {
                this.paymentDetails = this.i.paymentnote;
                this.paymentDetailsIsFocused = true;
            }
        },
        focusShipping: function() {
            if(this.isEditable) {
                this.shippingAmount = this.shippingtotal || 0;
                this.shippingIsFocused = true;
            }
        },
        closeNewItem: function () {
            this.showNewItem = false;
            this.newNewItem = {quantity: 1, price: 0, cost: 0, unit: 'quantity' };
        },
        addLineItem: function() {
            this.newNewItem = {quantity: 1, price: 0, cost: 0, unit: 'quantity'};
            this.showNewItem = true;
        },
        addExpense: function() {
            var temp = {...this.expenseItems} || {};
            temp[Date.now()+""] = {description: "Expense name", quantity: 0, price: 0};
            this.expenseItems = temp;
        },
        createClient: function() {
            this.client = {}
            this.selectedClient = true;
        },
        confirmEdit: function() {
            this.invoiceIsEditable = true;
            this.confirmEditIsFocused = false;
        },
        toQuote: function() {
            var finaldata = {
                id: this.id,
                quote: true
            }
            var thisy = this;
            thisy.reverting = true;
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.confirmRevertIsFocused = false;
                thisy.reverting = false;
            });
        },
        toInvoice: function() {
            var finaldata = {
                id: this.id,
                quote: false
            }
            var thisy = this;
            this.converting = true;
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.confirmCreateInvoiceFocused = false;
                thisy.converting = false;
            });
        },
        saveNewItem: function(item) {
            var temp = item
            temp.invoice = this.id;
            var thisy = this;
            this.$store.dispatch('addInvoiceItem',temp).then(function(data){
                thisy.showNewItem = false;
                thisy.newNewItem = {};
            })
            temp = null
        },
        updateInvoicePayment: function() {
             var finaldata = {
                id: this.id,
                paymentnote: this.paymentDetails || this.i.paymentnote,
            }
            this.$store.dispatch('event','invoice-updated-payment-details');
            this.$store.dispatch('saveInvoice',finaldata);

            this.paymentDetailsIsFocused = false;
        },
        updateInvoiceContent: function() {
             var finaldata = {
                id: this.id,
                description: this.invoiceContent || this.i.description,
                invoicenumber: this.invoiceNumber || this.i.invoicenumber,
                message: this.invoiceMessage || this.i.message,
            }
            var thisy = this;
            this.loadingContent = true;
            this.$store.dispatch('event','invoice-updated-description');
            return this.$store.dispatch('saveInvoice',finaldata).then(function(data) {
                thisy.invoiceContentIsFocused = false;
            })
        },
        updateInvoiceItem: function(item) {
            var thisy = this;
            this.$store.dispatch('updateInvoiceItem',item).then(function(data){
                thisy.lineItems = [];
                thisy.newNewItem = {quantity: 1, price: 0, cost: 0, unit: 'quantity' };
                thisy.invoiceContentIsFocused = false;
                thisy.showNewItem = false;
            });
        },
        updateInvoiceExpense: function() {
            var items = {}
            for (let index = 0; index < this.expenses.length; index++) {
                const element = this.expenses[index];
                items[element.id] = element.data;
            }
            var finaldata = {
                id: this.id,
                expenses: items
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-expenses');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.invoiceContentIsFocused = false;
                thisy.expenseItems = []
            });
        },
        deleteInvoiceExpense: function (item) {
            var finaldata = {
                invoice: this.id,
                id: item.id,
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-items');
            this.$store.dispatch('deleteInvoiceExpense',finaldata);
            thisy.invoiceContentIsFocused = false;
        },
        deleteInvoiceItem: function (item) {
            var finaldata = {
                invoice: this.id,
                id: item.id,
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-items');
            this.$store.dispatch('deleteInvoiceItem',finaldata).then(function(data){
                thisy.invoiceContentIsFocused = false;
            });
        },
        updateInvoiceDate: function() {
             var finaldata = {
                id: this.id,
                date: this.invoiceDate,
                duedate: this.dueDate,
            }
            console.log(finaldata)
            var thisy = this;
            thisy.loadingDates = true;
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.invoiceDateIsFocused = false;
                thisy.loadingDates = false;
            });
        },
        // updateInvoiceNumber: function() {
        //      var finaldata = {
        //         id: this.id,
        //         invoicenumber: this.invoiceNumber,
        //     }
        //     this.$store.dispatch('event','invoice-updated-number');
        //     this.$store.dispatch('saveInvoice',finaldata);
        //     this.invoiceNumberIsFocused = false;
        // },
        updateInvoiceTo: function() {
            this.savingclient = true;
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-client');                
            if(this.client.id) {
                this.$store.dispatch('updateClient',this.client);
                var finaldata = {
                    id: this.id,
                    client: this.client.id
                }   
                return this.$store.dispatch('saveInvoice',finaldata).then(function() {
                    thisy.toIsFocused = false;
                    thisy.savingclient = false;
                    // thisy.client = {};
                })
            }
            else {
                this.client.id = null;
                return this.$store.dispatch('createClient',this.client).then(function(data) {
                    var finaldata = {
                        id: thisy.id,
                        client: data.id
                    }                
                    thisy.$store.dispatch('saveInvoice',finaldata).then(function() {
                        thisy.toIsFocused = false;
                        thisy.savingclient = false;
                        thisy.client = {};
                    })
                });
            }
        },
        updatePayment: function(payment) {
            var finaldata = {
                invoice: this.id,
                id: payment.id,
                amount: payment.amount,
                date: payment.date,
                payment: payment.payment,
            }               
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-payment-updated');
            this.$store.dispatch('updatePayment',finaldata).then(function(data){
                thisy.payIsFocused = false;
            });
        },
        deletePayment: function(payment) {
            var finaldata = {
                id: payment.id,
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-payment-delete');
            this.$store.dispatch('deleteIncome',finaldata).then(function(data){
                thisy.payIsFocused = false;
            });
        },
        addPayment: function() {
            var finaldata = this.payment;
            finaldata.name = "Payment on Invoice "+this.i.invoicenumber;
            finaldata.invoice= this.id;
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-payment-added');
            this.$store.dispatch('addPayment',finaldata).then(function(data){
                thisy.payIsFocused = false;
                thisy.paymentItems = [];
            });
        },
        updateShipping: function() {
            var finaldata = {
                id: this.id,
                shippingtotal: this.shippingAmount,
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-shipping');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.shippingIsFocused = false;
            });
        },
        updateDeposit: function() {
            this.newDeposit= true;
            var finaldata = {
                id: this.id,
                deposit: {amount: this.deposit.amount, 
                         percent: (this.deposit.amount/this.i.$total)},
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-deposit');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.depositIsFocused = false;
                thisy.deposit = {};
            });
        },
        updateDiscount: function() {
            this.newDiscount= true;
            var finaldata = {
                id: this.id,
                discount: {amount: this.discount.amount, 
                           percent: (this.discount.amount/this.i.$subtotal)},
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-discount');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.discountIsFocused = false;
                thisy.discount = {};
            });
        },
        updateVat: function() {
            this.newVat= true;
            var v = {...this.vat};
            var finaldata = {
                id: this.id,
                vat: v,
            }
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-vat');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.vatIsFocused = false;                
            });
        },
        updateMeta: function() {
            var finaldata = {
                id: this.id,
                meta: {...this.meta},
            }
            this.analyzing = true;
            var thisy = this;
            this.$store.dispatch('event','invoice-updated-meta');
            this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                thisy.$store.dispatch('analyzeInvoice',finaldata.id).then(function(data) {
                    thisy.metaIsFocused = false;
                    thisy.analyzing = false;
                });
            });
        },
        writeoff: function() {
            if(!this.i.writtenoff) {
                var finaldata = {
                    id: this.id,
                    writtenoff: true,
                }
                var eventdata = {
                    id: this.id,
                    event: "written-off",
                    amount: this.i.$outstanding,
                    description: "Wrote off ",
                }
                var thisy = this;
                this.$store.dispatch('event','invoice-writtenoff');
                this.$store.dispatch('saveInvoice',finaldata).then(function(data){
                    thisy.metaIsFocused = false;
                });
                this.$store.dispatch('invoiceEvent',eventdata).then(function(data){
                    thisy.moreIsFocused = false;
                });
            }
            else this.moreIsFocused = false;
        },
        sentevent: function() {
            var finaldata = {
                id: this.id,
                event: "sent",
                description: "Sent to "+(this.c.email),
            }
            this.$store.dispatch('invoiceEvent',finaldata);
        },
        sendInvoice: function() {
            // updateInvoiceContent() is called because it's possible to edit the invoice description on the send modal
            if(this.hasEmail) {
                var thisy = this;
                this.sending = true;
                var finaldata = {
                  id: thisy.id, 
                  email: thisy.client.email,
                  message: this.invoiceMessage || this.i.message,
                }
                thisy.$store.dispatch('sendInvoice',finaldata).then(function(result){
                    if(!result.error) {
                        thisy.sentevent();
                        setTimeout(function(){
                            // thisy.$router.push("/invoices");
                            thisy.sendIsFocused = false;
                            thisy.sendSuccessfulIsFocused = true;
                            thisy.sending = false;
                        },100)
                    }
                    else {
                        thisy.sendIsFocused = false;
                        thisy.sendSuccessfulIsFocused = false;
                        thisy.sending = false;
                    }
                })
                
            }
        },
       toggleDiscount: function() {return this.$store.dispatch('saveInvoice',{id: this.id, showdiscount: this.showDiscount});},
       toggleShipping: function() {return this.$store.dispatch('saveInvoice',{id: this.id, showshipping: this.showShipping});},
       toggleVat: function() {return this.$store.dispatch('saveInvoice',{id: this.id, showvat: this.showVat});},
       toggleDeposit: function() {return this.$store.dispatch('saveInvoice',{id: this.id, showdeposit: this.showDeposit});},  
       sendInvoice2: function() {
            var finaldata = {
                id: this.id,
            }
            return this.$store.dispatch('shareInvoice',finaldata);
        },
        duplicateInvoice: function() {
            //This needs to be redone
        },
        deleteInvoice: function() {
            var thisy = this;
            this.$store.dispatch('deleteInvoice',thisy.id).then(function(result){
                if(!result.error) {
                    thisy.$router.push("/invoices");
                }
            })
        },
        back: function () {
          if(this.quote) this.$router.push("/quotes");
          else this.$router.push("/invoices");
        },
        referenceGenerator: function() {
            var inv = this.invoices.length+1+"";
            if(inv.length == 1) return "00"+inv;
            if(inv.length == 2) return "0"+inv;
            return inv;
        },
        previewlink: function() {
            var thisy = this;
            var url = "/view/"+thisy.user.uid+"/"+thisy.id;
            var win = window.open(url, "_blank");
            win.focus();
        },
        sharelink: function() {
            var thisy = this;
            this.sendInvoice2().then(function() {
                var url = thisy.viewlink;
                var message = thisy.invoiceMessage || thisy.i.message || "Hello "+thisy.client.name+".\n\nThanks for your support. You can view your "+thisy.invoiceTypeL+" here:  "
                var finalurl = "https://wa.me/?text="+encodeURI(message+"\n\n"+url);
                console.log(message)
                var win = window.open(finalurl, "_blank");
                win.focus();
                setTimeout(function(){
                    thisy.sendIsFocused = false;
                    thisy.sendSuccessfulIsFocused = true;
                    thisy.sharing = false;
                },1000)
            });
        },
        selectClient: function(client) {
            if(client) {
                this.selectedClient = true;
                this.client = client;
            } else {
                this.selectedClient = false;
            }
        },
        markInvoiceNotificationAsRead: function(id) {
          var thisy = this;
          setTimeout(function() {
            if(thisy.$route.name=="invoice") {
              // stayed long enought to read invoice
              thisy.$store.dispatch('markInvoiceNotificationAsRead',{id: id});
              console.log("notification read")
            }
          },50)
        }
    },
    computed: {
        viewlink: function() {
          return this.$store.getters.public+"/view/"+this.user.uid+"/"+this.id;
        },
        quote: function() {
          return this.i.quote || false;
        },
        invoiceType: function() {
          return (this.quote ? 'Quote' : 'Invoice')
        },
        invoiceTypeL: function() {
          return (this.quote ? 'quote' : 'invoice')
        },
        sendtext: function() {
          var label = "Send"
          if(this.quote) {
            if(this.i.quotesent) label = "Resend";
          }
          else {
            if(this.i.sent) label = "Resend"; 
          }
          return label;
        },
        user: function() {
            return this.$store.getters.user;
        },
        id: function() {
            return this.$route.params.id
        },
        invoices: function() {
            return this.$store.getters.business.invoices || [];
        },
        quotes: function() {
            return this.$store.getters.business.quotes || [];
        },
        currency: function() {
            return this.$store.getters.currency;
        },
        clients: function() {
            return this.$store.getters.business.clients || [];
            
        },
        d: function() {
            return this.$store.getters.business.details || {};
        },
        a: function() {
            return this.$store.getters.business.account || {};
        },
        i: function() {
            var id = this.id;
            var inv = this.invoices.find(function(obj){return obj.id === id}) || this.quotes.find(function(obj){return obj.id === id}) || {};
            this.showDiscount = inv.showdiscount;
            this.showShipping = inv.showshipping;
            this.showVat = inv.showvat;
            this.showDeposit = inv.showdeposit;
            console.log(inv)
            if(inv.notification) this.markInvoiceNotificationAsRead(id);
            return inv;
        },
        c: function() {
            return this.i.client || {};
        },
        v: function() {
            return this.i.vat || {percent: 0.15};
        },
        m: function() {
            return this.i.meta || {};
        },
        analysis: function() {
            return this.i.analysis || {};
        },
        invoicename: function() {
            return (this.c.name || this.invoiceType);
        },
        items: function() {
            var it = this.i.items || [];
            return it;
        },
        expenses: function() {
            var items = [];
            if(this.i) {
                var it = this.i.expenses || {};
                Object.keys(it).forEach(function(key,index) {
                    var temp = {}
                    temp.data = it[key];
                    temp.id = key
                    items.push(temp)
                });
                var thisy = this;
                Object.keys(this.expenseItems).forEach(function(key,index) {
                    var temp = {}
                    temp.data = thisy.expenseItems[key];
                    temp.id = key
                    items.push(temp)
                });
            }
            return items;
        },
        statuses: function() {
            this.backdoor;
            var statuses = [];
            var now = new Date();this.t
            var due = new Date(this.i.duedate);
            if(due.getTime() < now.getTime() && !this.i.writtenoff && this.i.$outstanding>0 && !this.i.quote) statuses.push("overdue");
            if(this.i.sent || this.i.quotesent) statuses.push("sent");
            if(this.i.writtenoff) statuses.push("written-off");
            if(this.i.$outstanding < 0 && this.i.$paymentstotal > 0) statuses.push("over-paid");
            else if(this.i.$outstanding == 0 && this.i.$paymentstotal > 0) statuses.push("paid");
            if(this.i.$paymentstotal > 0 && this.i.$outstanding > 0) statuses.push("partially-paid");      
            if(this.i.sent && this.i.$paymentstotal == 0) statuses.push("unpaid");
            if(statuses.length==0) statuses.push("draft");
            statuses.push(this.i.date) //trigger reactivity
            return {id: Math.random(),
                    list: statuses};
        },
        user:function () {
            return this.$store.getters.user || {};
        },
        dep: function() {
            return this.i.deposit || {amount: 0, percent: 0.0};
        },
        dis: function() {
            return this.i.discount || {amount: 0, percent: 0};
        },
        shippingtotal: function () {
            return this.i.shippingtotal || 0;
        },
        newitems:function() {
            return this.lineItems || [];
        },
        expensetotal: function() {
            //TODO
            return 0;
        },
        hist: function() {
            var hist = [];
            var history = this.i.events;
            if(history) {
                Object.keys(history).forEach(function(key,index) {
                    var temp = {}
                    temp.data = history[key];
                    temp.id = key
                    hist.push(temp)
                });
            }
            hist = hist.concat(this.payments)
            return hist.sort(function(a, b) { 
                if(a.timestamp < b.timestamp) {
                    return 1;
                } 
                else return -1;
            });
        },
        payments: function() {
            var pay = this.i.income || [];
            return pay.sort(function(a, b) { 
                if(a.timestamp < b.timestamp) {
                    return 1;
                } 
                else return -1;
            });
        },
        showPayments: function() {
            return this.payments.length > 0;
        },
        isEditable: function() {
            return this.invoiceIsEditable || !this.i.sent;
        },
        hasEmail: function() {
            return this.client.email || false;
        }
    },
}
</script>

<style lang="scss">
@import './../assets/scss/variables';

.invoice-editor {
    padding-top: 75px;
}
@media screen and (min-width: $breakpoint-md) {
    .invoice-editor {
        padding-top: 80px;
    }
}
@media screen and (min-width: $breakpoint-lg) {
    .invoice-editor {
        padding-top: 95px;
    }
}
.invoice-editor-invoice {
    padding-top: $space-4;
}

.invoice-editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-2 0;
    // border-bottom: 1px solid $gray-300;

    .invoice-editor-close {   
        // border: 1px solid $primary;
        cursor: pointer;
        color: $gray-900;
        

        &:hover {
            color: $primary;
        }

        .material-icons {
            padding: $space-1;
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            vertical-align: middle;
            background: $gray-200;
            border-radius: 50%;
        }
        .back-text {
            padding: $space-1;
            line-height: 24px;
            font-weight: 600;
            vertical-align: middle;
            display: none;
        }
    }
    .invoice-editor-title {
        flex-grow: 1;
        text-align: center;
        h1 {
            margin: $space-0;
        }
    }
    .invoice-editor-actions  {
        // display: flex;
        
        .invoice-editor-actions__more { display: inline-block; margin-right: $space-1;}
        // .invoice-editor-actions__preview { display: none; }
        // .invoice-editor-actions__send { display: none; }
        // .invoice-editor-actions__pay { display: none; }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .invoice-editor-header  {
        .invoice-editor-close {
            margin: 0;
        }
    }
}

@media screen and (min-width: $breakpoint-lg) {
    .invoice-editor-header  {
        padding: $space-3 0;

        .invoice-editor-close {
            width: 300px;
            .material-icons {
                background: none;
            }
            .back-text {
                display: inline;
            }
        }
        .invoice-editor-actions  {
            width: 300px;
            text-align: right;
            .invoice-editor-actions__more { display: inline-block; }
            .invoice-editor-actions__preview { display: inline-block; }
            .invoice-editor-actions__send { display: inline-block; }
            .invoice-editor-actions__pay { display: inline-block; }
    }
    }
}

.modal--send-successful .modal {
    .modal__content {
        text-align: center;
        margin-top: $space-3;
        h2 {
            margin-top: 0;
        }
    }
    .modal__buttons {
        justify-content: center;
    }
}

.edit-invoice {
    background: $gray-200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: $space-2;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    
    h4, button {
        margin: 0;
    }
}
.edit-invoice +  .invoice {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media screen and (min-width: $breakpoint-md) {
    .edit-invoice {
        padding: $space-2 $space-3;
    }
}

.analysis-result {
    margin: $space-2 0 0;
}

.overlay .modal .profit-modal__content {
    padding-top: $space-3;

    input[type=number] {
        width: 40%;
        max-width: auto;
        min-width: auto;
        margin-right: $space-1;
        display: inline-block;
    }
    select {
        width: calc(60% - #{$space-1} - 4px);
        max-width: auto;
        min-width: auto;
        display: inline-block;
    }
}

</style>