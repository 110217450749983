<template>
  <div class="reset-password focus">
    <router-link to="/"><img class="stub-logo" src="../assets/img/stub-logo.svg" alt="stub logo"></router-link>

    <form class="focus__content" v-on:submit.prevent="reset()">
      <h2>Reset Password</h2>
      <p>Enter a new password for your stub account.</p>
      <p v-if="error" class="alert--error">The passwords that you  entered do not match.</p>
      <div>
        <label class="" for="password-1">
          New password
        </label>
        <input v-model="password"  class="" id="password-1" type="password" placeholder="">
      </div>
      <div>
        <label class="" for="password-2">
          Confirm new password
        </label>
        <input v-model="password2" class="" id="password-2" type="password" placeholder="">
      </div>

      <div class="form__actions">
        <StubButton @click="reset()" type="button" :is-loading="loading">
          Reset password
        </StubButton>
      </div>
    </form>
  </div>
</template>

<script>
import Copyright from '../components/Copyright.vue'
import StubButton from '../components/StubButton.vue'

export default {
  name: 'reset-password',
  components: {
    Copyright,
    StubButton
  },
  data: function () {
    return {
      password: null,
      password2: null,
      error: false,
      loading: false
    }
  },
  methods: {
    reset: function(event) {
      if(this.password) {
        var thisy  = this;
        this.error = false;
        if(this.password == this.password2) {
            this.loading = true;
            this.$store.dispatch('resetPassword',{token: this.$route.query.token, password: this.password}).then(function(){
              thisy.loading = false;
              thisy.$router.push('/reset-password-success');
            });
        }
        else {
          this.error = true;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

.stub-logo {
  display: block;
  margin: $space-2 auto;
}
@media screen and (min-width: $breakpoint-md) {
  .stub-logo {
    margin: $space-2 auto $space-3;
  }
}
</style>