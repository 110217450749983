import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(Router)

import Home from './views/static/Home.vue';
import About from './views/static/About.vue';
import Careers from './views/static/Careers.vue';
import Pricing from './views/static/Pricing.vue';
import BuildingStub from './views/static/BuildingStub.vue';
import Thankyou from './views/static/Thankyou.vue';
import PublicQuinvoice from './views/PublicQuinvoice.vue';

import Login from './views/Login.vue';
import Signup from './views/Signup.vue';
import ResetPassword from './views/ResetPassword.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import Insights from './views/Insights.vue';
import Invoice from './views/Invoice.vue';
import Invoices from './views/Invoices.vue';
import Expenses from './views/Expenses.vue';
import Income from './views/Income.vue';
import Clients from './views/Clients.vue';
import Products from './views/Products.vue';
import Account from './views/Account.vue';
import Business from './views/account/Business.vue';
import Bank from './views/account/Bank.vue';
import Plans from './views/account/Plans.vue';
import Settings from './views/account/Settings.vue';
import Status from './views/Status.vue';
import Notfound from './views/Notfound.vue';


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
    },
    {
      path: '/careers',
      name: 'careers',
      component: Careers,
    },
    {
      path: '/building-stub',
      name: 'building-stub',
      component: BuildingStub,
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: Thankyou,
    },
    {
      path: '/view/:uid/:id',
      name: 'public-quinvoice',
      component: PublicQuinvoice,
      meta: { 
        focusMode: true
      }
    },
    {
      path: '/insights',
      name: 'insights',
      component: Insights,
      meta: { requiresAuth: true }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices,
      meta: { requiresAuth: true, quote: false }
    },
    {
      path: '/quotes',
      name: 'quotes',
      component: Invoices,
      meta: { requiresAuth: true, quote: true}
    },
    {
      path: '/invoice/:id',
      name: 'invoice',
      component: Invoice,
      meta: { 
        requiresAuth: true,
        focusMode: true
      }
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: Expenses,
      meta: { requiresAuth: true }
    },
    {
      path: '/income',
      name: 'income',
      component:Income,
      meta: { requiresAuth: true }
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients,
      meta: { requiresAuth: true }
    },
    {
      path: '/products',
      name: 'products',
      component: Products,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/',
      component: Account,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: Business,
          meta: { requiresAuth: true },
        },
        {
          path: 'business',
          name: 'settings-business',
          component: Business,
          meta: { requiresAuth: true },
        },
        {
          path: 'bank',
          name: 'bank-business',
          component: Bank,
          meta: { requiresAuth: true },
        },
        {
          path: 'plans',
          name: 'settings-plans',
          component: Plans,
          meta: { requiresAuth: true },
        },
        {
          path: 'settings',
          name: 'settings-profile',
          component: Settings,
          meta: { requiresAuth: true },
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        focusMode: true
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        focusMode: true
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        focusMode: true
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        focusMode: true
      }
    },
    {
      path: '/forgot-password-sent',
      name: 'forgot-password-sent',
      component: Status,
      meta: {
        focusMode: true,
        title: "Check your email",
        subtitle: "We've sent you instructions to reset your password.",
      }
    },
    {
      path: '/reset-password-success',
      name: 'reset-password-success',
      component: Status,
      meta: {
        focusMode: true,
        title: "Your password has been reset",
        subtitle: "You can log in to your account using your new password.",
        cta: {
            text: "Log in",
            target: "/login"
        }
      }
    },
    { 
      path: '*', 
      name: 'notfound',
      component: Notfound,
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})